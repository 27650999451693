import Vue from 'vue'
import Cookie from 'vue-cookies'
import VueGtag from 'vue-gtag'
import Router from 'vue-router'
import Define from './api/define.js'

const routes = [
  {
    path      : `/${Define.PAGE_NAMES.LOGIN_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.LOGIN_PAGE_NAME,
    component : () => import('./views/Login.vue'),
    meta      : {
      bodyClass : 'login'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TOP_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.TOP_PAGE_NAME,
    component : () => import('./views/Top.vue'),
    meta      : {
      bodyClass : 'home'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/Contact.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}/item/:id`,
    name      : Define.PAGE_NAMES.ITEM_CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/ItemContact.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'ITEM_CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CONTACT_PAGE_NAME}/stock/:id`,
    name      : Define.PAGE_NAMES.STOCK_CONTACT_PAGE_NAME,
    component : () => import('./views/Contact/StockContact.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'ITEM_CONTACT_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME,
    component : () => import('./views/RegisterMember.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'PAGE_NEW_MEMBER_REGISTRATION'
    }
  },
  {
    path        : `/${Define.PAGE_NAMES.MY_PAGE_PAGE_NAME}`,
    name        : Define.PAGE_NAMES.MY_PAGE_PAGE_NAME,
    redirect : {
      name : Define.PAGE_NAMES.BID_HISTORY_PAGE_NAME
    },
    component   : () => import('./views/MyPage.vue'),
    children    : [
      {
        path      : Define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME,
        name      : Define.PAGE_NAMES.CHANGE_INFO_PAGE_NAME,
        component : () => import('./views/MyPage/ChangeInfo.vue'),
        meta        : {
          requiresAuth : true
        }
      },
      {
        path      : Define.PAGE_NAMES.BID_HISTORY_PAGE_NAME,
        name      : Define.PAGE_NAMES.BID_HISTORY_PAGE_NAME,
        component : () => import('./views/MyPage/BidHistory.vue'),
        meta        : {
          requiresAuth : true
        }
      },
      {
        path      : Define.PAGE_NAMES.CARD_INFO_PAGE_NAME,
        name      : Define.PAGE_NAMES.CARD_INFO_PAGE_NAME,
        component : () => import('./views/MyPage/CardInfo.vue'),
        meta        : {
          requiresAuth : true
        }
      }
    ],
    meta        : {
      requiresAuth : true
    }
  },

  {
    path      : `/${Define.PAGE_NAMES.REISSUE_PASSWORD_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.REISSUE_PASSWORD_PAGE_NAME,
    component : () => import('./views/ReissuePassword.vue'),
    meta      : {
      headerNameTag : 'LINK_FORGOT_PASSWORD_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME,
    component : () => import('./views/Auction/List.vue'),
    meta      : {
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true,
      webSocketOn    : true,
      headerNameTag  : 'AUCTION_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME,
    component : () => import('./views/Auction/Detail.vue'),
    meta      : {
      bodyClass      : 'item_p-detail',
      enableQuery    : true,
      webSocketOn    : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME,
    component : () => import('./views/Stock/List.vue'),
    meta      : {
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerFavorite : true,
      headerNameTag  : 'STOCK_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.STOCK_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.STOCK_DETAIL_PAGE_NAME,
    component : () => import('./views/Stock/Detail.vue'),
    meta      : {
      bodyClass      : 'item_p-detail',
      enableQuery    : true,
      headerFavorite : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME,
    component : () => import('./views/Tender/List.vue'),
    meta      : {
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true,
      headerNameTag  : 'TENDER_PAGE_HEADER_LABEL'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.TENDER_LIST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.TENDER_DETAIL_PAGE_NAME,
    component : () => import('./views/Tender/Detail.vue'),
    meta      : {
      bodyClass      : 'item_p-detail',
      enableQuery    : true,
      headerBidding  : true,
      headerFavorite : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.GUIDE_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.GUIDE_PAGE_NAME,
    component : () => import('./views/Guide.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_BEGINNER_GUIDE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.GUIDE_PAGE_NAME}/${Define.PAGE_NAMES.GUIDE_AUCTION_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.GUIDE_AUCTION_PAGE_NAME,
    component : () => import('./views/Guide/Auction.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_GUIDE_WEB_AUCTION'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.GUIDE_PAGE_NAME}/${Define.PAGE_NAMES.GUIDE_AUCTION_BUY_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.GUIDE_AUCTION_BUY_PAGE_NAME,
    component : () => import('./views/Guide/Buy.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_BUY_MOTORCYCLE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.GUIDE_PAGE_NAME}/${Define.PAGE_NAMES.GUIDE_AUCTION_SELL_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.GUIDE_AUCTION_SELL_PAGE_NAME,
    component : () => import('./views/Guide/Sell.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_SELL_MOTORCYCLE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHER_PAGE_NAME}/${Define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME,
    component : () => import('./views/Other/delivery.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_DELIVERY'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHER_PAGE_NAME}/${Define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME,
    component : () => import('./views/Other/commission.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_VARIOUS_COMMISSIONS'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHER_PAGE_NAME}/${Define.PAGE_NAMES.OTHER_GUIDE_TERMS_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.OTHER_GUIDE_TERMS_PAGE_NAME,
    component : () => import('./views/Other/terms.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_TERMS'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHER_PAGE_NAME}/${Define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME,
    component : () => import('./views/Other/auctionTerms.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_AUCTION_TERMS'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHER_PAGE_NAME}/${Define.PAGE_NAMES.OTHER_GUIDE_TOKUSHO_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.OTHER_GUIDE_TOKUSHO_PAGE_NAME,
    component : () => import('./views/Other/tokusho.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_TOKUSHO'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHER_PAGE_NAME}/${Define.PAGE_NAMES.OTHER_GUIDE_FAQ_SUPPORT_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.OTHER_GUIDE_FAQ_SUPPORT_PAGE_NAME,
    component : () => import('./views/Other/faqSupport.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_FAQ_SUPPORT'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.GUIDE_PAGE_NAME}/${Define.PAGE_NAMES.GUIDE_AUCTION_AGENCY_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.GUIDE_AUCTION_AGENCY_PAGE_NAME,
    component : () => import('./views/Guide/Auctionagency.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_GUIDE_WEB_AUCTION_AGENCY'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHERS_PAGE_NAME}/${Define.PAGE_NAMES.ORDER_RULE_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.ORDER_RULE_PAGE_NAME,
    component : () => import('./views/Others/OrderRule.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_COMPANY_ORDER_RULE'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHERS_PAGE_NAME}/${Define.PAGE_NAMES.PRIVACY_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.PRIVACY_PAGE_NAME,
    component : () => import('./views/Others/Privacy.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'PAGE_PRIVACY_POLICY'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.OTHERS_PAGE_NAME}/${Define.PAGE_NAMES.PROFILE_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.PROFILE_PAGE_NAME,
    component : () => import('./views/Others/Profile.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_COMPANY_INFO'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.FAQ_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.FAQ_PAGE_NAME,
    component : () => import('./views/Others/Faq.vue'),
    meta      : {
      bodyClass     : 'sub',
      headerNameTag : 'LINK_GUIDANCE_FAQ'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.VERIFY}`,
    name      : Define.PAGE_NAMES.VERIFY,
    component : () => import('./views/Verify/Verify.vue'),
    meta        : {
      title        : Define.PAGE_TITLES.VERIFY
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME,
    component : () => import('./views/Inspection/List.vue'),
    meta      : {
      bodyClass      : 'item_p-list',
      enableQuery    : true,
      headerNameTag  : 'INSPECTION_PAGE_HEADER_LABEL',
      requiresAuth  : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.INSPECTION_REQUEST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.INSPECTION_REQUEST_PAGE_NAME,
    component : () => import('./views/Inspection/Request.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'INSPECTION_REQUEST_PAGE_NAME',
      requiresAuth  : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.INSPECTION_AUCTION_REQUEST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.INSPECTION_AUCTION_REQUEST_PAGE_NAME,
    component : () => import('./views/Inspection/AuctionRequest.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'INSPECTION_AUCTION_REQUEST_PAGE_NAME',
      requiresAuth  : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.INSPECTION_PURCHASE_REQUEST_PAGE_NAME}`,
    name      : Define.PAGE_NAMES.INSPECTION_PURCHASE_REQUEST_PAGE_NAME,
    component : () => import('./views/Inspection/PurchaseRequest.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'INSPECTION_PURCHASE_REQUEST_PAGE_NAME',
      requiresAuth  : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME}/preview/:id`,
    name      : Define.PAGE_NAMES.INSPECTION_AUCTION_REQUEST_PREVIEW,
    component : () => import('./views/Inspection/RequestPreview.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'INSPECTION_AUCTION_REQUEST_PREVIEW',
      requiresAuth  : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME}/edit/:id`,
    name      : Define.PAGE_NAMES.INSPECTION_AUCTION_REQUEST_EDIT,
    component : () => import('./views/Inspection/Request.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'INSPECTION_AUCTION_REQUEST_EDIT',
      requiresAuth  : true
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CHAT_REQUEST_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.CHAT_REQUEST_PAGE_NAME,
    component : () => import('./views/Chat/RequestChat.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'CHAT_REQUEST_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CHAT_INQUIRY_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.CHAT_INQUIRY_PAGE_NAME,
    component : () => import('./views/Chat/InquiryChat.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'CHAT_INQUIRY_PAGE_NAME'
    }
  },
  {
    path      : `/${Define.PAGE_NAMES.CHAT_BID_PAGE_NAME}/:id`,
    name      : Define.PAGE_NAMES.CHAT_BID_PAGE_NAME,
    component : () => import('./views/Chat/BidChat.vue'),
    meta      : {
      bodyClass     : 'entry',
      headerNameTag : 'CHAT_BID_PAGE_NAME'
    }
  },
  {
    path      : '/system-error',
    name      : Define.PAGE_NAMES.SYSTEM_ERROR_PAGE_NAME,
    component : () => import('./views/SystemError.vue')
  },
  {
    path     : '*',
    name     : Define.PAGE_NAMES.NOT_EXIST_PAGE_NAME,
    redirect : {name : Define.PAGE_NAMES.TOP_PAGE_NAME}
  }
]

const router = new Router({
  // Mode: 'hash', // モック作成時はこっちで実行
  mode : 'history',
  base : process.env.BASE_URL,

  routes
})

Vue.use(Router)
Vue.use(VueGtag, {
  config : {id : process.env.VUE_APP_GA_ID}
}, router)

Vue.prototype.$define = Define
Vue.prototype.$routerGo = function(name, id = null, query = {}, hash = null) {
  const obj = {
    name,
    query
  }
  if (id) {
    obj.params = {id}
  }
  if (hash) {
    obj.hash = `#${hash}`
  }
  router.push(obj).catch(() => {})
}

Vue.prototype.$routerGoNewWindow = function(name, id = null, query = {}, hash = null) {
  const obj = {
    name,
    query
  }
  if (id) {
    obj.params = {id}
  }
  if (hash) {
    obj.hash = `#${hash}`
  }
  const routeData = this.$router.resolve(obj)
  window.open(routeData.href, '_blank')
}

router.beforeEach((to, from, next) => {
  if ($('header p.btnMenu').hasClass('close')) {
    $('header p.btnMenu').trigger('click')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Cookie.get(Define.LOCAL_STORE_LABEL.SESSION_TOKEN)) {
      if (to.name !== from.name) {
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
      }
      return next()
    } else {
      /*
       * セッションIDがなければログイン画面へリダイレクトする
       * alert('ログインしてください。')
       */
      return next({name : Define.PAGE_NAMES.LOGIN_PAGE_NAME})
    }
  } else {
    if (to.name !== from.name) {
      window.scrollTo({
        top      : 0,
        left     : 0,
        behavior : 'smooth'
      })
    }
    return next()
  }
})

export default router
