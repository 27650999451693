<template>
<header>
  <div class="wrap1160N">
    <div class="h-top">
      <h1 class="h-top-logo topLogo">
        <a class="logo" @click="gotoTop">
          <img class="" src="@/assets/img/common/logo.png" :alt="$t('SIDE_LOGO_TITLE')">
        </a>
        <p class="fontB">{{$t("SIDE_NAME")}}<br class="only_sp">{{$t("SIDE_NAME_SP")}}</p>
      </h1>
      <div class="h-top-logo">
        <a class="logo" @click="gotoTop">
          <img class="" src="@/assets/img/common/logo.png" :alt="$t('SIDE_LOGO_TITLE')">
        </a>
        <p class="fontB">{{$t("SIDE_NAME")}}<br class="only_sp">{{$t("SIDE_NAME_SP")}}</p>
      </div>
      <div class="h-top-menu">
        <ul v-if="logined && $route.name != $define.PAGE_NAMES.LOGIN_PAGE_NAME">
          <li>
            <v-menu offset-y @input="refreshNotify">
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-bind:class="{'btn-notice': true, 'is-notice': hasNotify}"
                  v-bind="attrs"
                  v-on="on"
                  >{{ $t('TOP_PAGE_NOTIFICATION') }}</a>
              </template>
              <Notify ref="notify"/>
            </v-menu>
          </li>
          <li v-if="$route.meta.headerBidding"><a class="btn-bid" @click="$routerGo($route.name, null, {filter : encodeURIComponent(JSON.stringify({bidding: true}))})">{{$t("HEADER_BIDDING_BUTTON_LABEL")}}</a></li>
          <li v-if="$route.meta.headerFavorite"><a class="btn-fav" @click="$routerGo($route.name, null, {filter : encodeURIComponent(JSON.stringify({favorite: true}))})">{{$t("HEADER_FAVORITE_LIST_BUTTON_LABEL")}}</a></li>
          <li><a class="btn-logout" @click="logout">{{$t("HEADER_LOGOUT_BUTTON_LABEL")}}</a></li>
          <li><a class="btn-page" @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)"><img src="@/assets/img/common/ic_page.svg" alt="マイページ"></a></li>
        </ul>
        <ul v-else>
          <li><a class="btn-page" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a></li>
        </ul>
      </div>
      <p class="btnMenu only_sp"></p>
    </div>
  </div>

  <div class="gNav">
    <nav class="wrap1160N">
      <!-- PC gNav -->
      <ul class="only_pc">
        <li class="nav-top"><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">{{$t("PAGE_TOP")}}</a></li>
        <li class="nav-black">
          <a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)">{{$t("PAGE_AUCTION")}}</a>
        </li>
        <li>
          <a @click="() => {if(!logined) $routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)}">{{$t("PAGE_MEMBER_LOGIN")}}</a>
          <ul>
            <li v-if="!logined"><a @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a></li>
            <li v-if="!logined"><a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("PAGE_NEW_MEMBER_REGISTRATION")}}</a></li>
            <li v-if="logined"><a @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t("PAGE_MY_PAGE")}}</a></li>
          </ul>
        </li>
        <li class="nav-first">
          <a @click="$routerGo('')">{{$t("PAGE_USERS_GUIDE")}}</a>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_SELL_PAGE_NAME)">{{$t("PAGE_SELL_MOTORCYCLE")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_BUY_PAGE_NAME)">{{$t("PAGE_BUY_MOTORCYCLE")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_PAGE_NAME)">{{$t("PAGE_ABOUT_AUCTION_LISTING")}}</a></li>
            <li>
              <a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_AGENCY_PAGE_NAME)">{{$t("PAGE_ABOUT_AUCTION_LISTING_SERVICE")}}</a>
            </li>
          </ul>
        </li>
        <li class="nav-info"><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{$t("PAGE_DELIVERY")}}</a></li>
        <li class="nav-terms">
          <a>{{$t("PAGE_MARKET_TERMS")}}</a>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_TERMS_PAGE_NAME)">{{$t("PAGE_TERMS")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME)">{{$t('PAGE_AUCTION_TERMS')}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{$t("PAGE_VARIOUS_COMMISSIONS")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t("PAGE_PRIVACY_POLICY")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_TOKUSHO_PAGE_NAME)">{{$t("PAGE_TOKUSHO")}}</a></li>
          </ul>
        </li>
        <li class="nav-qa"><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_FAQ_SUPPORT_PAGE_NAME)">{{$t("PAGE_FAQ_SUPPORT")}}</a></li>
        <li class="nav-access"><a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t("LINK_COMPANY_INFO")}}</a></li>
        <li class="nav-examination" v-if="logined"><a class="main-menu" @click="$routerGo($define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)">{{$t("PAGE_INSPECTION")}}</a></li>
      </ul>

      <!-- SP gNav -->
      <ul class="only_sp">
        <li><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">{{$t("PAGE_TOP")}}</a></li>
        <li class="nav-black">
          <a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)">{{$t("PAGE_AUCTION")}}</a>
        </li>
        <li>
          <p>{{$t("PAGE_MEMBER_LOGIN")}}</p>
          <ul>
            <li v-if="!logined"><a @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a></li>
            <li v-if="!logined"><a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("PAGE_NEW_MEMBER_REGISTRATION")}}</a></li>
            <li v-if="logined"><a @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t("PAGE_MY_PAGE")}}</a></li>
          </ul>
        </li>
        <li class="nav-first">
          <p>{{$t("PAGE_USERS_GUIDE")}}</p>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_SELL_PAGE_NAME)">{{$t("PAGE_SELL_MOTORCYCLE")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_BUY_PAGE_NAME)">{{$t("PAGE_BUY_MOTORCYCLE")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_PAGE_NAME)">{{$t("PAGE_ABOUT_AUCTION_LISTING")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_AGENCY_PAGE_NAME)">{{$t("PAGE_ABOUT_AUCTION_LISTING_SERVICE")}}</a></li>
          </ul>
        </li>
        <li class="nav-info"><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{$t("PAGE_DELIVERY")}}</a></li>
        <li class="nav-terms">
          <p>{{$t("PAGE_MARKET_TERMS")}}</p>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_TERMS_PAGE_NAME)">{{$t("PAGE_TERMS")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME)">{{$t("PAGE_AUCTION_TERMS")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{$t("PAGE_VARIOUS_COMMISSIONS")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t("PAGE_PRIVACY_POLICY")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_TOKUSHO_PAGE_NAME)">{{$t("PAGE_TOKUSHO")}}</a></li>
          </ul>
        </li>
        <li class="nav-qa"><a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_FAQ_SUPPORT_PAGE_NAME)">{{$t("PAGE_FAQ_SUPPORT")}}</a></li>
        <li class="nav-access"><a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t("LINK_COMPANY_INFO")}}</a></li>
        <li class="nav-examination" v-if="logined"><a class="main-menu" @click="$routerGo($define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)">{{$t("PAGE_INSPECTION")}}</a></li>
      </ul>
    </nav>
  </div>
</header>
</template>
<script>
import useNotification from '@/stores/useNotification'
import {computed, defineComponent} from '@vue/composition-api'
import Notify from '../Notify/Notify.vue'
export default defineComponent({
  setup(props) {
    const notifyStore = useNotification()
    if (props.logined) {
      notifyStore.getNotification()
    }
    const hasNotify = computed(() => {
      return notifyStore.notifies && notifyStore.notifies.length > 0
    })
    return {
      notifyStore,
      hasNotify
    }
  },
  props : {
    logined : {
      type    : Boolean,
      default : false
    }
  },
  components : {
    Notify
  },
  data() {
    return {
    }
  },
  methods : {
    gotoTop() {
      console.log('gotoTop')
      this.$routerGo(this.$define.PAGE_NAMES.TOP_PAGE_NAME)
    },
    logout() {
      this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
      this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.MEMBER_NO)
      this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
    },
    refreshNotify(val) {
      console.log('refreshNotify', val)
      if (val && this.logined) {
        this.notifyStore.getNotification()
      }
    }
  },
  watch : {
    $route(to, from) {
      // If (to.name === this.$define.PAGE_NAMES.TOP_PAGE_NAME) {
      this.refreshNotify(true)
      // }
    }
  }
})
</script>
<style scoped lang="scss">
  a.focused {
    border-bottom: 2px solid #364A81 !important;
  }
  #header-index {
    #pNav > ul {
      width: 100%;
      max-width: 1600px;
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;
      margin: 0 auto;
    }
    #pNav > ul > li {
      font-size: 14px;
    }
    #pNav > ul > li:not(:last-of-type)::after {
      content: "＞";
      display: inline-block;
      margin: 0 5px;
    }
    #pNav > ul > li a {
      color: #364A81;
    }
    @media only screen and (max-width: 767px) {
      #pNav > ul {
        flex-wrap: nowrap;
        overflow-x: auto;
        word-break: keep-all;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
</style>
<style scoped lang="scss">
  p {
    margin-bottom: 0px !important;
  }
  ul {
    padding-left: 0px !important;
  }
  a {
    color: #333 !important;
  }
  header .h-top .h-top-menu a {
    color: #fff !important;
  }
  header .gNav nav > ul > li.nav-black > a {
    background-color: #000;
    color: #fff !important;
  }
  @media only screen and (min-width: 768px) {
    header .gNav nav > ul > li > a {
      text-align: center;
    }
  }
  @media only screen and (max-width: 767px) {
    header .h-top .h-top-logo p {
      max-width: calc(100vw - 52px - 50px - 73px - 50px);
    }
  }
</style>
<style scoped lang="scss">
  header .h-top .h-top-logo a.logo-title {
      display: block;
      // width: 102px;
      color: #fff !important;
      font-size: xx-large;
      font-weight: 700;
  }
  header .h-top .h-top-logo p {
    // color: #000 !important;
  }
  header {
    // background-color: #ffffff !important;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
