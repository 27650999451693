<template>
  <v-app class="v-content-100vh">
    <Header :logined="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null"/>
    <v-main id="main">
      <div id="pNav" class="bgLGray" v-if="$route.meta.headerNameTag">
        <ul>
          <li><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">TOP</a></li>
          <li>{{$t($route.meta.headerNameTag)}}</li>
        </ul>
      </div>
      <router-view/>
    </v-main>
    <Footer :logined="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null"/>
    <v-dialog v-model="isLoading" persistent fullscreen content-class="loading-dialog" transition="fade-transition">
      <v-container fill-height fluid >
        <v-layout row justify-center align-center class="loading-icon">
          <v-progress-circular indeterminate :size="70" :width="7" color="green"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-app>
</template>

<script>
import Methods from '@/api/methods'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import Footer from './components/Common/Footer'
import Header from './components/Common/Header'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    return {
      loadingStore
    }
  },
  name       : 'App',
  components : {
    Footer,
    Header
  },
  data() {
    return {
      scripts : [],
      focused : null
    }
  },
  computed : {
    isLoading() {
      return this.loadingStore.isLoading
    }
  },
  created() {
    this.$moment.locale(this.$i18n.locale)
    window.document.title = this.$t('SITE_TITLE')
    document.addEventListener('focusin', this.focusIn)
    document.addEventListener('focusout', this.focusOut)
    document.addEventListener('keydown', event => {
      if (event.code === 'Space') {
        if (this.focused && this.focused.className.includes('space_click_event')) {
          this.focused.click()
          event.preventDefault()
        }
      }
    })
  },
  mounted() {
    Methods.addExternalScripts(this.scripts, '')
    this.updateLoginStatus()
    document.body.classList.add('sub')
    document.body.id = 'top'
  },
  methods : {
    focusIn(event) {
      const el = event.target
      if (el) {
        el.className += ' focused'
        this.focused = el
      }
    },
    focusOut(event) {
      const el = event.target
      if (el.className) {
        el.className = el.className.replace('focused', '')
      }
    },
    updateLoginStatus() {
      if (this.$cookies.get(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)) {
        document.body.classList.remove('state-out')
        document.body.classList.add('state-in')
      } else {
        document.body.classList.remove('state-in')
        document.body.classList.add('state-out')
      }
    }
  },
  watch : {
    '$route.query.lang'(value) {
      this.$i18n.locale = this.$route.query.lang ? this.$route.query.lang : this.$i18n.locale
    },
    '$i18n.locale'(value) {
      this.$moment.locale(value)
      document.body.classList.remove('open')
      window.document.title = this.$t('SITE_TITLE')
    },
    $route() {
      this.updateLoginStatus()
      const classList = this.$router.options.routes.map(route => route.meta ? route.meta.bodyClass : null).filter(bodyClass => bodyClass)
      for (const row of classList) {
        document.body.classList.remove(row)
      }
      if (this.$route.meta.bodyClass) {
        document.body.classList.add(this.$route.meta.bodyClass)
      }
    }
  }
})
</script>
<style lang="scss">
  .loading-dialog {
    background-color: #303030;
    opacity: 0.3;
  }
  input.iptW-M, input.iptW-S, input.ime-dis, input.white-input, input.full {
    background-color: #fff;
  }
  textarea.full {
    background-color: #fff;
  }
  .only_pc {
    font-weight: inherit;
  }
  @media only screen and (max-width: 767px) {
    .only_pc {
      display: none!important;
    }
  }
  .only_sp {
    font-weight: inherit;
  }
  @media only screen and (min-width: 768px) {
    .only_sp {
      display: none!important;
    }
  }
  span.checkbox-parts::before, span.checkbox-parts::after {
    -webkit-box-sizing: initial !important;
    box-sizing: initial !important;
  }
</style>

<style scoped lang="scss">
  .checkbox-parts.focused::before {
    border: 3px solid #000;
  }
</style>

<style lang="scss">
  #main #entry-form em.req {
    display: inline-block;
    background-color: #E80000;
    width: 35px;
    height: 20px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    line-height: 19px;
  }
  #main #entry-form p.entry-form-info em.req {
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
  @media only screen and (max-width: 767px) {
    #main #entry-form {
      margin-top: 40px;
      margin-bottom: 40px;
      padding: 15px 15px 20px;
    }
    #main #entry-form p.entry-form-info {
      text-align: left;
      font-size: 17px;
    }
  }
  #main #entry-form em.req {
    width: auto !important;
    max-width: 55px;
    padding: 0 5px;
  }
  #main #entry-form table.tbl-entry th {
    padding-right: 70px !important;
  }
</style>

<style lang="scss">
  .custom-tooltip {
    -moz-animation: tooltipAnimation 2s ease-out 1s forwards;
    /* Firefox */
    -webkit-animation: tooltipAnimation 2s ease-out 1s forwards;
    /* Safari and Chrome */
    -o-animation: tooltipAnimation 2s ease-out 1s forwards;
    /* Opera */
    animation: tooltipAnimation 2s ease-out 1s forwards;
  }
  @keyframes tooltipAnimation {
      0% {
        opacity: 1;
      }
      40% {
        opacity:  30%;
      }
      80% {
        opacity:  60%;
      }
      100% {
        opacity: 0;
        width: 0;
        height:0;
        overflow: hidden;
      }
  }
  @-webkit-keyframes tooltipAnimation {
      0% {
        opacity: 1;
      }
      40% {
        opacity:  30%;
      }
      80% {
        opacity:  60%;
      }
      100% {
        opacity: 0;
        width: 0;
        height:0;
        overflow: hidden;
      }
  }
</style>
<style lang="scss">
  .machine_list .stock-location,
  .mech_d-main-txt .stock-location,
  .machine_li-txt .stock-location,
  .target-item-txt .stock-location {
    font-size: 14px;
    color: #fff;
    background-color: #E80000;
    text-align: center;
    // border-radius: 100vh;
    font-weight: 700;
    padding: 2px 15px;
    margin-right: 5px;
    letter-spacing: 0;
    height: 32px;
    line-height: 28px !important;
  }
  .mech_d-main-txt .stock-location {
    margin-bottom: 20px;
  }
</style>
<style lang="scss">
  @media only screen and (min-width: 641px) {
    .remodal {
      max-width: 700px !important;
    }
  }
  .remodal img {
    height: 100% !important;
    width: 100% !important;
  }
</style>
<style lang="scss">
  .show-password {
    margin-left: -25px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 0px !important;
    cursor: pointer !important;
  }
</style>
<style lang="scss">
  .error-msg {
    -webkit-box-align: center;
    align-items: center;
  }
  .error-msg .error-center {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    .error-msg {
      display: block;
    }
    .error-msg > p.err-txt{
      left: 370px !important;
      max-width: 350px !important;
    }
    .error-msg > p.file{
      left: 400px !important;
    }
    .error-msg .error-center > p.err-txt{
      position: initial;
      left: 0;
      margin-left: 10px;
      transform: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .error-msg {
      display: flex;
    }
    .error-msg .error-center {
      display: flex;
      margin: 0;
      position: initial;
      top: 0;
      transform: none;
    }
    .error-msg > p.err-txt{
      width: 100%;
    }
  }

  #main #entry-info dl dd ol {
    justify-content: space-evenly !important;
  }

  .date-err {
    border: solid 1px #f00 !important;
  }
  .link-text {
    text-decoration: underline !important;
    cursor: pointer;
    color: #1976d2 !important;
  }

  .text-red {
    color: red;
  }
</style>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
