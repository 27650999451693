<template>
  <v-card
    class="mx-auto"
    max-width="400"
  >

    <div v-if="loading" style="width: 100%;">
      <v-skeleton-loader
        class="mx-auto"
        width="300"
        max-width="100%"
        type="list-item-two-line"
      ></v-skeleton-loader>
    </div>

    <v-list v-else one-line>
      <v-list-item-group
        active-class="pink--text"
        multiple
      >
        <template v-for="(item,i) in notifies">
          <v-divider v-if="i > 0" :key="`divider-${i}`"></v-divider>
          <v-list-item
            :key="item.notification_no"
            @click="onItemClick(item)"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <!-- <v-list-item-subtitle v-text="item.message"></v-list-item-subtitle> -->
                {{ item.message }}
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>

        <template v-if="!notifies || notifies.length === 0">
          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t('NOTIFY_NOT_FOUND')"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import useNotification from '@/stores/useNotification'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const notifyStore = useNotification()
    return {
      notifyStore
    }
  },
  data() {
    return {
      selected : []
    }
  },
  methods : {
    onItemClick(notify) {
      if (notify) {
        this.notifyStore.readNotify(notify.notification_no)
        if (notify.link_url) {
          this.$router.push(notify.link_url).catch(() => {})
        }
      }
    }
  },
  computed : {
    notifies() {
      return this.notifyStore.notifies
    },
    loading() {
      return this.notifyStore.loading
    }
  },
  created() {
    console.log('created')
  },
  mounted() {
    console.log('mounted')
  },
  changed() {
    console.log('changed')
  }
})
</script>
<style scoped lang="scss">
  .v-list{
    max-height:500px;
    overflow-y:auto
  }
</style>
