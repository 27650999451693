/**
 * 定数定義
 */
const PAGE_NAMES = {
  LOGIN_PAGE_NAME                         : 'login',
  TOP_PAGE_NAME                           : 'index',
  DETAIL_PAGE_NAME                        : 'detail',
  CONTACT_PAGE_NAME                       : 'contact',
  ITEM_CONTACT_PAGE_NAME                  : 'item_contact',
  STOCK_CONTACT_PAGE_NAME                 : 'stock_contact',
  NEW_MEMBER_REGISTRATION_PAGE_NAME       : 'entryinfo',
  MY_PAGE_PAGE_NAME                       : 'mypage',
  BID_HISTORY_PAGE_NAME                   : 'history',
  CHANGE_INFO_PAGE_NAME                   : 'change_info',
  CARD_INFO_PAGE_NAME                     : 'card_info',
  REISSUE_PASSWORD_PAGE_NAME              : 'reissue_password',
  AUCTION_HISTORY_PAGE_NAME               : 'auction_history',
  AUCTION_LIST_PAGE_NAME                  : 'auction',
  AUCTION_DETAIL_PAGE_NAME                : 'auction_detail',
  STOCK_LIST_PAGE_NAME                    : 'stock',
  STOCK_DETAIL_PAGE_NAME                  : 'stock_detail',
  TENDER_LIST_PAGE_NAME                   : 'tender',
  TENDER_DETAIL_PAGE_NAME                 : 'tender_detail',
  GUIDE_PAGE_NAME                         : 'guide',
  GUIDE_AUCTION_PAGE_NAME                 : 'aboutauction',
  GUIDE_AUCTION_AGENCY_PAGE_NAME          : 'auctionagency',
  GUIDE_AUCTION_BUY_PAGE_NAME             : 'buy',
  GUIDE_AUCTION_SELL_PAGE_NAME            : 'sell',
  OTHER_GUIDE_DELIVERY_PAGE_NAME          : 'delivery',
  OTHER_GUIDE_COMMISSION_PAGE_NAME        : 'commission',
  OTHER_GUIDE_TERMS_PAGE_NAME             : 'terms',
  OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME     : 'auctionTerms',
  OTHER_GUIDE_TOKUSHO_PAGE_NAME           : 'tokusho',
  OTHER_GUIDE_FAQ_SUPPORT_PAGE_NAME       : 'faqSupport',
  OTHER_PAGE_NAME                         : 'other',
  OTHERS_PAGE_NAME                        : 'others',
  ORDER_RULE_PAGE_NAME                    : 'order-rule',
  PRIVACY_PAGE_NAME                       : 'privacy',
  PROFILE_PAGE_NAME                       : 'profile',
  FAQ_PAGE_NAME                           : 'faq',
  VERIFY                                  : 'verify',
  INSPECTION_LIST_PAGE_NAME               : 'inspection',
  INSPECTION_REQUEST_PAGE_NAME            : 'inspection_request',
  INSPECTION_AUCTION_REQUEST_PAGE_NAME    : 'inspection_auction_request',
  INSPECTION_PURCHASE_REQUEST_PAGE_NAME   : 'inspection_purchase_request',
  INSPECTION_AUCTION_REQUEST_PREVIEW      : 'inspection_request_preview',
  INSPECTION_AUCTION_REQUEST_EDIT         : 'inspection_request_edit',
  CHAT_REQUEST_PAGE_NAME                  : 'chat_request',
  CHAT_INQUIRY_PAGE_NAME                  : 'chat_inquiry',
  CHAT_BID_PAGE_NAME                      : 'chat_bid',
  SYSTEM_ERROR_PAGE_NAME                  : 'system_error',
}

// Page title
const DEFAULT_PAGE_TITLE = 'UEJオークション'
const PAGE_TITLES = {
  VERIFY                            : `会員申請確認｜${DEFAULT_PAGE_TITLE}`,
  SYSTEM_ERROR_PAGE_NAME            : `システムエラー｜${DEFAULT_PAGE_TITLE}`,
}

const LOCAL_STORE_LABEL = {
  SESSION_TOKEN : 'ss_id',
  MEMBER_NO     : 'member_no'
}

module.exports = {
  PAGE_NAMES,
  PAGE_TITLES,
  LOCAL_STORE_LABEL
}
