import {defineStore} from 'pinia'
import Methods from '../api/methods'

const useNotification = defineStore('useNotification', {
  state : () => {
    return {
      locale   : 'ja',
      loading  : false,
      errors   : [],
      selected : [],
      notifies : [],
    }
  },
  actions : {
    getNotification() {
      console.log('getNotification')
      this.loading = true
      Methods.apiExcute('private/get-notification', this.locale)
        .then(data => {
          this.loading = false
          this.notifies = data
        })
        .catch(error => {
          this.loading = false
          console.log('error', error)
        })
    },
    readNotify(notificationNo) {
      console.log('readNotify')
      this.loading = true
      Methods.apiExcute('private/read-notification', this.locale, {
        notification_no : notificationNo
      })
        .then(data => {
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.log('error', error)
        })
    },
  },
  getters : {
  },
})

export default useNotification
