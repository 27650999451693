/**
 * 日本語
 */
export default {
  SIDE_LOGO_TITLE                         : 'ライマ',
  SITE_TITLE                              : 'ライマ【ネットで完結】バイク査定買取＆絶対売れるオークション',
  NAME                                    : '日本語',
  LANGUAGE_CODE                           : 'jp',
  SIDE_NAME                               : 'バイクのオークション',
  SIDE_NAME_SP                            : '「ライダーズマーケット」',
  PAGE_TOP                                : 'TOP',
  PAGE_PURCHASE_OF_CONSTRUCTION_EQUIPMENT : '商品購入',
  PAGE_AUCTION                            : 'オークション',
  PAGE_AUCTION_EXCAVATOR                  : 'ショベル他',
  PAGE_AUCTION_CRANE                      : 'クレーン',
  PAGE_TENDER                             : '封入',
  PAGE_TENDER_EXCAVATOR                   : 'ショベル他',
  PAGE_TENDER_CRANE                       : 'クレーン',
  PAGE_INVENTORY_INFORMATION              : '在庫',
  PAGE_INVENTORY_INFORMATION_EXCAVATOR    : 'ショベル他',
  PAGE_INVENTORY_INFORMATION_CRANE        : 'クレーン',
  PAGE_INSPECTION                         : '買取査定・出品',
  PAGE_INSPECTION_REQUEST                 : '新規買取査定依頼',
  PAGE_AUCTION_REQUEST                    : '出品代行依頼',

  HEADER_BIDDING_BUTTON_LABEL             : '入札中',
  HEADER_FAVORITE_LIST_BUTTON_LABEL       : 'お気に入りリスト',
  HEADER_LOGOUT_BUTTON_LABEL              : 'ログアウト',
  PAGE_MEMBER_REGISTRATION                : '会員について',

  PAGE_USERS_GUIDE                        : 'ご利用ガイド',
  PAGE_ABOUT_AUCTION_LISTING              : 'オークション出品について',
  PAGE_ABOUT_AUCTION_LISTING_SERVICE      : 'オークション出品代行について',
  PAGE_BUY_MOTORCYCLE                     : 'バイクを買いたい方',
  PAGE_SELL_MOTORCYCLE                    : 'バイクを売りたい方',
  PAGE_DELIVERY                           : 'ライマ配送手配',

  PAGE_MARKET_TERMS                       : 'ライマ利用規約',
  PAGE_PRIVACY_POLICY                     : 'プライバシーポリシー',
  PAGE_VARIOUS_COMMISSIONS                : '各種手数料',
  PAGE_TERMS                              : '利用規約',
  PAGE_TOKUSHO                            : '特定商取引法の表示',
  PAGE_AUCTION_TERMS                      : 'オークション利用規約',
  PAGE_FAQ_SUPPORT                        : 'サポート・困ったときのQ&A',
  PAGE_MEMBER_LOGIN                       : '会員登録・ログイン',

  PAGE_MEMBER_REGISTRATION_NAME           : '会員登録申請',
  PAGE_LOGIN                              : 'ログイン',
  PAGE_NEW_MEMBER_REGISTRATION            : '新規会員登録',
  PAGE_MY_PAGE                            : 'マイページ',
  PAGE_BEGINNER_GUIDE                     : 'ご利用ガイド',
  PAGE_GUIDE_WEB_AUCTION_FLOW             : 'WEBオークションの流れ',
  PAGE_GUIDE_WEB_AUCTION                  : 'オークション出品について',
  PAGE_GUIDE_WEB_AUCTION_AGENCY           : 'オークション出品代行について',
  PAGE_GUIDE_WEB_AUCTION_BUY              : 'バイクを買いたい方',
  PAGE_GUIDE_WEB_AUCTION_SELL             : 'バイクを売りたい方',
  PAGE_GUIDE_WEB_AUCTION_TAB1             : 'WEBオークション',
  PAGE_GUIDE_WEB_AUCTION_TAB2             : 'WEB封入出展',
  PAGE_GUIDE_WEB_AUCTION_TAB3             : '在庫機商談',
  PAGE_GUIDE_BID_MEETING_FLOW             : 'オークションの流れ',
  LINK_VENUE_ACCESS                       : '会場アクセス',
  LINK_INTRODUCTION_SECONDHAND_CAR_CENTER : '中古車センターのご紹介',
  LINK_ICHIKAWA_SECONDHAND_CAR_CENTER     : '市川中古車センター',
  LINK_KOBE_SECONDHAND_CAR_CENTER         : '神戸中古車センター',
  LINK_YOKOHAMA_SECONDHAND_CAR_CENTER     : '横浜中古車センター',
  PAGE_GUIDANCE                           : 'ご案内',
  PAGE_OTHER_GUIDANCE                     : 'その他のご案内',
  PAGE_OTHER_GUIDANCE_DELIVERY            : '配送について',
  PAGE_OTHER_GUIDANCE_COMMISSION          : '各種手数料',
  PAGE_OTHER_GUIDANCE_TERMS               : '利用規約',
  PAGE_OTHER_GUIDANCE_TERMS_AUCTION       : 'オークション利用規約',
  PAGE_OTHER_GUIDANCE_FAQ_SUPPORT         : 'サポート・困った時のQ&amp;A',
  LINK_GUIDANCE_FAQ                       : 'よくある質問',
  PAGE_GUIDANCE_CONTACT                   : 'お問い合わせ',
  LINK_IN_HOUSE_CATALOG                   : '自社カタログ',
  LINK_FOR_JAPANESE_PC                    : '国内のユーザー様は<br>こちらより',
  LINK_FOR_JAPANESE_SP                    : '国内のユーザー様はこちらより',
  LINK_COMPANY_INFO                       : '会社案内',
  LINK_COMPANY_PROFILE                    : '会社概要',
  LINK_COMPANY_ORDER_RULE                 : '取引条件',
  LINK_COMPANY_PRIVACY                    : '個人情報の保護',
  FOOTER_COPYRIGHT                        : '<small>Copyright © 2023 All rights reserved</small>',
  FOOTER_KOBUTSU                          : '<span>古物商許可番号 </span><span>1234567890</span>',

  LABEL_LOGINID                                 : 'メールアドレス',
  PLACEHOLDER_USERID                            : '',
  LABEL_PASSWORD                                : 'パスワード',
  PLACEHOLDER_PASSWORD                          : '',
  LABEL_USERNAME                                : '担当者名',
  PLACEHOLDER_USERNAME                          : '',
  PS_USERNAME_BEFORE                            : 'ログインする担当者さまのお名前をご記入ください。',
  PS_USERNAME_AFTER                             : 'ログイン後、担当者別で履歴確認することができます。',
  LABEL_SAVE_ID_PASSWORD_USERNAME               : 'メールアドレス・パスワードを保存',
  LINK_FORGOT_PASSWORD                          : 'パスワードを忘れた方はコチラ',
  LINK_FORGOT_PASSWORD_NAME                     : 'パスワードをお忘れの方',
  LABEL_PARTICIPATION_TERMS                     : '参加規約',
  LABEL_AGREE_TERMS_PARTICIPATION               : '参加規約に同意する',
  LINK_NEW_MEMBER_REGISTRATION_APPLICATION_FREE : '新規会員登録(無料)',
  PS_MEMBERSHIP_REGISTRATION_REQUIRED           : '※商品の価格を見るには会員登録が必要です。',
  OTHER_LANGUAGE                                : 'English',
  OTHER_LANGUAGE_SHOT                           : 'EN',
  OTHER_LANGUAGE_CODE                           : 'en',
  DATE_FORMAT                                   : 'YYYY/MM/DD(dd)',
  DATE_TIME_FORMAT                              : 'YYYY/MM/DD HH:mm',
  ROW_BID_DATE_TIME_FORMAT                      : 'MM/DD HH:mm',

  TEXT_FORMAT       : '^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$',
  TEXT_FORMAT_ERROR : '半角英数字・全角文字・記号で入力してください。',

  CURRENT_PASSWORD_ERROR_MESSAGE : 'パスワードの変更時は、現在のパスワードを入力してください。',
  PASSWORD_CONFIRM_ERROR_MESSAGE : 'パスワードとパスワード(確認用)が一致していません。',
  MAIL_CONFIRM_ERROR_MESSAGE     : 'メールアドレスとメールアドレス(確認用)が一致していません。',
  MEMBER_INFO_EMPTY_MESSAGE      : '入力してください。',

  NO_RECOMMEND_ITEM_IN_SESSION_MESSAGE  : '現在{names}を開催中です',
  NO_RECOMMEND_ITEM_SCHEDULED_MESSAGE   : '次回のオークション公開は、<br>{start_date}～の予定です<br>どうぞ楽しみにお待ちください',
  NO_RECOMMEND_ITEM_NO_SCHEDULE_MESSAGE : '次回のオークション公開をどうぞお楽しみにお待ちください',

  TOP_PAGE_AUCTION_RECOMMEND_ITEMS_VIEW_HEADER            : '人気の商品',
  TOP_PAGE_AUCTION_NEW_ITEMS_VIEW_HEADER                  : '在庫機　新着',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_PREFIX_LABEL : 'オークション',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_SUBFIX_LABEL : '出展機一覧',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_PREFIX_LABEL  : '封入出展',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_SUBFIX_LABEL  : '出展機一覧',
  TOP_PAGE_CRANE_LIST_BUTTON_PREFIX_LABEL                 : 'クレーン',
  TOP_PAGE_CRANE_LIST_BUTTON_SUBFIX_LABEL                 : '一覧',
  TOP_PAGE_AUCTION_NEW_ITEMS_BUTTON_LABEL                 : '一覧',
  TOP_PAGE_INVENTORY_MACHINE_LIST_BUTTON_LABEL            : '在庫機一覧',
  TOP_PAGE_LIST_OF_CRANE_STOCK_MACHINES_BUTTON_LABEL      : 'クレーン在庫機一覧',
  TOP_PAGE_AUCTION_SCHEDULE_VIEW_HEADER                   : 'オークションスケジュール',
  TOP_PAGE_AUCTION_STATUS_AUCTION_IN_SESSION_LABEL        : '開催中',
  TOP_PAGE_AUCTION_STATUS_AUCTION_WILL_BE_HELD_LABEL      : '開催予定',
  TOP_PAGE_AUCTION_STATUS_AUCTION_ENDED_LABEL             : '開催終了',
  TOP_PAGE_ITEM_MODEL_LABEL                               : 'モデル',
  TOP_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                 : '号機',
  TOP_PAGE_ITEM_MODEL_YEAR_LABEL                          : '年式',
  TOP_PAGE_NOTICES_VIEW_HEADER                            : 'お知らせ',
  TOP_PAGE_FOR_NEWBIE_LABEL                               : 'はじめての方へ',
  TOP_PAGE_AUCTION_GUIDE_LINK_LABEL                       : 'バイクを売りたい方',
  TOP_PAGE_PRICE_CURRENCY_MARK                            : '￥',

  TOP_PAGE_CUBIC_CAPACITY                                 : '排気量',
  TOP_PAGE_MILEAGE                                        : '走行距離',
  TOP_PAGE_INSPECTION_DATE                                : '車検',
  TOP_PAGE_SHOW_LIST_BTN                                  : 'オークション一覧',
  TOP_PAGE_NOTIFICATION                                   : '通知',

  MEMBER_REQUEST_DOCUMENT_EXPLANATION_MESSAGE          : ' 会員ご登録申請にあたり当社にて審査を行っています。</br>以下からダウンロードいただき、必要書類をPDF（JPEG/PNGも可）にてご用意お願いします。',
  MEMBER_REQUEST_DOCUMENT_CAUTION_MESSAGE              : '※個人名義で会員登録される場合、2は会社名でなく個人名での留意事項への記載、3は免許証など個人の身分証明書のコピーをご提出下さい。',
  MEMBER_REQUEST_DOCUMENT_TITLE                        : '会員申請必要書類',
  MEMBER_REQUEST_DOWNLOAD_SAMPLE_DOCUMENT_BUTTON_LABEL : '申請必要書類ダウンロード',
  MEMBER_REQUEST_FORM_CAUTION_TITLE                    : '【注意事項】日本国内の建設機械ユーザー様へ',
  MEMBER_REQUEST_FORM_CAUTION_MESSAGE                  : '<span class="fontB">【注意事項】日本国内の建設機械ユーザー様へ</span><br> UEJ株式会社では、。。。<br> ご購入をご希望の方は<a href="https://www.kobelco-kenki.co.jp/" target="_blank">販売サービス窓口（コベルコ建機のホームページへ移動します）</a>からお問い合わせください。',
  MEMBER_REQUEST_FORM_ENTRY_FORM_INFO_MESSAGE          : '<em class="req">必須</em>のついている項目は必ずご入力ください。',
  MEMBER_REQUEST_FORM_COUNTRY_LABEL                    : '国<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_COMPANY_NAME_LABEL               : '会社名・団体名<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_REPRESENTATIVE_DIRECTOR_LABEL    : '代表者氏名',
  MEMBER_REQUEST_FORM_BRANCH_OFFICE_LABEL              : '支社・営業所名',
  MEMBER_REQUEST_FORM_USER_NAME_LABEL                  : '担当者氏名<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_POST_CODE_LABEL                  : '郵便番号',
  MEMBER_REQUEST_FORM_ADDRESS_LABEL                    : '住所',
  MEMBER_REQUEST_FORM_SHIP_POST_CODE_LABEL             : '配送先郵便番号',
  MEMBER_REQUEST_FORM_SHIP_ADDRESS_LABEL               : '配送先住所',
  MEMBER_REQUEST_FORM_REQUIRED_LABEL                   : '必須',
  MEMBER_REQUEST_FORM_REQUIRED_VALIDATION              : 'を入力してください。',
  MEMBER_REQUEST_FORM_TEL_LABEL                         : '電話番号',
  MEMBER_REQUEST_FORM_EMAIL_LABEL                      : 'メールアドレス',
  MEMBER_REQUEST_FORM_CONFIRM_EMAIL_LABEL              : 'メールアドレス(確認用)',
  MEMBER_REQUEST_FORM_PASSWORD_LABEL                   : 'パスワード',
  MEMBER_REQUEST_FORM_CONFIRM_PASSWORD_LABEL           : 'パスワード(確認用)',
  MEMBER_REQUEST_FORM_PASSWORD_RULE                    : '※パスワードは8文字以上、14文字以内で英字、数字を含めて指定してください。',
  MEMBER_REQUEST_FORM_ANTIQUE_FILE_LABEL               : '貴社古物商許可書<br class="only_pc">のコピー<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_EXPORT_FILE_LABEL                : '署名済み輸出管理<br class="only_pc">に関する<br class="only_sp">留意事項<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_NAME_CARD_FILE_LABEL             : '代表者様の<br class="only_pc">名刺コピー<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_AGREE_LABEL                      : '個人情報の<br class="only_pc">取り扱いについて<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL             : '同意する',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1   : '入力内容を送信いただく前に、必ず「',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2   : 'プライバシーポリシー',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3   : '」をご覧ください。',
  MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL         : '削除',
  MEMBER_REQUEST_FORM_SUBMIT_LABEL                     : '入力内容を確認する',
  MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL    : '検索',
  MEMBER_REQUEST_FORM_FULL_WIDTH_LABEL                 : '[全角]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL         : '[半角数字]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_ALPHANUMERICAL_LABEL  : '[半角英数字]',
  MEMBER_REQUEST_FORM_POST_CODE_PLACEHOLDER            : '',
  MEMBER_REQUEST_FORM_ADDRESS_PLACEHOLDER              : '',
  MEMBER_REQUEST_FORM_PREFECTURES_PLACEHOLDER          : '',
  MEMBER_REQUEST_FORM_MUNICIPALITIES_PLACEHOLDER       : '',
  MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER             : '',
  MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER         : '',
  MEMBER_REQUEST_FORM_FAX_NUMBER_PLACEHOLDER           : '',
  MEMBER_REQUEST_FORM_WITHDRAW_LABEL                   : '退会する',
  MEMBER_PAGE_CHANGE_TITLE                              : '会員情報の確認と変更',
  MEMBER_PAGE_CHANGE_UPDATE_BUTTON_LABEL                : '変更する',

  MEMBER_REQUEST_FORM_NICKNAME_LABEL                    : 'ニックネーム',
  MEMBER_REQUEST_FORM_NAME_LABEL                        : '氏名',
  MEMBER_REQUEST_FORM_FURIGANA_LABEL                    : 'フリガナ',
  MEMBER_REQUEST_FORM_BIRTHDAY_LABEL                    : '生年月日',
  MEMBER_REQUEST_TEXT_FORMAT_ERROR                      : '半角英数字・記号で入力してください。',
  MEMBER_EDIT_FORM_ADDRESS_LABEL                        : '住所',
  MEMBER_EDIT_FORM_FILE_LABEL                           : '添付ファイル',
  MEMBER_EDIT_FORM_FILE_CHANGING_LABEL                  : '※変更がある場合はお手数ですがお問い合わせください',
  MEMBER_REQUEST_FORM_INVOICE_NUMBER_LABEL              : 'インボイス登録番号',
  MEMBER_REQUEST_FORM_INVOICE_NUMBER_LABEL2             : '(国税庁から登録番号を取得済みの方のみ)',
  MEMBER_REQUEST_FORM_INVOICE_NUMBER_PLACEHOLDER        : '',
  MEMBER_REQUEST_FORM_TOP_CONTENT7                      : '<em class="req">必須</em>のついている項目は必ずご記入下さい。',

  MEMBER_REQUEST_CONFIRM_TITLE                          : '確認',
  MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE            : '入力項目をご確認いただき、お間違いがなければ「送信する」をクリックしてください。',
  MEMBER_REQUEST_CONFIRM_NICKNAME_LABEL                 : 'ニックネーム',
  MEMBER_REQUEST_CONFIRM_NAME_LABEL                     : '氏名',
  MEMBER_REQUEST_CONFIRM_FURIGANA_LABEL                 : 'フリガナ',
  MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL                : '郵便番号',
  MEMBER_REQUEST_CONFIRM_ADDRESS_LABEL                  : '住所',
  MEMBER_REQUEST_CONFIRM_SHIP_POST_CODE_LABEL           : '配送先郵便番号',
  MEMBER_REQUEST_CONFIRM_SHIP_ADDRESS_LABEL             : '配送先住所',
  MEMBER_REQUEST_CONFIRM_EMAIL_LABEL                    : 'メールアドレス',
  MEMBER_REQUEST_CONFIRM_CONFIRM_EMAIL_LABEL            : 'メールアドレス<br class="only_pc">(確認用)',
  MEMBER_REQUEST_CONFIRM_PASSWORD_LABEL                 : 'パスワード',
  MEMBER_REQUEST_CONFIRM_CONFIRM_PASSWORD_LABEL         : 'パスワード(確認用)',
  MEMBER_REQUEST_YEAR_LABEL                             : '年',
  MEMBER_REQUEST_MONTH_LABEL                            : '月',
  MEMBER_REQUEST_DAY_LABEL                              : '日',

  MEMBER_REQUEST_CONFIRM_TEL_LABEL                     : '電話番号',
  MEMBER_REQUEST_CONFIRM_FAX_LABEL                     : 'FAX番号',
  MEMBER_REQUEST_CURRENT_PASSWORD_LABEL                : '現在のパスワード',
  MEMBER_REQUEST_NEW_PASSWORD_LABEL                    : '新しいパスワード',
  MEMBER_REQUEST_CONFIRM_NEW_PASSWORD_LABEL            : '新しいパスワード(確認用)',
  MEMBER_REQUEST_CONFIRM_EXPORT_FILE_LABEL             : '貴社古物商許可書<br class="only_pc">のコピー',
  MEMBER_REQUEST_CONFIRM_ANTIQUE_FILE_LABEL            : '署名済み輸出管理<br class="only_pc">に関する<br class="only_sp">留意事項',
  MEMBER_REQUEST_CONFIRM_NAME_CARD_FILE_LABEL          : 'ご担当者様の<br class="only_pc">名刺コピー',
  MEMBER_REQUEST_CONFIRM_AGREE_LABEL                   : '個人情報の',
  MEMBER_REQUEST_CONFIRM_AGREE_LABEL2                  : '取り扱いについて',
  MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL             : '戻る',
  MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL             : '送信する',

  MEMBER_REQUEST_COMPLETION_MESSAGE                    : '会員登録が完了しました。<br />入力いただいたメールアドレスを確認し有効化してください。',
  MEMBER_REQUEST_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL   : 'ログイン画面に戻る',

  MEMBER_EDIT_CONFIRM_EXPLANATION_MESSAGE              : '入力項目をご確認いただき、お間違いがなければ「更新する」をクリックしてください。',
  MEMBER_EDIT_CONFIRM_SEND_BUTTON_LABEL                : '更新',
  MEMBER_EDIT_CONFIRM_CLEAR_BUTTON_LABEL               : '削除',
  MEMBER_EDIT_COMPLETION_MESSAGE                       : '会員情報が更新されました。',
  MEMBER_EDIT_SKIP_BID_CONFIRM_LABEL                   : '入札確認モーダル',
  MEMBER_EDIT_SKIP_BID_CONFIRM_TXT                     : '表示しない',
  MEMBER_EDIT_DONT_SKIP_BID_CONFIRM_TXT                : '表示する',

  REISSUE_PASSWORD_EXPLANATION_MESSAGE                 : 'パスワードをお忘れの方は会員登録に使用されたメールアドレスを入力してください。<br />「送信する」ボタンを押されると、仮パスワードが登録メールアドレスに届きます。',
  REISSUE_PASSWORD_CAUTION_MESSAGE                     : 'メールアドレスをお忘れの場合は事務局にお問い合わせください。',
  REISSUE_PASSWORD_FORM_ID_LABEL                       : 'ID',
  REISSUE_PASSWORD_FORM_EMAIL_LABEL                    : 'メールアドレス',
  REISSUE_PASSWORD_FORM_CONFIRM_EMAIL_LABEL            : 'メールアドレス(確認用)',
  REISSUE_PASSWORD_FORM_ID_PLACEHOLDER                 : '半角英数字',
  REISSUE_PASSWORD_FORM_EMAIL_PLACEHOLDER              : '半角英数字',
  REISSUE_PASSWORD_FORM_SUBMIT_LABEL                   : '送信する',
  REISSUE_PASSWORD_COMPLETION_MESSAGE                  : '新しいパスワードをメールアドレス宛に送信いたしました。',
  REISSUE_PASSWORD_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL : 'ログイン画面に戻る',
  REISSUE_PASSWORD_COMPLETION_GO_TO_TOP_BUTTON_LABEL   : 'トップ画面に戻る',

  MEMBER_VERIFY_LOADING                                : 'アカウントを確認しています。',

  AUCTION_PAGE_SEARCH_HEADER    : 'オークション出展　検索条件',
  AUCTION_PAGE_HEADER_LABEL     : 'オークション出展',
  TENDER_PAGE_SEARCH_HEADER     : '封入出展機　検索条件',
  TENDER_PAGE_HEADER_LABEL      : '封入出展機',
  STOCK_PAGE_SEARCH_HEADER      : '在庫機　検索条件',
  STOCK_PAGE_HEADER_LABEL       : '在庫機',
  INSPECTION_PAGE_SEARCH_HEADER : '検索条件',
  INSPECTION_PAGE_HEADER_LABEL  : '買取査定・出品',

  AUCTION_PAGE_SEARCH_TYPE    : 'オークション',
  TENDER_PAGE_SEARCH_TYPE     : '封入出展',
  STOCK_PAGE_SEARCH_TYPE      : '在庫機',

  ITEM_SEARCH_KEYWORD_LABEL                          : 'キーワード',
  ITEM_SEARCH_KEYWORD_PLACEHOLDER                    : '',
  ITEM_SEARCH_INVENTORY_AREA_LABEL                   : '在庫地',
  ITEM_SEARCH_CATEGORY_LABEL                         : 'カテゴリ',
  ITEM_SEARCH_MODEL_YEAR_LABEL                       : '年式',
  ITEM_SEARCH_PRICE_LABEL                            : '価格',
  ITEM_SEARCH_PRICE_UNIT                             : '万',
  ITEM_SEARCH_FAVORITE_LABEL                         : 'お気に入り',
  ITEM_SEARCH_BIDDING_LABEL                          : '入札中',
  ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL         : '最低落札超え',
  ITEM_SEARCH_SOLD_OUT_LABEL                         : 'SOLD OUT以外',
  ITEM_SEARCH_RECOMMEND_LABEL                        : 'オススメ',
  ITEM_SEARCH_NEW_LABEL                              : 'NEW',
  ITEM_SEARCH_SEARCH_BUTTON_LABEL                    : '検索',
  ITEM_SEARCH_CONDITIONS_LABEL                       : '条件',
  ITEM_SEARCH_SEARCH_RESULTS_LABEL                   : '検索結果',
  ITEM_SEARCH_SEARCH_RESULTS_SUBJECT_LABEL           : '件',
  ITEM_SEARCH_SEARCH_RESULTS_NUMBER_SUBJECT_LABEL    : '件',
  ITEM_SEARCH_CATEGORY_SEARCH_RESULTS_NUMBER_LABEL   : '件',
  ITEM_SEARCH_PRINT_ITEMS_CSV_BUTTON_LABEL           : '出展機一覧リストCSV',
  ITEM_SEARCH_BULK_BIDDING_BUTTON_LABEL              : '一括入札',
  ITEM_SEARCH_CURRENT_PRICE_SORT_LABEL               : '現在価格',
  ITEM_SEARCH_RESULTS_LIMIT_LABEL                    : '表示件数',
  ITEM_SEARCH_BULK_BIDDING_MODAL_HEADER              : '一括入札',
  ITEM_SEARCH_CONFIRM_BIDDING_MODAL_HEADER           : '入札確認',
  ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL    : '入札',
  ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL : '閉じる',

  ITEM_SEARCH_MAKER_LABEL                            : 'メーカー',
  ITEM_SEARCH_BODY_TYPE_LABEL                        : 'バイクの種類',
  ITEM_SEARCH_CUBIC_CAPACITY_LABEL                   : '排気量',
  ITEM_SEARCH_MILEAGE_LABEL                          : '走行距離',
  ITEM_SEARCH_BIKE_TYPE_LABEL                        : '車種',
  ITEM_SEARCH_FIRST_INSPECTION_LABEL                 : '初度登録年',
  ITEM_SEARCH_AREA_LABEL                             : '地域',

  ITEM_ROW_TOP_STATUS_LABEL                          : 'あなたがTOP',
  ITEM_ROW_SECOND_STATUS_LABEL                       : '2位応札',
  ITEM_ROW_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL     : '最低落札超え',
  ITEM_ROW_NOT_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL : '最低落札未達',
  ITEM_ROW_MORE_LITTLE_LABEL                         : 'あと少し',
  ITEM_ROW_CANCEL_LABEL                              : '出品取消',
  ITEM_ROW_LOWEST_BID_PRICE_LABEL                    : '最低落札価格',
  ITEM_ROW_AUCTION_STATUS_LABEL                      : 'オークション',
  ITEM_ROW_MAKER_LABEL                               : 'メーカー',
  ITEM_ROW_YYYY_LABEL                                : '年式',
  ITEM_ROW_YYYY_UNIT_LABEL                           : '',
  ITEM_ROW_YYYY_NOT_CLEAR_LABEL                      : '',
  ITEM_ROW_MDLGR_LABEL                               : 'モデル',
  ITEM_ROW_OPETIM_LABEL                              : '総走行距離',
  ITEM_ROW_OPETIM_NOT_CLEAR_LABEL                    : '',
  ITEM_ROW_ACTSERNO_LABEL                            : '号機',
  ITEM_ROW_CURRENT_PRICE_LABEL                       : '現在価格',
  ITEM_ROW_START_PRICE_LABEL                         : 'スタート価格',
  ITEM_ROW_PRICE_CURRENCY_MARK                       : '￥',
  ITEM_ROW_START_STATUS_LABEL                        : '開始予定',
  ITEM_ROW_END_STATUS_LABEL                          : '終了予定',
  ITEM_ROW_ENDED_STATUS_LABEL                        : '終了',
  ITEM_ROW_SELLING_PRICE_LABEL                       : '販売価格',
  ITEM_ROW_SOLD_OUT_LABEL                            : 'SOLD OUT',
  ITEM_ROW_BID_PRICE_LABEL                           : '入札価格',
  ITEM_ROW_BID_BUTTON_LABEL                          : '入札',
  ITEM_ROW_MEMBERS_ONLY_LABEL                        : '会員限定',
  ITEM_ROW_LOGIN_LINK_LABEL                          : 'ログイン',
  ITEM_ROW_NEW_MEMBER_REGISTRATION_BUTTON_LABEL      : '新規会員登録(無料)',
  ITEM_ROW_DETAIL_LINK_LABEL                         : '詳細',
  ITEM_ROW_BULK_BIDDING_MODAL_DELETE_BUTTON_LABEL    : '削除',
  ITEM_ROW_BID_SUCCESS_MESSAGE                       : '入札を受け付けました。',
  ITEM_ROW_CANCEL_SUCCESS_MESSAGE                    : '入札取り消しを受け付けました。',
  ITEM_ROW_BID_CONFIRM_MESSAGE                       : 'お間違いなければ入札ボタンをクリックしてください。',
  ITEM_ROW_MULTIPLE_ITEMS_LABEL                      : '台セット',
  ITEM_ROW_BID_CURRENT_1PITCH_ERROR_MESSAGE          : '現在価格+1ピッチ以上の入札をしてください。',

  ITEM_ROW_BIKE_TYPE_LABEL      : '車種',
  ITEM_ROW_CUBIC_CAPACITY       : '排気量',
  ITEM_ROW_MILEAGE              : '走行距離',
  ITEM_ROW_INSPECTION_DATE      : '車検',
  ITEM_FREE_FIELD_FUMEI         : '不明',
  ITEM_ROW_PRICE_WITH_TAX_LABEL : '税込',
  ITEM_ROW_BID_CONFIRM_SKIP     : '次回以降は表示しない',

  ITEM_ROW_EXHIBIT_UEJ_LABEL    : 'ライマ出品',
  ITEM_ROW_EXHIBIT_MEMBER_LABEL : '会員出品',

  AUCTION_DETAIL_PAGE_NAME    : 'オークション詳細',
  STOCK_DETAIL_PAGE_NAME      : '在庫機詳細',
  TENDER_DETAIL_PAGE_NAME     : '封入出展詳細',
  INSPECTION_DETAIL_PAGE_NAME : '買取査定依頼詳細',

  DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL           : 'スタート価格',
  DETAIL_BID_STATUS_LABEL                        : '入札状況',
  DETAIL_BID_STATUS_UNBID_LABEL                  : '未入札',
  DETAIL_BID_STATUS_BIDED_LABEL                  : '入札済み',
  DETAIL_BID_STATUS_MORE_LITTLE_LABEL            : 'あと少し',
  DETAIL_BID_STATUS_SUCCESSFUL_BID_RIGHT_LABEL   : '落札権あり',
  DETAIL_BID_AUTOMATIC_BIDDING_LABEL             : '自動入札',
  DETAIL_DOWNLOAD_IMAGES_BUTTON_LABEL            : '画像を一括ダウンロード',
  DETAIL_CONTACT_PRODUCT_BUTTON_LABEL            : 'この製品について問い合わせる',
  DETAIL_DOWNLOAD_INSPECTION_REPORT_BUTTON_LABEL : '検品書をみる',
  DETAIL_PRODUCT_INFORMATION_LABEL               : '製品情報',
  DETAIL_AWAMETER_LABEL                          : '総走行距離',
  DETAIL_MILEAGE_LABEL                           : '走行距離',
  DETAIL_STOCK_LOCATION_LABEL                    : '在庫場所',
  DETAIL_VEHICLE_INSPECTION_LABEL                : '車検',
  DETAIL_MAXIMUM_RATED_TOTAL_LOAD_LABEL          : '最大定格総荷重',
  DETAIL_CR_INSPECTION_LABEL                     : 'CR検査',
  DETAIL_SPECIFICATION_LABEL                     : '仕様',
  DETAIL_HOOK_LABEL                              : 'フック',
  DETAIL_OUTRIGGER_LABEL                         : 'アウトリガー',
  DETAIL_REMARKS_LABEL                           : '備考',
  DETAIL_CONTACT_PRODUCT_BOTTOM_BUTTON_LABEL     : '製品に関するお問い合わせ',
  DETAIL_BACK_TO_ITEM_SEARCH_BUTTON_LABEL        : '検索結果に戻る',
  DETAIL_NOTE_TEXT_TOP                           : '置き場現状有姿渡し・先売優先・保証なし',
  DETAIL_NOTE_TEXT_BOTTOM                        : '重要：保管場所にご注意ください。',
  DETAIL_LIST_OF_STORAGE_LOCATIONS_BUTTON_LABEL  : '保管場所一覧',
  DETAIL_ITEM_LOT_ITEMS_PS_TEXT                  : '※このロットには複数の製品が含まれています。',
  DETAIL_ITEM_PREV_BUTTON_LABEL                  : '前へ',
  DETAIL_ITEM_NEXT_BUTTON_LABEL                  : '次へ',
  DETAIL_ITEM_SNS_SHARE_LABEL                    : 'このバイクをシェアする',
  DETAIL_ITEM_HOLDING_PERIOD_LABEL               : '開催期間',
  DETAIL_ITEM_SNS_SHARE_TEXT                     : '',
  DETAIL_ITEM_BID_REMAINING_TIME                 : '残り時間',
  DETAIL_ITEM_BID_EXTENDED_END                   : '延長終了',
  DETAIL_BID_PRICE_TAX_LABEL                     : '税込価格',
  DETAIL_BID_ITEM_IMAGES                         : '商品画像',
  DETAIL_BID_ITEM_OPTIONAL_IMAGES                : '任意画像',
  DETAIL_BID_CURRENT_CANDIDATE_LABEL             : '入札履歴：最高入札者',
  DETAIL_BID_CURRENT_CANDIDATE_SAN_LABEL         : 'さん',
  DETAIL_ITEM_SHIPPING_FEE_LABEL                 : 'ライマ配送<br class="only_pc">手配送料',
  DETAIL_ITEM_SHIPPING_FEE_NOT_FOUND             : '別途お問い合わせください',
  DETAIL_ITEM_SHIPPING_ADD_NOT_SET               : '配送先住所を設定してください',
  DETAIL_ITEM_SHIPPING_LINK_TXT                  : '配送について',

  CONTACT_PAGE_NAME                  : 'お問い合わせ',
  ITEM_CONTACT_PAGE_NAME             : '中古建機のお問い合わせ',
  CONTACT_FORM_INFO_TEXT             : '以下の項目をご入力ください。<em class="req">必須</em>のついている項目は必ずご入力ください。',
  CONTACT_FORM_CONTACT_CONTENT_LABEL : 'お問い合わせ内容',
  CONTACT_EXPORT_FILE_LABEL          : '添付ファイル',
  CONTACT_FORM_GUEST_TEXT            : 'ゲスト',
  CONTACT_FORM_UDRCARR_LABEL         : '足回り',
  CONTACT_FORM_CAB_LABEL             : '運転室',
  CONTACT_FORM_CLASS_LABEL           : 'クラス',
  CONTACT_FORM_AREA_LABEL            : '保管場所',
  CONTACT_COMPLETION_MESSAGE         : 'お問い合わせが完了しました。',

  INSPECTION_LIST_PAGE_NAME                             : '査定依頼一覧',
  INSPECTION_LIST_FROM_TITLE                            : '買取査定・出品依頼履歴',
  INSPECTION_SEARCH_DATE_LABEL                          : '依頼日',
  INSPECTION_SEARCH_REQUEST_NO_LABEL                    : '申請番号',
  INSPECTION_SEARCH_TITLE_LABEL                         : 'ご依頼内容',
  INSPECTION_SEARCH_CATEGORY_CHECKBOX_LABEL             : '依頼種別',
  INSPECTION_SEARCH_CATEGORY_CHECKBOX_1                 : '査定依頼',
  INSPECTION_SEARCH_CATEGORY_CHECKBOX_2                 : '買取依頼',
  INSPECTION_SEARCH_CATEGORY_CHECKBOX_3                 : '出品代行依頼',
  INSPECTION_SEARCH_CATEGORY_CHECKBOX_4                 : 'オークション出品依頼',
  INSPECTION_SEARCH_SEARCH_BUTTON_LABEL                 : '検索する',
  INSPECTION_SEARCH_NEW_INSPECTION_REQUEST_BUTTON_LABEL : '新規査定依頼をする',
  INSPECTION_SEARCH_LIST_REQUEST_NO_FIELD               : '申請番号',
  INSPECTION_SEARCH_LIST_TITLE_FIELD                    : 'ご依頼内容',
  INSPECTION_SEARCH_LIST_CATEGORY_FIELD                 : '依頼種別',
  INSPECTION_SEARCH_LIST_DATETIME_FIELD                 : '処理日',
  INSPECTION_SEARCH_LIST_STATUS_FIELD                   : 'ステータス',
  INSPECTION_SEARCH_LIST_CONTENT_FIELD                  : '操作',
  INSPECTION_SEARCH_LIST_SHOW_HISTORY_LINK_LABEL        : '履歴を見る',

  ITEM_SEARCH_BIDDING_MODAL_LOGIN_BUTTON_LABEL          : 'ログインする',
  ITEM_SEARCH_CONFIRM_BIDDING_LOGIN_REQUIRED_LABEL      : '会員限定で入札できます。ログインしてください。',
  ITEM_SEARCH_CONFIRM_BIDDING_MODAL_CANCEL_BUTTON_LABEL : '閉じる',
  ITEM_ROW_BID_CURRENT_PRICE_ERROR_MESSAGE              : '現在価格以下の入札はできません。',
  ITEM_ROW_BID_LOWEST_PRICE_ERROR_MESSAGE               : 'スタート価格を下回る入札はできません。',
  ITEM_ROW_BID_SUCCESS_MESSAGE_NOT_TOP                  : '他の方がより高い金額で入札しています。',
  ITEM_SEARCH_CONFIRM_BIDDING_CARD_REQUIRED             : 'マイページよりクレジットカードを登録してください。',

  NOTIFY_NOT_FOUND : '通知がありません。',

  INSPECTION_REQUEST_PAGE_NAME       : '新規査定依頼',
  INSPECTION_FORM_TITLE              : '新規査定依頼',
  INSPECTION_STEP_1_LABEL            : 'STEP-1',
  INSPECTION_STEP_2_LABEL            : 'STEP-2',
  INSPECTION_STEP_3_LABEL            : 'STEP-3',
  INSPECTION_STEP_4_LABEL            : 'STEP-4',
  INSPECTION_FORM_INFO_STEP1_TITLE   : 'STEP-1',
  INSPECTION_FORM_INFO_STEP2_TITLE   : 'STEP-2',
  INSPECTION_FORM_INFO_STEP2_TEXT    : '愛車について',
  INSPECTION_FORM_INFO_STEP2_REQUEST : '内容説明',
  INSPECTION_RELATED_FILE_LABEL      : '関係ファイル',
  INSPECTION_FORM_COMPLETE_TEXT      : '査定依頼を作成しました。',
  ITEM_REQUEST_FORM_SAVE_BTN         : '一時保存する',

  INSPECTION_STEP1_REQUEST_TITLE     : 'ご依頼内容',
  INSPECTION_STEP1_REQUEST_NO        : '申請番号',
  INSPECTION_FORM_MANAGE_NO_NULL     : '',
  INSPECTION_STEP1_ESTIMATE_PRICE    : '他社の参考見積額',
  INSPECTION_STEP1_NAME              : '氏名',
  INSPECTION_STEP1_TEL               : '電話番号',
  INSPECTION_STEP1_ADDRESS           : '住所',
  INSPECTION_STEP1_IDENTITY_VERIFY   : '写真付き本人確認書類',
  INSPECTION_STEP1_AUTH_LETTER       : '委任状',
  INSPECTION_STEP1_ITEM_IMAGES       : '画像アップロード',
  INSPECTION_STEP1_OPTIONAL_IMAGES   : '任意画像アップロード',
  INSPECTION_STEP1_FILE_NOT_SELECTED : 'ファイルが選択されていません',

  INSPECTION_DOCUMENT_FILES_LABEL             : '書類',
  INSPECTION_DOCUMENT_FILE_SELECT             : '参照…',
  INSPECTION_DOCUMENT_FILE_UNSELECTED_MESSAGE : 'ファイルが選択されていません',
  INSPECTION_DOCUMENT_FILE_ARI                : 'あり',
  INSPECTION_DOCUMENT_FILE_NASI               : 'なし',

  INSPECTION_IMAGE_UPLOAD_LABEL      : 'ファイルをアップロード',
  INSPECTION_IMAGE_UPLOAD_LABEL_SP   : 'ファイルを選択',
  INSPECTION_IMAGE_UPLOAD_TITLE      : 'タイトルを入れる',
  INSPECTION_IMAGE_UPLOAD_DRAG_1     : 'アップロードしたいファイルを',
  INSPECTION_IMAGE_UPLOAD_DRAG_2     : 'ここにドラッグ＆ドロップ',
  INSPECTION_IMAGE_UPLOAD_FILE_SEL   : 'またはファイルをアップロード',

  INSPECTION_STEP2_MAKER                  : 'メーカー',
  INSPECTION_STEP2_BODY_TYPE              : 'バイクの種類',
  INSPECTION_STEP2_BIKE_TYPE              : '車種',
  INSPECTION_STEP2_FIRST_INSPECTION       : '年式／初度検査月日',
  INSPECTION_STEP2_INSPEC_EXPIRE          : '車検/有効期間の',
  INSPECTION_STEP2_INSPEC_EXPIRE_2        : '満了する日',
  INSPECTION_STEP2_INSPEC_EXPIRE_PH       : '車検証の内容を記載',
  INSPECTION_STEP2_CUBIC_CAPACITY_1       : '排気量',
  INSPECTION_STEP2_FRAME_NO               : 'シリアル/車台番号',
  INSPECTION_STEP2_MODEL                  : '型式',
  INSPECTION_STEP2_ENGINE_TYPE_1          : 'エンジン型式/',
  INSPECTION_STEP2_ENGINE_TYPE_2          : '原動機の型式',
  INSPECTION_STEP2_FUEL_TYPE              : '燃料の種類',
  INSPECTION_STEP2_FUEL_TYPE_SELECT       : '選択してください',
  INSPECTION_STEP2_COLOR                  : 'カラー',
  INSPECTION_STEP2_METER                  : 'メーター',
  INSPECTION_STEP2_METER_SPEED            : 'スピード',
  INSPECTION_STEP2_METER_TACO             : 'タコ　　',
  INSPECTION_STEP2_ENGINE_SERIAL          : 'エンジンシリアル番号',
  INSPECTION_STEP2_HORN                   : 'ホーン',
  INSPECTION_STEP2_HEAD_LIGHT             : 'ヘッドライト',
  INSPECTION_STEP2_HEAD_LIGHT_HI          : 'HI',
  INSPECTION_STEP2_HEAD_LIGHT_LO          : 'LO',
  INSPECTION_STEP2_WINKER                 : 'ウィンカー前後左右',
  INSPECTION_STEP2_WINKER_FL              : '前方/L',
  INSPECTION_STEP2_WINKER_FR              : '前方/R',
  INSPECTION_STEP2_WINKER_RL              : '後方/L',
  INSPECTION_STEP2_WINKER_RR              : '後方/R',
  INSPECTION_STEP2_TAIL_LIGHT             : 'テールライト',
  INSPECTION_STEP2_TAIL_LIGHT_SMALL       : 'スモール',
  INSPECTION_STEP2_TAIL_LIGHT_BREAK       : 'ブレーキ(フロント・リア)',
  INSPECTION_STEP2_BATTERY_HEALTH         : 'バッテリーの状態',
  INSPECTION_STEP2_MILEAGE                : '走行距離',
  INSPECTION_STEP2_RADIATOR_LEAK          : 'ラジエーター水漏れ',
  INSPECTION_STEP2_ENGINE_START_STATUS    : 'エンジン始動時、',
  INSPECTION_STEP2_ENGINE_START_STATUS_2  : '走行時のエンジンの状態',
  INSPECTION_STEP2_WHITE_SMOKE            : '白煙',
  INSPECTION_STEP2_ENGINE_NOISE           : '異音',
  INSPECTION_STEP2_FRONT_FORK_STATUS      : 'フロントフォークの状態',
  INSPECTION_STEP2_FFORK_STRAIN           : '曲がり　　',
  INSPECTION_STEP2_RUSTY                  : 'サビ　　　',
  INSPECTION_STEP2_OIL_LEAK               : 'オイル漏れ',
  INSPECTION_STEP2_HANDLE                 : 'ハンドルロックは正常に作動しますか？',
  INSPECTION_STEP2_HANDLE_2               : 'わからないの場合は「不明」と記入してください',
  INSPECTION_STEP2_FRONT_BREAK            : 'フロントブレーキ/押して動かすとき前タイヤは動きますか？',
  INSPECTION_STEP2_FRONT_BREAK_2          : '引きずりなどはありませんか？',
  INSPECTION_STEP2_FRONT_BREAK_3          : 'しっかりかかりますか？',
  INSPECTION_STEP2_REAR_BREAK             : 'リアブレーキ/押して動かすとき前タイヤは動きますか？',
  INSPECTION_STEP2_REAR_BREAK_2           : '引きずりなどはありませんか？',
  INSPECTION_STEP2_REAR_BREAK_3           : 'しっかりかかりますか？',
  INSPECTION_STEP2_ENGINE_CONDITION       : 'エンジンのかかり、クランキング、オイル漏れの状態を詳しく教えてください',
  INSPECTION_STEP2_TIRE_CONDITION         : 'タイヤの状態を詳しく教えてください',
  INSPECTION_STEP2_MODIFICATION           : '自身で改造された部分、購入時に改造されてあった部分わかる範囲で教えてください',
  INSPECTION_STEP2_SCRATCH                : '愛車のきず、へこみ、サビなど痛みのある個所、状態をなるべく詳しく教えてください',
  INSPECTION_STEP2_OTHER_MEMO             : '愛車について、アピールしたいポイントなどあれば自由にご記入ください',
  INSPECTION_STEP2_COMMENT                : '備考・コメント',
  INSPECTION_STEP2_NOTICE                 : '愛車の状態についてご入力ください。わかる箇所はなるべく詳しく、不明箇所は不明で大丈夫ですが、不明だと正しい査定ができないので査定額が低くなる可能性があります。ご注意ください。',
  INSPECTION_STEP2_AGREEMENT              : '売買成立時には必ず名義人様の譲渡証が必要となります',
  INSPECTION_STEP2_DIALOG_OK              : 'OK',
  INSPECTION_STEP2_DIALOG_CANCEL          : 'キャンセル',
  INSPECTION_STEP2_IMAGE_CLEAR_DIALOG_TXT : '画像を削除してもよろしですか？',
  INSPECTION_STEP2_ERROR_DIALOG_TXT       : '入力エラーがあります。赤い枠の箇所をご確認ください。',

  INSPECTION_AUCTION_REQUEST_PREVIEW                        : '依頼詳細',
  INSPECTION_AUCTION_REQUEST_EDIT                           : '依頼編集',
  INSPECTION_AUCTION_REQUEST_PAGE_NAME                      : '出品依頼',
  INSPECTION_AUCTION_REQUEST_FROM_TITLE                     : '出品依頼',
  INSPECTION_AUCTION_REQUEST_POST_CODE_LABEL                : '配送元郵便番号',
  INSPECTION_AUCTION_REQUEST_ADDRESS_LABEL                  : '配送元住所',
  INSPECTION_AUCTION_REQUEST_COMMENT_LABEL                  : '備考・コメント',
  INSPECTION_AUCTION_REQUEST_CATEGORY_LABEL                 : '出品種類の選択',
  INSPECTION_AUCTION_REQUEST_CATEGORY_AGENCY_BUTTON_LABEL   : '出品代行',
  INSPECTION_AUCTION_REQUEST_CATEGORY_AGENCY_BUTTON_2_LABEL : '管理をプロに任せて簡単出品！',
  INSPECTION_AUCTION_REQUEST_CATEGORY_MYSELF_BUTTON_LABEL   : '自分で出品',
  INSPECTION_AUCTION_REQUEST_CATEGORY_MYSELF_BUTTON_2_LABEL : '自分でオークションに出品',
  INSPECTION_AUCTION_REQUEST_LINK_LABEL                     : '代行オプションの詳細はこちら',
  INSPECTION_AUCTION_DETAIL_FROM_TITLE                      : '査定依頼時情報',
  INSPECTION_AUCTION_REQUEST_AGENCY_BUTTON_LABEL            : '出品代行依頼',
  INSPECTION_AUCTION_REQUEST_BUTTON_LABEL                   : 'オークション出品依頼',
  INSPECTION_AUCTION_REQUEST_TOP_BTN_LABEL                  : 'トップに戻る',

  INSPECTION_AUCTION_POST_CODE_REQUIRED_ERROR_MESSAGE : '配送元郵便番号を入力してください。',
  INSPECTION_AUCTION_ADDRESS_REQUIRED_ERROR_MESSAGE   : '配送元住所を入力してください。',

  INSPECTION_AUCTION_REQUEST_SAVE_SUCCESS_TXT               : '一時保存が完了しました。',
  INSPECTION_AUCTION_REQUEST_SAVE_SUCCESS_CLOSE             : '閉じる',
  INSPECTION_AUCTION_REQUEST_CANCEL_TXT                     : '入力内容は破棄されますがよろしいですか？',
  INSPECTION_AUCTION_REQUEST_CANCEL_OK_SAVE                 : '一時保存データを残して戻る',
  INSPECTION_AUCTION_REQUEST_CANCEL_OK_CLEAR                : '一時保存データを削除して戻る',
  INSPECTION_AUCTION_REQUEST_CANCEL_CLOSE                   : 'キャンセル',
  INSPECTION_AUCTION_CANCEL_REQUEST_TEXT                    : '出品を取り消してもよろしいですか？',
  INSPECTION_AUCTION_CANCEL_REQUEST_2_TEXT                  : '※出品手数料の払い戻しはいたしません',

  INSPECTION_RESULT_FROM_TITLE        : '査定依頼結果',
  INSPECTION_RESULT_JUDGE_STATUS      : '依頼結果',
  INSPECTION_RESULT_ASSESSMENT_AMOUNT : '査定額',
  INSPECTION_RESULT_COMMENT           : 'コメント',

  REQUEST_RESULT_FROM_TITLE : '依頼結果',
  REQUEST_DETAIL_FROM_TITLE : '依頼時情報',

  INSPECTION_AUCTION_POPUP_TITLE                         : '直近開催オークション',
  INSPECTION_AUCTION_POPUP_PREVIEW_START_DATETIME_FIELD  : '下見開始',
  INSPECTION_AUCTION_POPUP_BID_DATETIME_FIELD            : '入札期間',
  INSPECTION_AUCTION_POPUP_EXHIBITION_NAME_FIELD         : 'オークション名',
  INSPECTION_AUCTION_POPUP_EXHIBITION_ITEM_COUNT_FIELD   : '出品台数',
  INSPECTION_AUCTION_POPUP_MAX_EXHIBITION_DATETIME_FIELD : '延長有無',
  INSPECTION_AUCTION_POPUP_CONTENT_FIELD                 : '操作',
  INSPECTION_AUCTION_POPUP_BUTTON                        : '出品依頼',
  INSPECTION_AUCTION_EXHIBITION_DIVISION                 : '出品区分',
  INSPECTION_AUCTION_COMMENT                             : '備考・コメント',
  INSPECTION_AUCTION_POPUP_PREVIEW_DATETIME_NOT_SET      : 'なし',

  INSPECTION_REQUEST_CONFIRM_EXPLANATION_AGENCY_MESSAGE  : '審査の結果、承認された場合は出品代行手数料が#(税込)発生します。',
  INSPECTION_REQUEST_CONFIRM_EXPLANATION_AUCTION_MESSAGE : '審査の結果、承認された場合は出品手数料が#(税込)発生します。',
  INSPECTION_REQUEST_CONFIRM_EXPLANATION_MESSAGE         : 'よろしいですか？',
  INSPECTION_REQUEST_COMFIRM_DIALOG_OK_LABEL             : '送信',
  INSPECTION_REQUEST_FORM_COMPLETE_TEXT                  : '出品依頼を送信しました。',

  INSPECTION_PURCHASE_REQUEST_PAGE_NAME                   : '買取依頼',
  INSPECTION_PURCHASE_REQUEST_FROM_TITLE                  : '買取依頼',
  INSPECTION_PURCHASE_REQUEST_BUTTON_LABEL                : '買取依頼',
  INSPECTION_PURCHASE_REQUEST_DIVISION                    : '依頼区分',
  INSPECTION_REQUEST_CONFIRM_EXPLANATION_PURCHASE_MESSAGE : '買取依頼を行います。',
  INSPECTION_PURCHASE_REQUEST_FORM_COMPLETE_TEXT          : '買取依頼を送信しました。',

  INSPECTION_CHAT_HEADER_REQUEST_NO_LABEL : '申請番号',
  INSPECTION_CHAT_HEADER_MAKER_LABEL      : 'メーカー',
  INSPECTION_CHAT_HEADER_BIKE_TYPE_LABEL  : '車種',
  INSPECTION_CHAT_HEADER_DELIVERY_LABEL   : '配送方法',
  INSPECTION_CHAT_ADMIN_NAME_LABEL        : 'ライマ',
  INSPECTION_CHAT_POST_BUTTON_LABEL       : '投稿',
  INSPECTION_CHAT_CLEAR_BUTTON_LABEL      : 'クリア',

  CHAT_REQUEST_PAGE_NAME  : '依頼チャット',
  CHAT_REQUEST_FROM_TITLE : '依頼チャット',
  CHAT_INQUIRY_PAGE_NAME  : '問い合わせチャット',
  CHAT_INQUIRY_FROM_TITLE : '問い合わせチャット',
  CHAT_BID_PAGE_NAME      : '取引チャット',
  CHAT_BID_FROM_TITLE     : '取引チャット',

  CHAT_MESSAGE_REQUIRED_ERROR_MESSAGE : 'メッセージを入力してください。',
  CHAT_MESSAGE_LOGIN_ERROR_MESSAGE    : '未ログインの場合は質問できません。',
  CHAT_MESSAGE_AGENCY_ERROR_MESSAGE   : '配送代行の場合はコメントできません。',

  REPORT_COMFIRM_DIALOG_OK_LABEL : '通報する',

  MY_PAGE_SUCCESSFUL_BID_HISTORY_SEARCH_CONDITION_VIEW_HEADER : '落札履歴　検索条件',
  MY_PAGE_SEARCH_CONDITION_METHOD_LABEL                       : '方式',
  MY_PAGE_SEARCH_CONDITION_BIDDING_DATE_LABEL                 : 'オークション終了日',
  MY_PAGE_SEARCH_CONDITION_BIDDING_FROM_DATE_PLACEHOLDER      : '例)2021/01/01',
  MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER        : '例)2021/01/01',
  MY_PAGE_SEARCH_BUTTON_LABEL                                 : '検索',
  MY_PAGE_SEARCH_CONDITION_LABEL                              : '条件',
  MY_PAGE_SEARCH_RESULT_LABEL                                 : '検索結果',
  MY_PAGE_SEARCH_RESULT_COUNT_LABEL                           : '{count}件',
  MY_PAGE_SEARCH_RESULT_DISPLAY_COUNT_LABEL                   : '表示件数',
  MY_PAGE_SEARCH_RESULT_SORT_DISPLAY_COUNT_LABEL              : '{display_count}件',
  MY_PAGE_ITEM_BIDDING_END_DATETIME_LABEL                     : 'オークション終了日時',
  MY_PAGE_COLON_LABEL                                         : ':',
  MY_PAGE_ITEM_MAKER_LABEL                                    : 'メーカー',
  MY_PAGE_ITEM_MODEL_LABEL                                    : 'モデル',
  MY_PAGE_ITEM_YEAR_LABEL                                     : '年式',
  MY_PAGE_ITEM_OPERATING_TIME_LABEL                           : '総走行距離',
  MY_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                      : '号機',
  MY_PAGE_ITEM_CONTRACT_PRICE_LABEL                           : '落札価格',
  MY_PAGE_ITEM_CONTRACT_PRICE_TAX_LABEL                       : '落札価格(税込)',
  MY_PAGE_ITEM_BID_PRICE_LABEL                                : '入札価格',
  MY_PAGE_ITEM_CONTRACT_BIDDER_LABEL                          : '最終入札者',
  MY_PAGE_ITEM_METHOD_SHIP_LABEL                              : '配送方法',
  MY_PAGE_ITEM_METHOD_SHIP_UN_SELECT_LABEL                    : '未選択',
  MY_PAGE_ITEM_METHOD_SHIP_REQUEST_LABEL                      : '配送代行(申請中)',
  MY_PAGE_ITEM_METHOD_SHIP_DELIVERY_AGENCY_LABEL              : '配送代行',
  MY_PAGE_ITEM_METHOD_SHIP_DELIVERY_MYSELF_LABEL              : '自分で配送',
  MY_PAGE_ITEM_SELECT_METHOD_SHIP_BUTTON_LABEL                : '配送方法選択',
  MY_PAGE_ITEM_REQUEST_CHAT_BUTTON_LABEL                      : '依頼チャット',
  MY_PAGE_ITEM_BID_CHAT_BUTTON_LABEL                          : '取引チャット',
  MY_PAGE_ITEM_PRODUCT_IMAGE_DOWNLOAD_BUTTON_LABEL            : '商品画像ダウンロード',
  MY_PAGE_SHOW_MORE_BUTTON_LABEL                              : 'もっと見る',
  MY_PAGE_PRICE_CURRENCY_MARK                                 : '￥',
  MY_PAGE_YEAR_MARK                                           : '',
  MY_PAGE_NO_MARK                                             : 'No.',
  MY_PAGE_SHOW_ITEMS_LINK_LABEL                               : 'ロットの内訳',
  MY_PAGE_HIDE_ITEMS_LINK_LABEL                               : '内訳を閉じる',
  MY_PAGE_UPDATE_FILE_SIZE_LIMIT_ERROR_MESSAGE                : '5MB以内のファイルをアップロードしてください。',
  MY_PAGE_HEADER_BID_HISTORY_TEXT                             : '落札履歴',
  MY_PAGE_HEADER_CHANGE_INFO_TEXT                             : '会員情報編集',
  MY_PAGE_HEADER_CARD_INFO_TEXT                               : '支払方法登録',
  MY_PAGE_HEADER_INSPECTION_INFO_TEXT                         : '買取査定・出品履歴へ',
  MY_PAGE_UPDATE_FILE_EXT_LIMIT_ERROR_MESSAGE                 : '画像ファイルを選択してください。',

  MY_PAGE_DELIVERY_METHOD_DIALOG_HEADER_TEXT                  : '配送方法を選択してください',
  MY_PAGE_DELIVERY_METHOD_DIALOG_HEADER_2_TEXT                : '[確定後は変更できません]',
  MY_PAGE_DELIVERY_METHOD_DIALOG_DELIVERY_AGENCY_BUTTON_LABEL : '配送代行を依頼する',
  MY_PAGE_DELIVERY_METHOD_DIALOG_DELIVERY_MYSELF_BUTTON_LABEL : '自分で手配する',
  MY_PAGE_DELIVERY_METHOD_DIALOG_OK_LABEL                     : '確定',
  MY_PAGE_DELIVERY_METHOD_DIALOG_CANCLE_LABEL                 : '閉じる',
  MY_PAGE_DELIVERY_METHOD_DIALOG_BACK_LABEL                   : '戻る',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_HEADER_TEXT          : '確定後のキャンセルはできません。',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_HEADER_2_TEXT        : 'で確定してよろしいですか？',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGENCY_LABEL         : '配送代行',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_MYSELF_LABEL         : '自分で手配',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_TITLE_LABEL          : 'ご依頼内容',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_SHIP_ADDRESS_LABEL   : '配送先住所',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_TITLE_TEXT1    : '入力内容を送信いただく前に「',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_TITLE_TEXT2    : '」をご確認ください。',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_LABEL          : '配送について',
  MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_TEXT           : '同意する',
  MY_PAGE_DELIVERY_METHOD_COMPLETION_DIALOG_HEADER_TEXT       : '配送方法の選択が完了しました。',

  MY_PAGE_DELIVERY_METHOD_REQUIRED_ERROR_MESSAGE              : '配送方法を選択してください。',
  MY_PAGE_DELIVERY_METHOD_TITLE_REQUIRED_ERROR_MESSAGE        : 'タイトルを入力してください。',
  MY_PAGE_DELIVERY_METHOD_SHIP_ADDRESS_REQUIRED_ERROR_MESSAGE : '配送先住所を入力してください。',

  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_HEADER_TEXT      : '確認',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_MESSAGE_TEXT     : '退会してもよろしいでしょうか',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_OK_LABEL         : '確認',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL     : '閉じる',

  OTHER_DELIVERY_TITLE                                    : '配送について',
  OTHER_DELIVERY_CENTER_TEXT                              : '配送についての注意事項および規定',
  OTHER_DELIVERY_MAIN_1_TITLE                             : '書類と付属品のお預かり',
  OTHER_DELIVERY_MAIN_1_TEXT_1                            : '輸送車輌本体に関する書類または付属品を、輸送車輌本体と共にサービスでお預かり致します。付属品につきましては、40㎝×40㎝×40㎝程度の箱1個まで、マフラーの場合はサイレンサー部分（120サイズ内）のみお預かり致します。マフラー一式、タンク、シート、カウル、タイヤ/ホイールなどはお預かりできません。パーツ・付属品は、荷台に積載しての輸送となります。書類に関しましてはドライバーに手渡しの場合のみ、お預かりいたします。車体やパーツ・付属品内に書類を入れられた場合は確認ができないため、お預かりしたことにはなりません。',
  OTHER_DELIVERY_MAIN_1_TEXT_2                            : '輸送車両本体以外の付属品のお預かりにつきましては、輸送依頼の際にご相談ください。（事前のお申し出がない場合、付属品をお預かり致しかねることがあります。）',
  OTHER_DELIVERY_MAIN_1_TEXT_3                            : 'お預かりした付属品を保護するための、梱包等の処置につきましては、弊社はその一切を講ずることができません。',
  OTHER_DELIVERY_MAIN_1_TEXT_4                            : '弊社は、お預かりした輸送車輌本体以外の付属品（ナビ、ステレオ等含む）について、滅失、損傷等の損害に対し、原状復帰を含め一切の損害賠償責任を負わないものとします。',
  OTHER_DELIVERY_MAIN_1_TEXT_5                            : 'パーツ、付属品等をバイク本体に積載しての輸送はできません。シートや荷台にくくり付けするなどはお控えください。万が一、車体にパーツのくくり付けが発覚して場合は追加料金として￥10,000をご請求させて頂きます。また、その際のパーツの紛失・破損等が発生しても補償できません。',
  OTHER_DELIVERY_MAIN_1_TEXT_6                            : 'お預かりしたパーツや付属品に関しての雨漏れや破損の際の補償は致しかねますので、ご自身でしっかりとした養生等をお願いいたします。',
  OTHER_DELIVERY_MAIN_1_TEXT_7                            : '北海道や沖縄、離島などの輸送には書類やパーツ・付属品のお預かりはできません。ご了承ください。',
  OTHER_DELIVERY_MAIN_2_TITLE                             : '北海道・沖縄・離島への輸送',
  OTHER_DELIVERY_MAIN_2_TEXT_1                            : '※北海道・沖縄・離島への輸送はフェリーを使用するため、天候等による影響で輸送日程予定が予告無しで変更される場合がございます。ご了承ください。',
  OTHER_DELIVERY_MAIN_2_TEXT_2                            : '・沖縄離島について最上段「出発港までの陸送を経て、船舶での輸送になります。船舶への積載の際に、輸送車輌のエンジンを始動させ走行する都合上、必ず本体の鍵を付けエンジンがかかる状態でお渡しください。尚、不動車輸送のご相談は、海運業者と協議のうえ検討致します。オイル漏れや不具合のある車輌は、船舶への積載は不可となります。',
  OTHER_DELIVERY_MAIN_2_TEXT_3                            : '・沖縄・離島への輸送は、港止めになります。(下記参照)',
  OTHER_DELIVERY_MAIN_2_TEXT_4                            : '1） 沖縄＝那覇新港',
  OTHER_DELIVERY_MAIN_2_TEXT_5                            : '2） 大島＝波浮港',
  OTHER_DELIVERY_MAIN_2_TEXT_6                            : '3） 種子島＝西之表港',
  OTHER_DELIVERY_MAIN_2_TEXT_7                            : '4） 奄美大島＝名瀬港など',
  OTHER_DELIVERY_MAIN_2_TEXT_8                            : '・書類・付属品のお預かり、及び輸送料金の元払いや着払い、集金代行等のサービスはご利用いただけません。',
  OTHER_DELIVERY_MAIN_2_TEXT_9                            : '・船舶輸送につきまして、燃料サーチャージ等により輸送料金が変動する場合がございます。別途お問い合わせください',
  OTHER_DELIVERY_MAIN_3_TITLE                             : '沖縄を除く九州・山口エリアの配送',
  OTHER_DELIVERY_MAIN_3_TEXT_1                            : '沖縄を除く九州・山口エリアの配送において、今現在一部重量車やサイズの大きい車両の発送が厳しい場合があり配送をお断りさせて頂く場合がございます。詳しくはお問い合わせください。',
  OTHER_DELIVERY_MAIN_3_TEXT_2                            : '該当車両例',
  OTHER_DELIVERY_MAIN_3_TEXT_3                            : '・Harley-Davidson',
  OTHER_DELIVERY_MAIN_3_TEXT_4                            : 'FLHTCU（ウルトラ）',
  OTHER_DELIVERY_MAIN_3_TEXT_5                            : '・HONDA',
  OTHER_DELIVERY_MAIN_3_TEXT_6                            : 'GL1500/1800（ゴールドウィング）',
  OTHER_DELIVERY_MAIN_3_TEXT_7                            : '・YAMAHA',
  OTHER_DELIVERY_MAIN_3_TEXT_8                            : 'ベンチャーロイヤル',
  OTHER_DELIVERY_MAIN_3_TEXT_9                            : '・SUZUKI',
  OTHER_DELIVERY_MAIN_3_TEXT_10                           : 'GV1400 カバルケード',
  OTHER_DELIVERY_MAIN_3_TEXT_11                           : '・KAWASAKI',
  OTHER_DELIVERY_MAIN_3_TEXT_12                           : 'Voyager（ボイジャー）',
  OTHER_DELIVERY_MAIN_3_TEXT_13                           : 'サイドカー、トラック、バギーなどの特殊車両',
  OTHER_DELIVERY_MAIN_3_TEXT_14                           : 'その他350kgを超える重量車両になります。',
  OTHER_DELIVERY_MAIN_3_TEXT_15                           : '※ トップケース・パニヤケース・キャリアに付けたボックス・純正サイズと異なる部品（マフラー・ハンドル・カウル等）がある場合は、配送料金が1個につきプラス5,000円（税別）となりますのでご注意ください。',
  OTHER_DELIVERY_MAIN_3_TEXT_16                           : '上記付属品を外して車体のみを発送の場合は通常料金となります。',
  OTHER_DELIVERY_MAIN_3_TEXT_17                           : 'また、ケース・ボックス内にお荷物が入った状態でのお預かりはスタンド破損（折れ・曲がり）車両転倒等、安全な輸送が出来ないため一切お受けすることは出来ません。',
  OTHER_DELIVERY_MAIN_4_TITLE                             : 'その他発着地問わず要問い合わせ車輛',
  OTHER_DELIVERY_MAIN_4_TEXT_1                            : 'HONDA:',
  OTHER_DELIVERY_MAIN_4_TEXT_2                            : 'GL1500・GL1800・ワルキューレ・F61・F6C・VT1300/1800シリーズ・DN-01・NM4・パニアケース&サイドバッグ&トップケース装着車',
  OTHER_DELIVERY_MAIN_4_TEXT_3                            : 'YAMAHA:',
  OTHER_DELIVERY_MAIN_4_TEXT_4                            : 'ベンチャーロイヤル・ロイヤルスター・FJR1300（パニアケース装着車）・XV1600/1900シリーズ・パニアケース&サイドバッグ&トップケース装着車',
  OTHER_DELIVERY_MAIN_4_TEXT_5                            : 'SUZUKI:',
  OTHER_DELIVERY_MAIN_4_TEXT_6                            : 'GV1400ガバルケード・ブルーバードM109R・イントルーダー1500LC・パニアケース&サイドバッグ&トップケース装着車・スカイウェーブ650',
  OTHER_DELIVERY_MAIN_4_TEXT_7                            : 'KAWASAKI:',
  OTHER_DELIVERY_MAIN_4_TEXT_8                            : 'ボイジャー・1400GTR（パニアケース装着車）・バルカン1500/1700/2000・パニアケース&サイドバッグ&トップケース装着車',
  OTHER_DELIVERY_MAIN_4_TEXT_9                            : 'BMW:',
  OTHER_DELIVERY_MAIN_4_TEXT_10                           : 'パニアケース&サイドバッグ&トップケース装着全車輛',
  OTHER_DELIVERY_MAIN_4_TEXT_11                           : 'Harley:',
  OTHER_DELIVERY_MAIN_4_TEXT_12                           : 'FX・FL系シリーズ・FLカウルシリーズ・パニアケース&サイドバッグ&トップケース装着車・FXCWC',
  OTHER_DELIVERY_MAIN_4_TEXT_13                           : 'TRIUMPH:',
  OTHER_DELIVERY_MAIN_4_TEXT_14                           : 'ROCKETⅢ・パニアケース&サイドバッグ&トップケース装着車',
  OTHER_DELIVERY_MAIN_4_TEXT_15                           : 'その他:',
  OTHER_DELIVERY_MAIN_4_TEXT_16                           : '問い合わせ（カスタムペイント車輛・ワンオフパーツ装着車等）',
  OTHER_DELIVERY_MAIN_4_TEXT_17                           : '屋根付き車両、3輪バイク、サイドカー、トライク・バギー、ロングフォーク',
  OTHER_DELIVERY_MAIN_4_TEXT_18                           : 'ノーマル車よりサイズ（全長・全幅・全高）の変更がある車輛',
  OTHER_DELIVERY_MAIN_4_TEXT_19                           : '※下記車両はノーマルの場合の区分です。改造等されている場合は料金は異なるのでお問い合わせ下さい。',
  OTHER_DELIVERY_MAIN_4_TEXT_20                           : '・ジャイロX、ジャイロアップ・・・中型車',
  OTHER_DELIVERY_MAIN_4_TEXT_21                           : '・トリシティ125/155・・・中型車',
  OTHER_DELIVERY_MAIN_4_TEXT_22                           : '・トリシティ300・・・大型アメリカン・ビックスクーターモデル',
  OTHER_DELIVERY_MAIN_4_TEXT_23                           : '・ピアジオMP3・・・大型車',
  OTHER_DELIVERY_MAIN_4_TEXT_24                           : '・ジャイロキャノピー・・・大型車',
  OTHER_DELIVERY_MAIN_4_TEXT_25                           : '・サイズの大きい125cc外車スクーター・・・中型車',
  OTHER_DELIVERY_MAIN_4_TEXT_26                           : '例）SYM RV125シリーズ/JOYMAX（GTS）125',
  OTHER_DELIVERY_MAIN_4_TEXT_27                           : 'KYMCO ダウンタウン125/G-DINK125/K-XCT125',
  OTHER_DELIVERY_MAIN_4_TEXT_28                           : 'DAELIM Q2/デイスター125',
  OTHER_DELIVERY_MAIN_4_TEXT_29                           : 'HYOSNUG GV125',
  OTHER_DELIVERY_MAIN_5_TITLE                             : '車両の保証について',
  OTHER_DELIVERY_MAIN_5_TEXT_1                            : '弊社は皆様からお預かりした車両に関して細心の注意を持って取り扱っておりますが万が一、弊社で車両を傷つけてしまった場合は原状回復を原則として補償させて頂きます。ただし、弊社は基本的にエンジンを掛けない輸送に取り組んでおり、雨天時も輸送いたしますので以下の部分に関しては補償対象外となります。',
  OTHER_DELIVERY_MAIN_5_TEXT_2                            : '・バッテリー',
  OTHER_DELIVERY_MAIN_5_TEXT_3                            : '・電装部品',
  OTHER_DELIVERY_MAIN_5_TEXT_4                            : '・ほこりや泥などの汚れ',
  OTHER_DELIVERY_MAIN_5_TEXT_5                            : 'また、経年劣化による腐食やゴム部品のひび割れ、タイヤのパンクなども基本的には補償いたしかねますのでご了承ください。',
  OTHER_DELIVERY_MAIN_6_TITLE                             : '配送のキャンセル',
  OTHER_DELIVERY_MAIN_6_TEXT_1                            : 'ライマ配送手配を選択したあとのキャンセルは原則できません。',
  OTHER_DELIVERY_MAIN_7_TITLE                             : '物品の引受け',
  OTHER_DELIVERY_MAIN_7_TEXT_1                            : '荷送人は、配送業者が物品を引き取るまでに、物品内の金銭、有価証券、車検証等の書類全 般、宝石、絵画、カセットテープ・CD・DVD・ブルーレイディスク、書籍及び固定されていない 機器（取り外し可能なカーナビゲーションシステム等）等経済的価値を持つものを撤去するも のとし、荷送人が撤去しなかった場合には、配送業者はその滅失毀損等の責任を負いません。',
  OTHER_DELIVERY_MAIN_7_TEXT_2                            : '配送業者は物品を引き取るにあたり、マニュアルに基づいた視認による確認点検を行 い、その結果を伝票に記録します。荷送人は、悪天候の場合には視認が困難であること等を理 解した上、確認点検の結果について、一切異議を述べないものとします。',
  OTHER_DELIVERY_MAIN_7_TEXT_3                            : '前項の確認点検の結果、物品の種類が申込内容と異なることが判明 した場合、輸送料を変更でき、変更後の輸送料が適応されます。',
  OTHER_DELIVERY_MAIN_7_TEXT_4                            : '荷送人は、配送業者の指定する日時に、出発地において、物品を引き渡しをします。',
  OTHER_DELIVERY_MAIN_7_TEXT_5                            : '前項の日時に荷送人の都合により物品が引き渡されない場合、輸送契約は解除されたものとします。',
  OTHER_DELIVERY_MAIN_7_TEXT_6                            : '前項により輸送契約が解除された場合、荷送人は、受け取りのために要する費用として 予め定める料金（引き上げ料）を支払わなければなりません。',
  OTHER_DELIVERY_MAIN_8_TITLE                             : '次の各号の一に該当する場合、輸送の承諾を取り消すことができる',
  OTHER_DELIVERY_MAIN_8_TEXT_1                            : '1） 物品の形状等が申込内容と異なる場合',
  OTHER_DELIVERY_MAIN_8_TEXT_2                            : '2） 物品に液類の漏れが認められ、または、漏れるおそれが認められる場合',
  OTHER_DELIVERY_MAIN_8_TEXT_3                            : '3） 作業員1名で容易に荷扱いが不可能もしくは危険及び移動できない場合',
  OTHER_DELIVERY_MAIN_8_TEXT_4                            : '4） 転倒防止措置（以下「スタンド」という。）が不完全な場合',
  OTHER_DELIVERY_MAIN_8_TEXT_5                            : '5） 物品が固縛や輸送に耐えうる強度がない等、もしくはその他の事由により輸送品質の保証 ができないと判断した場合',
  OTHER_DELIVERY_MAIN_8_TEXT_6                            : '6） 荷送人もしくは荷受人が、暴力団、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、極 左右翼団体、特殊知能暴力集団等、もしくはこれに準ずる団体やその構成員または準構成 員（以下総称して「反社会的勢力」という。）であると疑われる場合、物品が犯罪の被害品も しくは法禁物であると疑われる場合、または、物品輸送に反社会的勢力が関与していると疑 われる場合',
  OTHER_DELIVERY_MAIN_8_TEXT_7                            : '7） 民法第90条に規定する、公序良俗に違反する目的をもって輸送契約が締結されたと 配送業者が判断した場合',
  OTHER_DELIVERY_MAIN_8_TEXT_8                            : '8） 物品に、消防法上の危険物、高圧ガス保安法上の高圧ガス、火薬類取締法上の火薬類、毒物及び劇物取締法上の毒物・劇物、放射性同位元素等による放射線障害の防止に関する法 律上の放射性同位元素、等々の配送業者がその職能において輸送可能とする範囲外である物 質が含まれると疑われる場合',
  OTHER_DELIVERY_MAIN_8_TEXT_9                            : '9） 荷送人が未成年であり、法定代理人の同意が認められない場合',
  OTHER_DELIVERY_MAIN_8_TEXT_10                           : '10） 配送業者において、損害賠償責任を負いかねる場合',
  OTHER_DELIVERY_MAIN_8_TEXT_11                           : '11） その他、配送業者において、輸送を引き受けることが不相当であると認める場合',
  OTHER_DELIVERY_MAIN_9_TITLE                             : '物品の点検',
  OTHER_DELIVERY_MAIN_9_TEXT_1                            : '1）配送業者が必要と認める場合、荷送人の承諾お よび立会いなく、物品を点検することができる。 また配送業者は、必要に応じて荷送人に立会いを求め、荷送人が立会いを拒んだ場 合、輸送契約を解除することができる。',
  OTHER_DELIVERY_MAIN_9_TEXT_2                            : '2）配送業者は、前項の点検において、必要に応じ、当該物品の付属書類等を閲覧し謄写すること ができる。',
  OTHER_DELIVERY_MAIN_10_TITLE                            : '荷受人の本人確認',
  OTHER_DELIVERY_MAIN_10_TEXT_1                           : '1）荷受人は、物品を受け取る際、配送業者に対し、荷受人本人であることを示す運転免許証等を提 示しなければならない。配送業者は本人確認ができない場合、物品を引き渡さない。',
  OTHER_DELIVERY_MAIN_10_TEXT_2                           : '2）配送業者が本規定により物品を引き渡すことができなかった日から 20 営業日を経過した場合、 配送業者は、物品の保管場所を変更することができる。',
  OTHER_DELIVERY_MAIN_10_TEXT_3                           : '3）配送業者が物品を保管している場合、荷受人および荷送人は、当該保管場所で物品を引き取ら なければならない。',
  OTHER_DELIVERY_MAIN_11_TITLE                            : '再配達',
  OTHER_DELIVERY_MAIN_11_TEXT_1                           : '1）配送業者が物品を保管する場合、荷送人または荷受人の依頼により、配達地に再配達することができる。',
  OTHER_DELIVERY_MAIN_11_TEXT_2                           : '2）前項により再配達する場合、荷送人および荷受人は、配送業者に対し、保管場所から配達地までの 輸送料金を支払わなければならない。',
  OTHER_DELIVERY_MAIN_12_TITLE                            : '物品の処分',
  OTHER_DELIVERY_MAIN_12_TEXT_1                           : '次の各号の一に該当する場合、原則として荷送人に連絡を行い、物品の返送を要請するものとし、荷送人が物品の引き取りに応じないときは、物品を自由に処分することができる。',
  OTHER_DELIVERY_MAIN_12_TEXT_2                           : '1）荷受人に対し、物品の受け取りを求めたのち、2カ月を経過した場合。',
  OTHER_DELIVERY_MAIN_12_TEXT_3                           : '2）物品の引渡しを拒否後、2カ月を経過しても本人確認ができ ない場合。',
  OTHER_DELIVERY_MAIN_12_TEXT_4                           : '3）物品の引渡し準備が完了したのち、2カ月を経過するまで荷受人と連絡が取れない場合。その連絡方法については、輸送申込書に記載された荷受人の連絡先に架電または電子メ ールでの連絡をすれば足りるものとする。',
  OTHER_DELIVERY_MAIN_12_TEXT_5                           : '前項により物品が返送された場合、荷送人は、配送業者に対し、配達地から出発地または荷送人 住所地までの輸送料金を、別途支払わなければならない。',
  OTHER_DELIVERY_MAIN_12_TEXT_6                           : '前項の事由で物品が処分された場合、荷送人および荷受人は、配送業者に対し、何ら異議を述べ ず、損害賠償など一切の請求をしない。',
  OTHER_DELIVERY_MAIN_13_TITLE                            : '配送業者の責任',
  OTHER_DELIVERY_MAIN_13_TEXT_1                           : '荷送人または荷受人が 配送業者の責に帰すべき事由により損害が生じたことを証明した場合、 配送業者は、荷送人または荷受人に対し、法律上の損害賠償責任に基づき、滅失の場合は車両代 相当額、毀損の場合は補修費用相当額の損害のみを賠償する。荷送人または荷受人は、配送業者が代車を提供せず、いかなる名目においても代車代金相当額を負担しないことを予め承諾する。',
  OTHER_DELIVERY_MAIN_13_TEXT_2                           : '前項にかかわらず、配送業者は、次の各号につき、責任を負わない。',
  OTHER_DELIVERY_MAIN_13_TEXT_3                           : '1）物品の欠陥や機能上の不具合、製造上の原因による外観品質の欠陥、自然の消耗による経時劣化、虫害または鳥害による損害',
  OTHER_DELIVERY_MAIN_13_TEXT_4                           : '2）物品の性質による発火、爆発、蒸れ、カビ、腐敗、変色、錆その他これに類似する事由によ る損害',
  OTHER_DELIVERY_MAIN_13_TEXT_5                           : '3）輸送中の風、雨、雪および道路などに散布された薬剤等によるなどして生じた錆、汚損等の 損害',
  OTHER_DELIVERY_MAIN_13_TEXT_6                           : '4）改造箇所等の固定方法に関して生じた損害',
  OTHER_DELIVERY_MAIN_13_TEXT_7                           : '5）経年劣化に起因する損傷等および同損傷等',
  OTHER_DELIVERY_MAIN_13_TEXT_8                           : '6）改造箇所に起因する積込時の傷・破損等の損害',
  OTHER_DELIVERY_MAIN_13_TEXT_9                           : '7）二輪車等物品本体以外の付属品（物品に付随して輸送する付属品も含む。）の損傷および 滅失',
  OTHER_DELIVERY_MAIN_13_TEXT_10                          : '8）地震、台風、津波等その他の自然災害および暴動、戦争などによる損傷および喪失',
  OTHER_DELIVERY_MAIN_13_TEXT_11                          : '9）不可抗力に該当する火災による損害',
  OTHER_DELIVERY_MAIN_13_TEXT_12                          : '10）天候、天災、災害等やむを得ない事情による輸送日時の遅延による、時間人件費的損害並 びに営業損害',
  OTHER_DELIVERY_MAIN_13_TEXT_13                          : '11）物品の引取から引渡までにおいて、RL 等のマニュアルに基づいた確認点検で発見が困難 な微細な傷等',
  OTHER_DELIVERY_MAIN_13_TEXT_14                          : '12）前項に基づく補修に起因する物品の引渡の遅延による時間人件費的損害並びに営業損害',
  OTHER_DELIVERY_MAIN_13_TEXT_15                          : '13）輸送中のバッテリー放電、その他消耗品の消耗等による損害、及びそれに起因する引渡し の遅延による時間人件費的損害並びに営業損害前項に関わらず配送業者は、次の各号の事由により生じた損害につき、責任を負わない。',
  OTHER_DELIVERY_MAIN_13_TEXT_16                          : '前項に関わらず配送業者は、次の各号の事由により生じた損害につき、責任を負わない。',
  OTHER_DELIVERY_MAIN_13_TEXT_17                          : '1）同盟罷業もしくは同盟怠業等の争議行為、社会的騒擾、その他の事変または強盗等の犯罪',
  OTHER_DELIVERY_MAIN_13_TEXT_18                          : '2）渋滞や検問等、予見できない異常な交通障害',
  OTHER_DELIVERY_MAIN_13_TEXT_19                          : '3）配送業者の過失なく発生した交通事故による物品の破損または納期遅延による損害その他一切の損害',
  OTHER_DELIVERY_MAIN_13_TEXT_20                          : '4）法令または公権力の発動による運送の差し止め、開封、没収、差押え、または、第三者への引渡し',
  OTHER_DELIVERY_MAIN_13_TEXT_21                          : '5）輸送の中止や変更等、荷送人の申告に基づいて荷送人やその他の者に生じた損害、その他荷送人または荷受人の故意、過失に基づく損害',
  OTHER_DELIVERY_MAIN_13_TEXT_22                          : '6）乗務員の急病等、運行管理衛生上発生した納期遅延による損害',
  OTHER_DELIVERY_MAIN_13_TEXT_23                          : '配送業者の責任は、荷受人が物品を受取ったときに消滅する。',
  OTHER_DELIVERY_MAIN_14_TITLE                            : '損害賠償責任',
  OTHER_DELIVERY_MAIN_14_TEXT_1                           : '配送業者の賠償責任は前項の範囲内とし、荷送人および荷受人は、慰謝料、迷惑料、逸失利益その他一切の賠償を請求しない。',
  OTHER_DELIVERY_MAIN_14_TEXT_2                           : '荷送人または荷受人は、配送業者の求めに応じ、すみやかに損害にかかる事情を説明し、直ちに損傷部位などの写真等を提供する。',
  OTHER_DELIVERY_MAIN_15_TITLE                            : '荷送人の賠償責任',
  OTHER_DELIVERY_MAIN_15_TEXT_1                           : '荷送人は、物品の欠陥もしくは性質または事実と異なる申告により配送業者に損害を与えた場合、 関係者に対し、損害を賠償しなければならない。',
  OTHER_DELIVERY_MAIN_16_TITLE                            : '債権譲渡',
  OTHER_DELIVERY_MAIN_16_TEXT_1                           : '配送業者はこの契約に基づく債権を第三者に譲渡することができる。',
  OTHER_DELIVERY_MAIN_17_TITLE                            : '期限の利益喪失',
  OTHER_DELIVERY_MAIN_17_TEXT_1                           : '荷送人が次の各号の一に該当する場合は、配送業者に対する一切の債務について当然に期限の利益を失い，直ちに債務を弁済しなければならない。',
  OTHER_DELIVERY_MAIN_17_TEXT_2                           : '1）本規約に違反する事実があったとき。',
  OTHER_DELIVERY_MAIN_17_TEXT_3                           : '2）財産状態が悪化し、またはそのおそれがあると認められる相当の理由があったとき。',
  OTHER_DELIVERY_MAIN_17_TEXT_4                           : '3）差押・仮差押・仮処分・公売処分・租税滞納処分その他これに準ずる処分を受け、担保権実行通知を受け、または破産手続開始、再生手続開始、更生手続開始の申立を受け、もしくは自ら申立をしたとき。',
  OTHER_DELIVERY_MAIN_17_TEXT_5                           : '4）営業の廃止若しくは変更または合併若しくは解散の決議をしたとき。',
  OTHER_DELIVERY_MAIN_17_TEXT_6                           : '5）手形交換所による不渡処分を受けたとき。',
  OTHER_DELIVERY_MAIN_17_TEXT_7                           : '6）監督官公庁から営業停止または営業免許若しくは営業登録の取消しの処分を受けたとき。',
  OTHER_DELIVERY_MAIN_17_TEXT_8                           : '7）その他前各号に準ずる信頼関係を破壊する重大な行為があったとき。',
  OTHER_DELIVERY_MAIN_18_TITLE                            : '個人情報の取り扱い',
  OTHER_DELIVERY_MAIN_18_TEXT_1                           : '荷送人、荷受人から知りえたご本人の氏名、住所、電子メールアドレス、生年月日、性別などの個人を識別できる情報は配送業者が物品輸送の履行を目的として使用する。それ以外で使用する場合には本人の同意を得ることとする。',
  OTHER_DELIVERY_MAIN_19_TITLE                            : '裁判管轄',
  OTHER_DELIVERY_MAIN_19_TEXT_1                           : '本規約に基づく契約に関するすべての紛争は、ライダーズマーケットが指定した配送業者の本社所在地を管轄する地方裁判所を専属 的合意管轄裁判所とする。',

  OTHER_COMMISSION_TITLE                                  : '各種手数料',
  OTHER_COMMISSION_SUPPLE_TEXT                            : '+TAXの表示がない金額は全て税込表記です',
  OTHER_COMMISSION_SUPPLE_TEXT__2                         : '※査定額が5万円未満の車両の出品代行はご利用いただけません',
  OTHER_COMMISSION_TABLE_1_TITLE                          : '出品手数料（出品者支払い）',
  OTHER_COMMISSION_TABLE_1_TEXT_1                         : '➀1入札会',
  OTHER_COMMISSION_TABLE_1_TEXT_2                         : '査定額',
  OTHER_COMMISSION_TABLE_1_TEXT_3                         : '成約時',
  OTHER_COMMISSION_TABLE_1_TEXT_4                         : '落札価格',
  OTHER_COMMISSION_TABLE_1_TEXT_5                         : '②',
  OTHER_COMMISSION_TABLE_1_TEXT_6                         : '事務手数料',
  OTHER_COMMISSION_TABLE_1_TEXT_7                         : '～5万未満',
  OTHER_COMMISSION_TABLE_1_TEXT_8                         : '2,200円',
  OTHER_COMMISSION_TABLE_1_TEXT_9                         : '2,200円',
  OTHER_COMMISSION_TABLE_1_TEXT_10                        : '5～15万未満',
  OTHER_COMMISSION_TABLE_1_TEXT_11                        : '3,300円',
  OTHER_COMMISSION_TABLE_1_TEXT_12                        : '5%+TAX',
  OTHER_COMMISSION_TABLE_1_TEXT_13                        : '15～30万未満',
  OTHER_COMMISSION_TABLE_1_TEXT_14                        : '5,500円',
  OTHER_COMMISSION_TABLE_1_TEXT_15                        : '5%+TAX',
  OTHER_COMMISSION_TABLE_1_TEXT_16                        : '30～50万未満',
  OTHER_COMMISSION_TABLE_1_TEXT_17                        : '5,500円',
  OTHER_COMMISSION_TABLE_1_TEXT_18                        : '5%+TAX',
  OTHER_COMMISSION_TABLE_1_TEXT_19                        : '50万以上～',
  OTHER_COMMISSION_TABLE_1_TEXT_20                        : '5,500円',
  OTHER_COMMISSION_TABLE_1_TEXT_21                        : '5%+TAX',
  OTHER_COMMISSION_TABLE_2_TITLE                          : '落札手数料（落札者支払い）',
  OTHER_COMMISSION_TABLE_2_TEXT_1                         : '落札金額',
  OTHER_COMMISSION_TABLE_2_TEXT_2                         : '③1落札',
  OTHER_COMMISSION_TABLE_2_TEXT_3                         : '～5万未満',
  OTHER_COMMISSION_TABLE_2_TEXT_4                         : '2,200円',
  OTHER_COMMISSION_TABLE_2_TEXT_5                         : '5～15万未満',
  OTHER_COMMISSION_TABLE_2_TEXT_6                         : '3,300円',
  OTHER_COMMISSION_TABLE_2_TEXT_7                         : '15～30万未満',
  OTHER_COMMISSION_TABLE_2_TEXT_8                         : '5,500円',
  OTHER_COMMISSION_TABLE_2_TEXT_9                         : '30～50万未満',
  OTHER_COMMISSION_TABLE_2_TEXT_10                        : '5,500円',
  OTHER_COMMISSION_TABLE_2_TEXT_11                        : '50万以上～',
  OTHER_COMMISSION_TABLE_2_TEXT_12                        : '5,500円',
  OTHER_COMMISSION_TABLE_3_TITLE                          : '出品代行（出品者支払い）',
  OTHER_COMMISSION_TABLE_3_TEXT_1                         : 'ライマ査定額',
  OTHER_COMMISSION_TABLE_3_TEXT_2                         : '④30日間お預かり',
  OTHER_COMMISSION_TABLE_3_TEXT_3                         : '成約時',
  OTHER_COMMISSION_TABLE_3_TEXT_4                         : '落札価格',
  OTHER_COMMISSION_TABLE_3_TEXT_5                         : '②',
  OTHER_COMMISSION_TABLE_3_TEXT_6                         : '事務手数料',
  OTHER_COMMISSION_TABLE_3_TEXT_7                         : '5～15万未満',
  OTHER_COMMISSION_TABLE_3_TEXT_8                         : '11,000円',
  OTHER_COMMISSION_TABLE_3_TEXT_9                         : '8%+TAX',
  OTHER_COMMISSION_TABLE_3_TEXT_10                        : '15～30万未満',
  OTHER_COMMISSION_TABLE_3_TEXT_11                        : '11,000円',
  OTHER_COMMISSION_TABLE_3_TEXT_12                        : '8%+TAX',
  OTHER_COMMISSION_TABLE_3_TEXT_13                        : '30～50万未満',
  OTHER_COMMISSION_TABLE_3_TEXT_14                        : '11,000円',
  OTHER_COMMISSION_TABLE_3_TEXT_15                        : '8%+TAX',
  OTHER_COMMISSION_TABLE_3_TEXT_16                        : '50万以上～',
  OTHER_COMMISSION_TABLE_3_TEXT_17                        : '11,000円',
  OTHER_COMMISSION_TABLE_3_TEXT_18                        : '8%+TAX',

  OTHER_TERMS_TITLE                                       : 'ライダーズマーケット利用規約',
  OTHER_TERMS_CENTER_TEXT                                 : '総則',
  OTHER_TERMS_TABLE_1_TITLE                               : '1. 名称',
  OTHER_TERMS_TABLE_1_TEXT_1                              : '株式会社ＵＥＪが運営主催するオンライン査定、買取、オークションその他サービスを行う当サイトをライダーズマーケットと称します。',
  OTHER_TERMS_TABLE_2_TITLE                               : '2. ライダーズマーケットのサービスのご利用',
  OTHER_TERMS_TABLE_2_TEXT_1                              : '本利用規約（以下、「本規約」といいます。）にご同意いただくことによって、当社のサービスをご利用いただくことができます。',
  OTHER_TERMS_TABLE_3_TITLE                               : '3. 目的',
  OTHER_TERMS_TABLE_3_TEXT_1                              : '1） 本規約は，ライダーズマーケット（以下「当社」といいます。）が主催する全てのサービスについて，その参加資格・運営方法等につ いて定めるものです。',
  OTHER_TERMS_TABLE_3_TEXT_2                              : '2） オークションは，本規約と併せて，別途定める「検査規定」，「書類規定」， 「クレーム規定」など個別サービスを円滑に運営するために定める規定（以 下まとめて「諸規定」といいます。）に基づき運営するものとします。',
  OTHER_TERMS_TABLE_4_TITLE                               : '4. サービス内容の保証および変更',
  OTHER_TERMS_TABLE_4_TEXT_1                              : '当社は、提供するサービスの内容について、瑕疵（かし）やバグがないことは保証しておりません。',
  OTHER_TERMS_TABLE_4_TEXT_2                              : 'また、当社は、お客様にあらかじめ通知することなくサービスの内容や仕様を変更したり、提供を停止したり中止したりすることができるものとします。',
  OTHER_TERMS_TABLE_5_TITLE                               : '5. サービスのご利用について',
  OTHER_TERMS_TABLE_5_TEXT_1                              : '1） 「出品者」とは，オークションにおいて車両の出品を行うお客様とします。',
  OTHER_TERMS_TABLE_5_TEXT_2                              : '2） 「落札者」とは，オークションにおいて車両の落札を行うお客様とします。',
  OTHER_TERMS_TABLE_5_TEXT_3                              : '3） 当社は、サービスのご利用をライダーズマーケット会員登録（以下「会員登録」といいます）を登録された方に限定します。また、一定の年齢以上の方に限定したり、当社が定める本人確認などの手続を経て一定の要件を満たしたお客様のみに限定したりするなど、利用に際して条件を付すことができるものとします。',
  OTHER_TERMS_TABLE_5_TEXT_4                              : 'また、当社は反社会的勢力の構成員（過去に構成員であった方を含みます）およびその関係者の方や、サービスを悪用したり、第三者に迷惑をかけたりするようなお客様に対してはご利用をお断りしています。',
  OTHER_TERMS_TABLE_5_TEXT_5                              : '4） オンライン査定、買取サービス、オークションにおける全ての取引は，システムによって処理されるものとなり、お客様はこのシステムによる全ての結果を遵守しなければなりません。',
  OTHER_TERMS_TABLE_6_TITLE                               : '6. 会員登録情報',
  OTHER_TERMS_TABLE_6_TEXT_1                              : '会員登録を行う際は（1）真実かつ正確な情報を登録していただくこと、（2）登録内容が最新となるようお客様ご自身で適宜修正していただくことがお客様の義務となります。(3)ご登録いただけるのはお客様一人につき1つまでです。',
  OTHER_TERMS_TABLE_7_TITLE                               : '7. パスワード等に関するお客様の責任',
  OTHER_TERMS_TABLE_7_TEXT_1                              : 'お客様を特定する所定の認証方法によりログインされた場合には、当社は、当該お客様ご自身によるご利用であるとみなします。サービスの利用や商品の購入などによって料金や代金、また当社が第三者から回収を受託したお客様の債務を含みます。以下「代金」といいます）が発生した場合には、当該お客様に請求いたします。',
  OTHER_TERMS_TABLE_8_TITLE                               : '8. サービス利用にあたっての順守事項',
  OTHER_TERMS_TABLE_8_TEXT_1                              : '当社のサービスのご利用に際しては以下に定める行為（それらを誘発する行為や準備行為も含みます）を禁止いたします。',
  OTHER_TERMS_TABLE_8_TEXT_2                              : '1） 日本国またはご利用の際にお客様が所在する国・地域の法令に違反する行為',
  OTHER_TERMS_TABLE_8_TEXT_3                              : '2） 社会規範・公序良俗に反するものや、他人の権利を侵害し、または他人の迷惑となるようなものを、投稿、掲載、開示、提供または送信（以下これらを総称して「投稿など」といいます）したりする行為',
  OTHER_TERMS_TABLE_8_TEXT_4                              : '3） ほかのお客様の使用するソフトウエア、ハードウエアなどの機能を破壊したり、妨害したりするようなプログラムなどの投稿などをする行為',
  OTHER_TERMS_TABLE_8_TEXT_5                              : '4） 当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為',
  OTHER_TERMS_TABLE_8_TEXT_6                              : '5） 当社のサービス、当社の配信する広告、または、当社のサイト上で提供されているサービス、広告を妨害する行為',
  OTHER_TERMS_TABLE_8_TEXT_7                              : '6） 個人情報などのほかのお客様に関する情報をお客様に無断で収集したり蓄積したりする行為',
  OTHER_TERMS_TABLE_8_TEXT_8                              : '7） サービスを、提供の趣旨に照らして本来のサービス提供の目的とは異なる目的で利用する行為',
  OTHER_TERMS_TABLE_8_TEXT_9                              : '8） ほかのお客様の登録を使用してサービスを利用する行為',
  OTHER_TERMS_TABLE_8_TEXT_10                             : '9） 手段のいかんを問わず他人からパスワードを入手したり、他人にパスワードを開示したり提供したりする行為',
  OTHER_TERMS_TABLE_8_TEXT_11                             : '10） 当社のサービスに関連して、反社会的勢力に直接・間接に利益を提供する行為',
  OTHER_TERMS_TABLE_9_TITLE                               : '9. 当社のサービスなどの再利用の禁止',
  OTHER_TERMS_TABLE_9_TEXT_1                              : 'お客様が、当社のサービスやそれらを構成するデータを、当該サービスの提供目的を超えて利用した場合、当社は、それらの行為を差し止める権利ならびにそれらの行為によってお客様が得た利益相当額を請求する権利を有します。',
  OTHER_TERMS_TABLE_10_TITLE                              : '10. 当社に対する補償',
  OTHER_TERMS_TABLE_10_TEXT_1                             : 'お客様の行為が原因で生じたクレームなどに関連して当社に費用が発生した場合または当社が賠償金などの支払を行った場合、お客様は当社が支払った費用や賠償金など（当社が支払った弁護士費用を含みます）を負担するものとします。',
  OTHER_TERMS_TABLE_11_TITLE                              : '11. お客様のデータおよびコンテンツの取扱い',
  OTHER_TERMS_TABLE_11_TEXT_1                             : 'お客様が当社の管理するサーバーに保存しているデータについて、当社ではバックアップの義務を負わないものとし、お客様ご自身においてバックアップを行っていただくものとします。',
  OTHER_TERMS_TABLE_11_TEXT_2                             : 'なお、当社のサービスの保守や改良などの必要が生じた場合には、当社はお客様が当社の管理するサーバーに保存しているデータを、サービスの保守や改良などに必要な範囲で複製等することができるものとします。',
  OTHER_TERMS_TABLE_12_TITLE                              : '12. 権利の帰属',
  OTHER_TERMS_TABLE_12_TEXT_1                             : 'オークションにおいて提供する車両情報その他のオークション情報に関する著作権は，当社に帰属します。オークション情報がお客様から提供されたものであっても同様とします。',
  OTHER_TERMS_TABLE_12_TEXT_2                             : 'お客様は，オークションに参加する目的で使用する場合を除き，転用，加工 等の方法を問わず，みだりにオークション情報を流用してはいけません。',
  OTHER_TERMS_TABLE_12_TEXT_3                             : 'また、チャットルームなど、不特定または多数のお客様がアクセスできるサービスに対してお客様が投稿などをしたコンテンツについては、お客様または当該コンテンツの著作権者に著作権が帰属します。',
  OTHER_TERMS_TABLE_12_TEXT_4                             : '当該コンテンツについて、お客様は当社に対して、日本の国内外で無償かつ非独占的に利用（複製、上映、公衆送信、展示、頒布、譲渡、貸与、翻訳、翻案、出版を含みます）する権利を期限の定めなく許諾（サブライセンス権を含みます）したものとみなします。',
  OTHER_TERMS_TABLE_12_TEXT_5                             : 'なお、お客様は著作者人格権を行使しないものとします。',
  OTHER_TERMS_TABLE_13_TITLE                              : '13. 広告掲載について',
  OTHER_TERMS_TABLE_13_TEXT_1                             : '当社は、提供するサービスやソフトウエアに当社または当社に掲載依頼をした第三者の広告を掲載することができるものとします。',
  OTHER_TERMS_TABLE_14_TITLE                              : '14. 投稿などの削除、サービスの利用停止、会員登録削除について',
  OTHER_TERMS_TABLE_14_TEXT_1                             : '当社は、提供するサービスを適正に運営するために、以下の場合にはあらかじめ通知することなく、データやコンテンツを削除したり、サービスの全部または一部の利用をお断りしたり、お客様の会員登録を削除したりするといった措置を講じることができるものとします。',
  OTHER_TERMS_TABLE_14_TEXT_2                             : '1） お客様が複数の会員登録をされていることが発覚した場合',
  OTHER_TERMS_TABLE_14_TEXT_3                             : '2） お客様が本利用規約に定められている事項に違反した場合、もしくはそのおそれがあると当社が判断した場合',
  OTHER_TERMS_TABLE_14_TEXT_4                             : '3） 当社にお支払いいただく代金について支払の遅滞が生じた場合',
  OTHER_TERMS_TABLE_14_TEXT_5                             : '4） 指定されたクレジットカードや銀行口座の利用が停止された場合',
  OTHER_TERMS_TABLE_14_TEXT_6                             : '5） お客様が破産もしくは民事再生の手続の申立てを受け、またはお客様自らがそれらの申立てを行うなど、お客様の信用不安が発生したと当社が判断した場合',
  OTHER_TERMS_TABLE_14_TEXT_7                             : '6） 反社会的勢力またはその構成員や関係者によって登録または使用された場合、もしくはそのおそれがあると当社が判断した場合',
  OTHER_TERMS_TABLE_14_TEXT_8                             : '7） その他、お客様との信頼関係が失われた場合など、当社とお客様との契約関係の維持が困難であると当社が判断した場合',
  OTHER_TERMS_TABLE_15_TITLE                              : '15. 免責事項',
  OTHER_TERMS_TABLE_15_TEXT_1                             : '当社の債務不履行責任は、当社の故意または重過失によらない場合には免責されるものとします。',
  OTHER_TERMS_TABLE_15_TEXT_2                             : 'なお、お客様との本規約に基づく当社のサービスのご利用に関する契約が消費者契約法に定める消費者契約に該当する場合、上記の免責は適用されないものとし、当社は、当社の故意・重過失に起因する場合を除き、通常生じうる損害の範囲内で、かつ、有料サービスにおいては代金額（継続的なサービスの場合は1か月分相当額）を上限として損害賠償責任を負うものとします。',
  OTHER_TERMS_TABLE_16_TITLE                              : '16. 個別サービスの諸規定について',
  OTHER_TERMS_TABLE_16_TEXT_1                             : '特定の当社のサービスについては、本規約のほかに、サービス固有の利用条件が設けられている場合があります。',
  OTHER_TERMS_TABLE_16_TEXT_2                             : '本規約に定める規則と諸規定を含むサービス固有の利用条件が異なっている場合には、特段の定めがない限り、当該サービスに関してはそのサービス固有の利用条件を優先して適用されるものとします。',
  OTHER_TERMS_TABLE_17_TITLE                              : '17. 本規約等の改定、変更',
  OTHER_TERMS_TABLE_17_TEXT_1                             : '1） 当社が必要と判断した場合には、本規約を変更することができるものとします。この場合、当社は、当社のウェブサイトへの掲載その他の適切な方法にて、本規約を変更する旨および変更後の本規約の内容ならびにその効力発生日を周知するものとします。',
  OTHER_TERMS_TABLE_17_TEXT_2                             : '2） お客様が改定後の最初のオークション取引に参加した場合，当該取引への参加をもって，改定を承認したものとみなします。',
  OTHER_TERMS_TABLE_18_TITLE                              : '18. 権利義務などの譲渡の禁止',
  OTHER_TERMS_TABLE_18_TEXT_1                             : 'お客様は、本利用規約に基づくすべての契約について、その契約上の地位およびこれにより生じる権利義務の全部または一部を、当社の書面による事前の承諾なく第三者に譲渡することはできません。',
  OTHER_TERMS_TABLE_19_TITLE                              : '19. 準拠法、裁判管轄',
  OTHER_TERMS_TABLE_19_TEXT_1                             : '本利用規約の成立、効力発生、解釈にあたっては日本法を準拠法とします。',
  OTHER_TERMS_TABLE_19_TEXT_2                             : 'また、当社のサービス（掲載内容や広告などを含む）、ソフトウエアに起因または関連して当社とお客様との間で生じた紛争については福岡地方裁判所小倉支部を第一審の専属的合意管轄裁判所とします。',
  OTHER_TERMS_TABLE_20_TITLE                              : '20. 利用規約の適用制限について',
  OTHER_TERMS_TABLE_20_TEXT_1                             : '本利用規約の規定がお客様との本利用規約に基づく契約に適用される関連法令に反するとされる場合、当該規定は、その限りにおいて、当該お客様との契約には適用されないものとします。',
  OTHER_TERMS_TABLE_20_TEXT_2                             : 'ただし、この場合でも、本利用規約のほかの規定の効力には影響しないものとします。',
  OTHER_TERMS_TABLE_21_TITLE                              : '付則',
  OTHER_TERMS_TABLE_22_TITLE                              : '施行',
  OTHER_TERMS_TABLE_23_TITLE                              : '2023年11月1日施行',

  OTHERS_ORDER_RULE_TEXT                                  : '取引条件',
  OTHERS_ORDER_INTRO_RULE_TEXT                            : '弊社製品のご購入を希望されるお客様は、次の点にご同意いただいたうえでお取引いただくこととなります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_TEXT                     : '安全保障貿易管理に関して',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_DESCRIPTION_TEXT         : '弊社は、大量破壊兵器等の拡散防止、通常兵器の過度の蓄積防止、国際的な平和及び安全の維持の観点から、安全保障貿易管理を実施しています。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_TEXT               : '輸出管理方針',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_1_TEXT : '「外国為替及び外国貿易法」等の輸出関連法規（以下、「外為法等」といいます。）及び米国製品の再輸出に関する法令を遵守し、これらに違反して貨物の輸出もしくは技術の提供をいたしません。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_2_TEXT : '該非判定、顧客審査及びこれらを総合的に勘案した取引審査についての手続きを明確に規定し確実に実施致します。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_1_DESCRIPTION_3_TEXT : '取引に際しては、貨物・技術の用途および最終顧客の確認を実施し、大量破壊兵器および武器などの通常兵器関連の拡散防止に努めます。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_TEXT               : 'お取引内容の確認',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_1_TEXT : '上記A)の方針に従い、お客様とのお取引内容を確認し、取引審査を実施させていただく場合があります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_2_TEXT : '審査結果により、弊社指定条件を付してのお取引もしくはお取引そのものを控えさ せていただく場合があります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_2_DESCRIPTION_3_TEXT : '審査状況により、お引渡し期日が遅れる場合がありますので、あらかじめご了承ください。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_TEXT               : '「輸出管理に関する留意事項」へのご署名',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_1_TEXT : '上記B)の実施と共に、弊社が発行する「輸出管理に関する留意事項」にご署名していただく事がお取引条件となっております。書面は必要に応じて弊社から郵送させていただきます。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_2_TEXT : 'また、当該書面は、外為法等の改正、弊社との取引実績等、総合的に勘案し定期的に更新のご署名をしていただくことになります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_1_SUB_3_DESCRIPTION_3_TEXT : '新規でお取引開始の場合は貴社古物商許可書のコピーの弊社への提出が必要となります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_2_TEXT                     : 'コンプライアンスに関して',
  OTHERS_ORDER_INTRO_RULE_MAIN_2_DESCRIPTION_TEXT         : '機械を輸出する場合にはご購入者様において、輸入国の環境、安全に関する全ての規制を遵守し、必要な措置 （製品の改造、政府承認の取得等）を取って戴くことになります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_TEXT                     : '販売条件に関して',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_1_TEXT : '機械は先売優先・現状有姿・機械保証なしでの販売となります。',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_2_TEXT : '従って、機械をご購入頂く際には、実機確認を強くお勧め致します。',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_3_TEXT : 'お支払いはご購入日より1週間以内に弊社指定の銀行口座に全額現金（日本円）にてお支払して戴きます。（電信送金）',
  OTHERS_ORDER_INTRO_RULE_MAIN_3_SUB_1_DESCRIPTION_4_TEXT : '機械の搬出はご購入日より1ヶ月以内にお願い致します。',

  OTHERS_PROFILE_H2                                       : '会社案内',
  OTHERS_PROFILE_TH1                                      : '経営理念',
  OTHERS_PROFILE_TH2                                      : '会社名',
  OTHERS_PROFILE_TH3                                      : '運営',
  OTHERS_PROFILE_TH4                                      : '代表者名',
  OTHERS_PROFILE_TH5                                      : '主な事業内容',
  OTHERS_PROFILE_TH6                                      : '所在地',
  OTHERS_PROFILE_TH7                                      : '設立',
  OTHERS_PROFILE_TH8                                      : 'バイク事業部HP',
  OTHERS_PROFILE_TD1                                      : 'お客様の利便性の向上と',
  OTHERS_PROFILE_TD1__2                                   : 'バイク業界全体の発展、拡大を目指します。',
  OTHERS_PROFILE_TD1__3                                   : '貿易業から始まった私たちは、世界中から〝集める力〟と〝見つける力〟を培い',
  OTHERS_PROFILE_TD1__4                                   : 'バイク業界でもその力を発揮し、日本が誇る美しいバイク達を',
  OTHERS_PROFILE_TD1__5                                   : '大事にしてきた相棒を、本当に必要としている人の新しい相棒として',
  OTHERS_PROFILE_TD1__6                                   : '末長く活躍できるよう、繋いでいきたいと思います。',
  OTHERS_PROFILE_TD2                                      : '株式会社UEJ',
  OTHERS_PROFILE_TD3                                      : 'OBM　バイク事業部',
  OTHERS_PROFILE_TD4                                      : 'SIMON JAMES ATKINS',
  OTHERS_PROFILE_TD5_UL1                                  : '・貿易事業',
  OTHERS_PROFILE_TD5_UL2                                  : '・運送事業',
  OTHERS_PROFILE_TD5_UL3                                  : '・中古機械の小売業',
  OTHERS_PROFILE_TD5_UL4                                  : '・バイク買取事業、オークション運営',
  OTHERS_PROFILE_TD6_UL1                                  : '株式会社UEJ',
  OTHERS_PROFILE_TD6_UL1__2                               : ' 〒824-0022',
  OTHERS_PROFILE_TD6_UL1__3                               : ' 福岡県行橋市稲童889-1',
  OTHERS_PROFILE_TD6_UL2                                  : 'OBM バイク事業部',
  OTHERS_PROFILE_TD6_UL2__2                               : ' 〒824-0028',
  OTHERS_PROFILE_TD6_UL2__3                               : ' 福岡県行橋市辻垣123-3',
  OTHERS_PROFILE_TD7                                      : '2015年9月',

  OTHERS_PRIVACY_H2                   : 'プライバシーポリシー',
  OTHERS_PRIVACY_SUPPLEMENT           : '株式会社UEJ（以下「当社」といいます）は、お客様の個人情報の保護を最も重要な責務と認識し、 個人情報を取り扱いさせていただきます。',
  OTHERS_PRIVACY_DT1                  : '1. 法令等の遵守',
  OTHERS_PRIVACY_DD1                  : '当社は、お客様の個人情報を取り扱うにあたり、個人情報保護に関する関係法令、及び社内諸規程等を遵守します。',
  OTHERS_PRIVACY_DT2                  : '2. 個人情報の項目',
  OTHERS_PRIVACY_DD2                  : '当社は、お客様の次の個人情報を「３．利用目的」で定めた範囲内で利用します。氏名、生年月日、性別、住所、電話番号、メールアドレス、利用履歴、アンケート内容等の、当社が取得した特定の個人を 識別する事ができる情報。',
  OTHERS_PRIVACY_DT3                  : '3. 個人情報の利用目的 識別する事ができる情報。',
  OTHERS_PRIVACY_DD3                  : '当社は、お客様の個人情報の利用目的は次のとおりとします。 識別する事ができる情報。',
  OTHERS_PRIVACY_DD3_OL1              : '1） 当社が運営する査定、オークション取引に付随する業務及びサービスの遂行に利用いたします。 ',
  OTHERS_PRIVACY_DD3_OL2              : '2） 上記以外の事業におけるマーケティング活動・商品開発・サービスの提供の為に利用いたします。',
  OTHERS_PRIVACY_DD3_OL3              : '3） 他のオークション会場との提携に基づくサービスの提供に関する為に利用いたします。',
  OTHERS_PRIVACY_DT4                  : '4. 第三者への提供',
  OTHERS_PRIVACY_DD4                  : '当社は、以下のいずれかに該当する場合を除き、お客様の個人情報を第三者に提供することはいたしません。',
  OTHERS_PRIVACY_DD4_OL1              : '1） 客様ご本人の同意を得ている場合。',
  OTHERS_PRIVACY_DD4_OL2              : '2） 法令に基づき開示・提供を求められた場合。',
  OTHERS_PRIVACY_DD4_OL3              : '3） 人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難である場合。',
  OTHERS_PRIVACY_DD4_OL4              : '4） 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合。',
  OTHERS_PRIVACY_DT5                  : '5. 開示・訂正・削除',
  OTHERS_PRIVACY_DD5                  : '当社は、個人情報を正確かつ最新の状態で管理するよう努めます。また、お客様から当社が保有している個人情報 の開示を求められたときは所定の手続に基づき速やかに開示します。その結果、万一誤った情報があれば速やかに訂正又は削除いたします。',
  OTHERS_PRIVACY_DT6                  : '6. 安全管理',
  OTHERS_PRIVACY_DD6                  : '当社は、取扱う個人情報の、漏えい、滅失又はき損の防止その他の安全管理のための必要かつ適切な措置を講じます。',
  OTHERS_PRIVACY_DT7                  : '7. 委託先の監督',
  OTHERS_PRIVACY_DD7                  : '当社は、個人情報の取扱いの全部又は一部を委託する場合は、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行います。',
  OTHERS_PRIVACY_DT8                  : '8. 個人情報の取扱いに関する問い合わせ窓口',
  OTHERS_PRIVACY_DD8_TH1              : '受付',
  OTHERS_PRIVACY_DD8_TH2              : '電話',
  OTHERS_PRIVACY_DD8_TH3              : 'FAX',
  OTHERS_PRIVACY_DD8_TH4              : 'E-Mail',
  OTHERS_PRIVACY_DD8_TD1              : '〒824-0028 福岡県行橋市辻垣123-3',
  OTHERS_PRIVACY_DD8_TD1__2           : '株式会社UEJ OBMバイク事業部',

  GUIDE_PAGE_TITLE_1                  : 'WEB オークション',
  GUIDE_PAGE_TITLE_2                  : '封入出展/商談の流れ',
  GUIDE_PAGE_STEP1_TITLE              : '会員登録',
  GUIDE_PAGE_STEP1_DESCRIPTION_1      : 'オークションに参加をご希望される方はまず、webサイトで会員登録申請を行って下さい。',
  GUIDE_PAGE_STEP1_DESCRIPTION_2      : 'ご登録申請受付後に当社にて審査を致します。審査を完了された方には、メールにてログインIDをお送り致します。',
  GUIDE_PAGE_STEP1_DESCRIPTION_3      : '尚、審査にあたり以下の書類のご提出をお願いする事になりますので、予めご了承下さい。',
  GUIDE_PAGE_STEP1_NOTE_TITLE_1       : '審査パス',
  GUIDE_PAGE_STEP1_NOTE_DESCRIPTION_1 : '参加資格',
  GUIDE_PAGE_STEP1_NOTE_DESCRIPTION_2 : '会員で、オークション参加要領と輸出管理上の留意事項を理解し、弊社の審査にパスした方に限ります。',

  GUIDE_PAGE_STEP2_TITLE : '商品を探す',
  GUIDE_PAGE_STEP2_TAB_1 : 'オークション',
  GUIDE_PAGE_STEP2_TAB_2 : '封入出展',
  GUIDE_PAGE_STEP2_TAB_3 : '在庫機商談',

  GUIDE_PAGE_STEP2_TAB_1_TITLE_1         : '閲覧期間と入札期間',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_1 : '出品建機情報を事前に確認できる「閲覧期間」と、実際に入札ができる「入札期間」が設定されています。',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_2 : '「閲覧期間」でも、お気に入り登録が可能ですので是非ご覧ください。',
  GUIDE_PAGE_STEP2_TAB_1_1_DESCRIPTION_3 : 'スケジュールを見る',
  GUIDE_PAGE_STEP2_TAB_1_2_TITLE_1       : 'オークションとは',
  GUIDE_PAGE_STEP2_TAB_1_2_DESCRIPTION_1 : '表示されている現在価格＋入札単位ごとの金額であれば入札可能な「オークション」方式です。',
  GUIDE_PAGE_STEP2_TAB_1_3_TITLE_3       : '終了時刻の延長',
  GUIDE_PAGE_STEP2_TAB_1_3_DESCRIPTION_1 : '終了直前N分以内に、現在価格が変わる入札があった場合、その機械に限り時間が延長される仕組みとなっております。（延長の有無、終了直前N分の値は、オークションによって異なります。）',
  GUIDE_PAGE_STEP2_TAB_1_4_TITLE_4       : '落札価格決定方法について',
  GUIDE_PAGE_STEP2_TAB_1_5_TITLE_5       : '自動入札',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_1 : '1番高い価格で入札した方が落札します。落札額は、２番目に入札価格が高い方の入札価格に１入札単位を足した金額が、自動的に決定されます。',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_2 : '（例）2番目に高い入札額650万円 ＋ 1入札単位（10万円）＝ 落札額660万円',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_3 : '例：スタート価格400万円、',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_4 : '最低落札価格600万円、',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_5 : '入札単位10万円の場合',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_6 : '入札例',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_7 : '２番目に入札価格が高い650万円＋１入札単位（10万円）が現在価格として表示されます。',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_8 : 'このまま終了した場合、落札者は、Aさんで落札額は660万円となります。',
  GUIDE_PAGE_STEP2_TAB_1_5_DESCRIPTION_9 : 'オークション出展一覧を見る',
  GUIDE_PAGE_STEP2_TAB_2_TITLE_1         : '閲覧期間と入札期間',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_1 : '出品建機情報を事前に確認できる「閲覧期間」と、実際に入札ができる「入札期間」が設定されています。',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_2 : '「閲覧期間」でも、お気に入り登録が可能ですので是非ご覧ください。',
  GUIDE_PAGE_STEP2_TAB_2_1_DESCRIPTION_3 : 'スケジュールを見る',
  GUIDE_PAGE_STEP2_TAB_2_2_TITLE_1       : '封入出展とは',
  GUIDE_PAGE_STEP2_TAB_2_2_DESCRIPTION_1 : '現在価格が非公開となり、終了時刻に一番高い価格を入札していた方が落札となる「封印」方式となります。',
  GUIDE_PAGE_STEP2_TAB_2_3_TITLE_3       : '終了時刻の延長',
  GUIDE_PAGE_STEP2_TAB_2_3_DESCRIPTION_1 : '封入出展方式は、延長はありません。',
  GUIDE_PAGE_STEP2_TAB_2_4_TITLE_4       : '落札価格決定方法について',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_1 : '落札額　＝　TOPの入札額',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_2 : '1番高い価格で入札した方が、そのまま落札額となる方式。',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_3 : '※最高額が複数の場合はより早く入札した入札者を落札者とします。',
  GUIDE_PAGE_STEP2_TAB_2_5_DESCRIPTION_4 : '封入出展機一覧を見る',
  GUIDE_PAGE_STEP2_TAB_3_TITLE_1         : '開催時期',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_1 : '随時開催しておりますので、サイトからご確認ください。',
  GUIDE_PAGE_STEP2_TAB_3_TITLE_2         : '価格について',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_2 : 'サイトに記載がない場合は、お問い合わせよりご連絡ください。',
  GUIDE_PAGE_STEP2_TAB_3_1_DESCRIPTION_3 : '在庫機一覧を見る',
  GUIDE_PAGE_STEP3_TITLE                 : '商品を問い合わせる',
  GUIDE_PAGE_STEP3_DESCRIPTION_1         : '機械についてご不明な点があれば、問い合わせフォームにてお問い合わせください。',
  GUIDE_PAGE_STEP3_DESCRIPTION_2         : '※実機の確認を希望される方は、事前に各事務所へのご連絡をお願い致します。',
  GUIDE_PAGE_STEP4_TITLE                 : '商談成立',
  GUIDE_PAGE_STEP4_DESCRIPTION_1         : 'オークション、封入出展に参加頂いた方には入札結果をメールにてご連絡申し上げます。',
  GUIDE_PAGE_STEP4_DESCRIPTION_2         : '結果発表日までに連絡がない場合、誠に申し訳ございませんが、弊社までご連絡下さい。',
  GUIDE_PAGE_STEP5_TITLE                 : 'お支払い',
  GUIDE_PAGE_STEP5_DESCRIPTION_1         : '請求書をご案内してから１週間以内に現金(電信送金）にてお支払い下さい。',
  GUIDE_PAGE_STEP5_DESCRIPTION_2         : '（注）応札後のキャンセルは如何なる理由であっても認められません。',
  GUIDE_PAGE_STEP5_DESCRIPTION_3         : '落札後、不当にキャンセルされた場合は、違約金として落札価格の20％をお支払い頂きます。',
  GUIDE_PAGE_STEP6_TITLE                 : '取引・搬出',
  GUIDE_PAGE_STEP6_DESCRIPTION_1         : '機械の引取、搬出は弊社へのご入金を確認後可能となります。',
  GUIDE_PAGE_STEP6_DESCRIPTION_2         : '機械の引取、搬出は落札のご連絡より1ヶ月以内にお願い致します。',
  GUIDE_PAGE_STEP6_DESCRIPTION_3         : '保証',
  GUIDE_PAGE_STEP6_DESCRIPTION_4         : '現状有姿での販売につきご購入後の保証は一切ございません。',
  GUIDE_PAGE_STEP6_DESCRIPTION_5         : '入札機リストに記載の仕様及び状態はあくまで参考であり、実機のご確認はお客様ご自身にてお願い致します。',

  FAQ_TITLE_H1                           : 'ライマサポート・困った時のQ&A',
  FAQ_TITLE_H2                           : 'ライマサポート',
  FAQ_GUIDE_LINK                         : 'ご利用ガイド',
  FAQ_REQUIRED_DOCUMENT                  : '必要書類について',
  FAQ_MAINTENANCE_AND_REPAIR             : 'ライマ整備・修理',
  FAQ_DELIVERY                           : '配送について',
  FAQ_MARKET_GUIDE                       : 'ライダーズマーケットのご利用方法',
  FAQ_MARKET_GUIDE_TEXT                  : 'にご利用の流れが記載してあります。是非ご覧ください。',
  FAQ_BUYING_AND_SELLING                 : '車両売買に関する必要書類について',
  FAQ_BUYING_AND_SELLING_TEXT            : '個人で売買する際に必要な書類について詳しく解説しております、',
  FAQ_BUYING_AND_SELLING_TEXT__2         : '一度ご確認いただき準備漏れなどないようにしましょう。',
  FAQ_BUYING_AND_SELLING_TEXT__3         : 'オークションから買いたいけど整備してくれるところがない方、オークションから買って、そのまま整備をしてご自宅へお届けも可能です！！',
  FAQ_BUYING_AND_SELLING_TEXT__4         : 'から詳しいサービス内容をご確認ください。',
  FAQ_TROUBLE                            : '困った時のQ&A',
  FAQ_SECTION1_TITLE                     : '会員登録について',
  FAQ_SECTION1_Q1                        : 'アカウントはメールアドレス毎に作ることはできますか？',
  FAQ_SECTION1_A1                        : ' アカウントを持てるのは',
  FAQ_SECTION1_A1_RED                    : ' お一人につき1つまで',
  FAQ_SECTION1_A1__2                     : 'です。',
  FAQ_SECTION1_A1__3                     : 'もし2つ目以上のアカウントが見つかった場合、予告なしに削除されますのでお気を付けください。',
  FAQ_SECTION1_Q2                        : '登録したアドレス、パスワードを忘れてしまった',
  FAQ_SECTION1_A2                        : 'パスワードをお忘れの場合はログイン画面の「パスワードを忘れた方はこちら」よりパスワードの再発行を行ってください。メールアドレスをお忘れの場合は事務局にお問い合わせください。',
  FAQ_SECTION2_TITLE                     : '買取査定について',
  FAQ_SECTION2_Q1                        : '買取査定のみをお願いしたい',
  FAQ_SECTION2_A1                        : 'ぜひお気軽に',
  FAQ_SECTION2_A1_LINK                   : '査定フォーム',
  FAQ_SECTION2_A1__2                     : 'からご依頼ください。買取査定のお見積りは無料です。',
  FAQ_SECTION2_Q2                        : '廃車や事故車、故障車でも大丈夫ですか？',
  FAQ_SECTION2_A2                        : '大丈夫です！他社でお値段がつかなかった、逆に処分費用の請求を受けた車両でも高価買取できる場合がございます。一度ご相談ください。',
  FAQ_SECTION2_Q3                        : '取引完了後に車両の不具合が見つかった場合、どうなりますか？',
  FAQ_SECTION2_A3                        : '査定フォームの内容が明らかに嘘のご申告であった場合は払い戻し請求、最悪キャンセルの可能性もありますが、わざと嘘をつく、隠蔽をするといったことがなく、きちんとわかる範囲で状態を教えてくださればそんなことは起こりませんのでご安心ください。',
  FAQ_SECTION2_Q4                        : '詳しくないので車両についてよくわかりません',
  FAQ_SECTION2_A4                        : '査定フォーマットでわからないところは不明で進めてもらって大丈夫です。またこちらからチャットやご希望であればお電話にて質問させてもらいますので分かる範囲でお答えいただくだけで大丈夫です。',
  FAQ_SECTION2_A4__2                     : 'バイクのプロにお任せください！',
  FAQ_SECTION2_Q5                        : '高価買取できる理由はなんですか？',
  FAQ_SECTION2_A5                        : '当社は日本国内だけでなく世界中に取引先があります。国内だけだと、人気な車種が限られてますがマーケットは世界中ですので日本ではそんなに高くない車両でも海外では人気があったり、価値が高いものだったりします。世界中のネットワークを使って取引しますので、高価買取が可能となっております。',
  FAQ_SECTION3_TITLE                     : 'オークション出品について',
  FAQ_SECTION3_Q1                        : '準備する書類がわかりません',
  FAQ_SECTION3_A1                        : 'をご覧ください。',
  FAQ_SECTION3_Q2                        : '間違った車両情報のまま出品してしまった！',
  FAQ_SECTION3_A2                        : '下見、入札が入る前までキャンセルが可能です。出品料はシステム上返金はできませんので出品前に十分にお確かめください。',
  FAQ_SECTION3_Q3                        : 'チャットルームでメールアドレスや電話番号などの個人情報を載せてしまった、送られてきた',
  FAQ_SECTION3_A3                        : '売買成立前のチャットは全て公開されますので、トラブルになるまえに見つけたら通報するか、ライマサポートまでご連絡ください。',
  FAQ_SECTION3_Q4                        : 'ライマ外での取引を持ちかけられた',
  FAQ_SECTION3_A4                        : 'オークションシステムを利用せずに直接取引を行うことは利用規約違反で、トラブルに巻き込まれる可能性があるため、取引は行わないでください。',
  FAQ_SECTION3_A4__2                     : '英語で書かれていることもあります。十分にお気を付けください。',
  FAQ_SECTION3_A4__3                     : 'トラブルを防ぐためにも、車両に関係ない質問には返信しないこともご検討ください。（ライマでは回答義務に関するルールは設けておりません）',
  FAQ_SECTION3_A4__4                     : '売買成立前のチャットルームは事務局も含め公開されます。',
  FAQ_SECTION3_Q5                        : '出品を取消したい',
  FAQ_SECTION3_A5                        : '入札が入っていない場合の取消は可能です。（いかなる場合も出品料の払い戻しはできません）',
  FAQ_SECTION3_A5__2                     : '万が一、入札が入っている出品中の車両を壊してしまったり、商品が用意できなくなったりして、どうしても出品を取り消さなくてはいけなくなった場合はライマサポートへご相談ください。その際ペナルティが生じる場合があります。',
  FAQ_SECTION3_Q6                        : '出品が勝手に取り消された',
  FAQ_SECTION3_A6                        : 'オークション利用規約に違反すると思われる出品を見つけたときにライマ事務局が出品を取り消します。',
  FAQ_SECTION3_A6__2                     : '取り消したあとご登録のアドレスへ通知メールが届きますのでご確認ください。',
  FAQ_SECTION3_A6__3                     : '※違反の理由や根拠をライマにお問い合わせいただいても、回答できません。ご自身で判断をお願いいたします。',
  FAQ_SECTION4_TITLE                     : '入札について',
  FAQ_SECTION4_Q1                        : '車両や取引内容についてもっと詳しく知りたい',
  FAQ_SECTION4_A1                        : '書類や車両について、支払方法などの取引条件について知りたい場合は出品者に必ず確認しましょう。不明点を確認し、納得したうえで入札することでトラブルが防止できます。',
  FAQ_SECTION4_Q2                        : '質問の返答がない',
  FAQ_SECTION4_A2                        : '質問に回答するかどうかは出品者に判断が任されています。しかし、商品や取引についての不安や疑問が残ったまま入札/落札した場合、トラブルが発生する可能性があるので、入札を控えることをお勧めします。',
  FAQ_SECTION4_Q3                        : '入札を取り消ししたい',
  FAQ_SECTION4_A3                        : '入札の取り消し及び現在価格以下の金額を指定した入札は認めておりません。ただし、TOP入札者の方に限り現在価格+1ピッチまで価格を下げて入札することが可能です。',
  FAQ_SECTION4_Q4                        : '入札・出品が取り消された',
  FAQ_SECTION4_A4_SUBTITLE1              : '■出品の取り消し',
  FAQ_SECTION4_A4__2                     : '商品の破損など、やむを得ない事情が発生した場合は、入札者がいる場合でも、出品を取り消すことがあります。また、利用規約違反により、ライマが取り消す場合もあります。',
  FAQ_SECTION4_A4_SUBTITLE2              : '■入札の取り消し',
  FAQ_SECTION4_A4__3                     : '出品の取消に伴って該当出品に対する入札も取消される場合がございます。取り消された理由について、個別に回答できません。',
  FAQ_SECTION5_TITLE                     : 'ライマ配送手配について',
  FAQ_SECTION5_Q1                        : '配送先を変更したい ',
  FAQ_SECTION5_A1                        : '申し訳ございません。配送元、配送先両方とも原則変更ができません。ご出品される前、ご入札される前に会員情報の変更をお願い致します。',
  FAQ_SECTION5_Q2                        : '商品ページの配送料の記載と請求された配送料が違う',
  FAQ_SECTION5_A2                        : '配送の発着のご住所が離島の場合、船を利用する配送となる場合、バイクに付属品がある場合、規格外である場合に配送料が変更となる場合があります。',
  FAQ_SECTION5_A2__2                     : '心配な方は、先に配送についてライマサポートまでお問い合わせいただくことをおすすめ致します。',
  FAQ_SECTION5_A2__3                     : '詳しくは',
  FAQ_SECTION5_A2__4                     : 'をご覧ください。',
  FAQ_SECTION5_Q3                        : 'ライマ配送手配のメリットはなに？',
  FAQ_SECTION5_A3                        : '・出品者様、落札者様の配送手配の手間がまったくかかりません。',
  FAQ_SECTION5_A3__2                     : '・出品者様とのやりとりもライマが致しますので安心です。',
  FAQ_SECTION5_A3__3                     : '・配送内容を全て確認し、落札者様から配送料のご入金までいただいて、いつでも配送手配ができる状態になってから取引がスタートいたします。',
  FAQ_SECTION5_A3__4                     : '取引が始まるまでのお時間は少しかかってしまいますが、出品者様と落札者様との間で配送トラブルなどが起こらないよう出来るだけ配慮しております。',
  FAQ_SECTION6_TITLE                     : '落札後について',
  FAQ_SECTION6_Q1                        : '出品者/落札者と連絡がとれない',
  FAQ_SECTION6_A1                        : 'まずはご自身から連絡をとってみてください。2週間以上連絡がとれず、取引が進めるのが難しい場合は、',
  FAQ_SECTION6_A1_LINK                   : '利用規約に記載されている「落札後のキャンセル」',
  FAQ_SECTION6_A1__2                     : 'となります。ライマ事務局へご連絡ください。',
  FAQ_SECTION6_Q2                        : '入金したのに商品が届かない/入金がない',
  FAQ_SECTION6_A2                        : '取引相手に、誤解や過失などの何らかの事情があるかもしれません。',
  FAQ_SECTION6_A2__2                     : 'まずはチャットルームなどから相手に連絡してみましょう。',
  FAQ_SECTION6_A2__3                     : 'こういったトラブルを防ぐ予防策として、',
  FAQ_SECTION6_A2_SUBTITLE1              : '落札者側',
  FAQ_SECTION6_A2__4                     : '・配送業者が車両を引き取る段取りがきちんとできたことを確認して入金をする',
  FAQ_SECTION6_A2_SUBTITLE2              : '出品者側',
  FAQ_SECTION6_A2__5                     : '・取引条件をライマ配送手配にする',
  FAQ_SECTION6_A2__6                     : '・配送業者へ車両を引き渡す前に入金を確認する',
  FAQ_SECTION6_A2__7                     : 'があります。予防策を取り入れたお取引をご検討ください。',
  FAQ_SECTION6_Q3                        : '届いた商品が出品時に記載されていたものと異なる',
  FAQ_SECTION6_A3                        : '中古車の状態(特にバッテリーやエンジン始動など)は保証するのが難しいのが正直なところです。',
  FAQ_SECTION6_A3__2                     : '明らかに写真や動画と異なる場合（写真では純正マフラーだったのに社外品がついてきた、等）は、出品者へ速やかに状況を説明して、相談しましょう。',
  FAQ_SECTION7_TITLE                     : 'その他',
  FAQ_SECTION7_Q1                        : 'オークション利用規約違反を見つけたとき',
  FAQ_SECTION7_A1                        : '事務局へご連絡ください。ライマで内容を確認し違反と判断した場合は削除します。',
  FAQ_SECTION7_A1__2                     : '・申告に対する個別の回答は行っていません。',
  FAQ_SECTION7_A1__3                     : '・申告に対して、ライマが削除をお約束するものではありません。',
  FAQ_SUPPORT_TEXT                       : '上記以外のお問い合わせはライマサポートまでご連絡ください。',
  FAQ_SUPPORT_TIME                       : '平日9:00～16:00',

  CARD_INFO_FORM_ENTRY_FORM_INFO_MESSAGE  : '各種手数料のお支払いにご使用するクレジットカードを登録します。',
  CARD_INFO_FORM_ENTRY_FORM_INFO_MESSAGE2 : '※同一クレジットカード番号の入力でカード情報が変更されます',
  CARD_INFO_CREATE_BUTTON_LABEL           : '新しいカードを追加する',
  CARD_INFO_LIST_CARD_NO_FIELD            : 'カード番号（下 4桁）',
  CARD_INFO_LIST_CARD_BRAND_FIELD         : 'ブランド',
  CARD_INFO_LIST_DEFAULT_FIELD            : 'デフォルト',
  CARD_INFO_LIST_DELETE_FIELD             : '削除',
  CARD_INFO_DEFAULT_BUTTON_LABEL          : 'デフォルトにする',
  CARD_INFO_DELETE_BUTTON_LABEL           : '削除する',

  CARD_REGIST_DIALOG_MESSAGE             : '新しいクレジットカードを登録します。',
  CARD_REGIST_DIALOG_CARD_NO_LABEL       : 'カード番号',
  CARD_REGIST_DIALOG_HOLDER_NAME_LABEL   : 'カード名義人',
  CARD_REGIST_DIALOG_EXPIRE_LABEL        : 'カード有効期限',
  CARD_REGIST_DIALOG_SECURITY_CODE_LABEL : 'セキュリティコード',
  CARD_REGIST_DIALOG_DEFAULT_LABEL       : 'このカードをデフォルトにする',
  CARD_REGIST_DIALOG_CANCLE_BUTTON_LABEL : '閉じる',
  CARD_REGIST_DIALOG_REGIST_BUTTON_LABEL : '登録する',

  CARD_REGIST_ERROR_MESSAGE                        : '入力内容を確認してください。',
  CARD_REGIST_CARD_NO_REQUIRED_ERROR_MESSAGE       : 'カード番号を入力してください。',
  CARD_REGIST_CARD_NO_FORMAT_ERROR_MESSAGE         : 'カード番号を正しく入力してください。',
  CARD_REGIST_HOLDER_NAME_REQUIRED_ERROR_MESSAGE   : 'カード名義人を入力してください。',
  CARD_REGIST_EXPIRE_REQUIRED_ERROR_MESSAGE        : 'カード有効期限を入力してください。',
  CARD_REGIST_EXPIRE_FORMAT_ERROR_MESSAGE          : 'カード有効期限を正しく入力してください。',
  CARD_REGIST_SECURITY_CODE_REQUIRED_ERROR_MESSAGE : 'セキュリティコードを入力してください。',
  CARD_REGIST_SECURITY_CODE_FORMAT_ERROR_MESSAGE   : 'セキュリティコードを正しく入力してください。',

  CARD_CHANGE_DIALOG_DEFAULT_MESSAGE : '以下のカードをデフォルトにしてもよろしいですか。',
  CARD_CHANGE_DIALOG_DELETE_MESSAGE  : '以下のカードを削除してもよろしいですか。',
  CARD_CHANGE_UPDATE_BUTTON_LABEL    : '変更する',
  CARD_DELET_BUTTON_LABEL            : '削除する',

  CARD_REGIST_COMPLETION_MESSAGE         : '新しいカードが登録されました。',
  CARD_CHANGE_DEFAULT_COMPLETION_MESSAGE : 'デフォルトのカードが変更されました。',
  CARD_DELETE_COMPLETION_MESSAGE         : 'カードが削除されました。',

  SYSTEM_ERROR_MESSAGE : 'システムエラーが発生しました。',

  GUIDE_MYPAGE_BTN                   : 'マイページへ',
  GUIDE_DOWNLOADS_BTN                : '書類ダウンロードページ',
  GUIDE_AUCTION_COMMISSION           : '手数料',
  GUIDE_AUCTION_COMMISSION_ALL_SORTS : '※各種手数料のご案内はこちら',
  GUIDE_SHIPPING_METHOD              : '※配送についてはこちら',
  GUIDE_NEW_MEMBER_REGISTRATION      : '新規会員登録<span>(無料)</span>',
  INFORMATION_ON_REQUIRED_DOCUMENT   : '※必要書類についてのご案内はこちら',
  PURCHASE_ASSESSMENT_REQUEST        : '買取査定依頼<span>(無料)</span>',
  ABOUT_AUCTION_LISTING              : 'オークション出品について',

  GUIDE_AUCTION_H1          : 'オークション出品について',
  GUIDE_AUCTION_H2          : '売れないリスクがゼロ！業界初の必ず売れるオークション',
  GUIDE_AUCTION_H3          : '入札会を選択する',
  GUIDE_AUCTION_STEP1_TEXT1 : '定期的に行われているオークション入札会に出品できます。',
  GUIDE_AUCTION_STEP1_TEXT2 : '※マイページ画面でのクレジットカードの登録をお願いします。',
  GUIDE_AUCTION_STEP1_TEXT3 : '予定されている入札会から出品したい入札会をお選びください。',
  GUIDE_AUCTION_STEP2_TEXT1 : '出品',
  GUIDE_AUCTION_STEP2_TEXT2 : 'を支払う',
  GUIDE_AUCTION_STEP2_TEXT3 : 'マイページに登録済みのクレジットカード情報で簡単にお支払いできます。',
  GUIDE_AUCTION_STEP2_TEXT4 : '※マイページへのクレジットカード情報の登録がお済みでない方は以下よりご登録ください。',
  GUIDE_AUCTION_STEP3_TEXT1 : '下見会場にバイクを展示する',
  GUIDE_AUCTION_STEP3_TEXT2 : '入札会までの間、下見会場にて展示されます。',
  GUIDE_AUCTION_STEP4_TEXT1 : 'オークション開始',
  GUIDE_AUCTION_STEP4_TEXT2 : 'ライマが査定した金額＋',
  GUIDE_AUCTION_STEP4_TEXT3 : 'が最低落札価格となり、最低落札価格以上の入札があれば取引成立となります。',
  GUIDE_AUCTION_STEP4_TEXT4 : 'オークションが終了したら・・・',
  GUIDE_AUCTION_TAB1_TITLE1 : '落札があり',
  GUIDE_AUCTION_TAB1_TITLE2 : '取引成立の場合',
  GUIDE_AUCTION_TAB2_TITLE1 : '流札の場合',
  GUIDE_AUCTION_TAB1_TEXT1  : '配送方法を確認する',
  GUIDE_AUCTION_TAB1_TEXT2  : '落札者様が選択した配送方法をご確認ください。',
  GUIDE_AUCTION_TAB1_TEXT3  : '「ライマ配送手配」もしくは「落札者様自身で手配」があります。',
  GUIDE_AUCTION_TAB1_TEXT4  : 'ライマ配送手配の場合、ライマより追ってご連絡させていただきます。',
  GUIDE_AUCTION_TAB1_TEXT5  : '落札者様自身で手配の場合、直接ご対応のほどよろしくお願いします。',
  GUIDE_AUCTION_TAB1_TEXT6  : '※配送についてのご案内はこちら',
  GUIDE_AUCTION_TAB1_TEXT7  : '成約',
  GUIDE_AUCTION_TAB1_TEXT8  : 'を支払う',
  GUIDE_AUCTION_TAB1_TEXT9  : 'マイページに登録済みのクレジットカードでお支払い可能です。',
  GUIDE_AUCTION_TAB1_TEXT10 : '落札者様とチャットルームで直接取引する',
  GUIDE_AUCTION_TAB1_TEXT11 : '落札者様へお手続きのご連絡をお願い致します。',
  GUIDE_AUCTION_TAB1_TEXT12 : '出品者様の',
  GUIDE_AUCTION_TAB1_TEXT13 : '「氏名」「連絡先」「振込先」「お渡しする書類について」',
  GUIDE_AUCTION_TAB1_TEXT14 : 'をお伝えください。',
  GUIDE_AUCTION_TAB1_TEXT15 : '※振込み先に指定できる銀行は、出品者様の名義のもののみです。',
  GUIDE_AUCTION_TAB1_TEXT16 : '書類について：車両に関する書類と、譲渡証明書や委任状の必要書類のご準備をお願い致します。',
  GUIDE_AUCTION_TAB1_TEXT17 : '※ライマ配送手配をご選択されている場合は、基本バイクと一緒に書類もお預かり致します。',
  GUIDE_AUCTION_TAB1_TEXT18 : '落札者様より別途郵送などのご依頼がありましたらご対応お願い致します。',
  GUIDE_AUCTION_TAB1_TEXT19 : '入金確認後、書類とバイクを配送する',
  GUIDE_AUCTION_TAB1_TEXT20 : '落札者様ご指定の方法で車両を配送します。',
  GUIDE_AUCTION_TAB1_TEXT21 : '車検証や譲渡証明書などの必要な書類もお渡しします。（事前郵送もしくはバイクと一緒に配送）',
  GUIDE_AUCTION_TAB1_TEXT22 : '名義変更完了の確認',
  GUIDE_AUCTION_TAB1_TEXT23 : '落札者様によって名義変更が完了したところまで必ずご確認ください。',
  GUIDE_AUCTION_TAB2_TEXT1  : 'ライマへ買取依頼できます。',
  GUIDE_AUCTION_TAB2_TEXT2  : '落札されなかった場合でも査定額そのままライマが買い取り！',
  GUIDE_AUCTION_TAB2_TEXT3  : '買取依頼について',

  GUIDE_AUCTIONAGENCY_H1            : 'オークション出品代行について',
  GUIDE_AUCTIONAGENCY_H2            : 'あなたのバイクをプロが代わりに売ります！ライマ出品代行',
  GUIDE_AUCTIONAGENCY_SUPPLEMENT1   : 'オークションへの出品、車両管理、車両お問い合わせ全てプロにお任せ！',
  GUIDE_AUCTIONAGENCY_SUPPLEMENT2   : '売れなかった場合もライマが買い取り保証！',
  GUIDE_AUCTIONAGENCY_SUPPLEMENT3   : '30日間バイクをお預かり&あなたの代わりにプロが販売いたします。',
  GUIDE_AUCTIONAGENCY_STEP1_TITLE1  : '出品',
  GUIDE_AUCTIONAGENCY_STEP1_TITLE2  : 'を支払う',
  GUIDE_AUCTIONAGENCY_STEP1_TEXT1   : 'マイページに登録済みのクレジットカード情報で簡単にお支払いできます。',
  GUIDE_AUCTIONAGENCY_STEP1_TEXT2   : '※マイページへのクレジットカード情報の登録がお済みでない方は以下よりご登録ください。',
  GUIDE_AUCTIONAGENCY_STEP2_TITLE1  : '配送手配',
  GUIDE_AUCTIONAGENCY_STEP2_TITLE2  : '（ライマがすべて手配します！）',
  GUIDE_AUCTIONAGENCY_STEP2_TEXT1   : '車検証や譲渡証明書などの必要書類をご準備ください。',
  GUIDE_AUCTIONAGENCY_STEP3_TITLE1  : '車両チェック',
  GUIDE_AUCTIONAGENCY_STEP3_TEXT1   : '整備士が車両をチェックします。少しでも高く売れるよう、自社バイクと同じようにようにしっかり丁寧にチェックします。',
  GUIDE_AUCTIONAGENCY_STEP4_TITLE1  : '出品代行によるオークション開始',
  GUIDE_AUCTIONAGENCY_STEP4_TEXT1   : '期間中の最適な入札会へ最低2回の出品を保証します。',
  GUIDE_AUCTIONAGENCY_STEP4_TEXT2   : '入札会以外でも倉庫内もしくは実店舗にて展示し、直接ご来店いただいたお客様にアプローチできます。',
  GUIDE_AUCTIONAGENCY_STEP4_TEXT3   : '車両に関するお問い合わせは全てライマが対応！',
  GUIDE_AUCTIONAGENCY_STEP4_TEXT4   : 'ライマが査定した金額＋',
  GUIDE_AUCTIONAGENCY_STEP4_TEXT5   : 'が最低落札価格となり、それを超える入札者があれば取引成立となります。',
  GUIDE_AUCTIONAGENCY_STEP4_TEXT6   : 'ご入札が最低落札価格まで到達されなかった場合はご流札、取引不成立となります。',
  GUIDE_AUCTIONAGENCY_TAB1_TEXT1    : '落札者様とのやりとりも全てライマが対応します。',
  GUIDE_AUCTIONAGENCY_TAB1_H4_1     : 'バイク代金を受け取る',
  GUIDE_AUCTIONAGENCY_TAB1_TEXT2    : '落札者様からのご入金確認後、成約',
  GUIDE_AUCTIONAGENCY_TAB1_TEXT3    : 'を差し引いた金額をお支払いします。',
  GUIDE_AUCTIONAGENCY_TAB1_H4_2     : '名義変更完了の確認',
  GUIDE_AUCTIONAGENCY_TAB1_TEXT7    : '名義変更の確認が取れ次第、チャットにてご報告いたします。',
  GUIDE_AUCTIONAGENCY_TAB2_H4_1     : '買い取りの手続き',
  GUIDE_AUCTIONAGENCY_TAB2_TEXT1    : 'ライマがそのまま買い取りいたします。',
  GUIDE_AUCTIONAGENCY_TAB2_TEXT2    : '契約書を送付しますので、ご確認とご捺印のうえご返送お願いします。',
  GUIDE_AUCTIONAGENCY_TAB2_H4_2     : 'バイク代金を受け取る',
  GUIDE_AUCTIONAGENCY_TAB2_TEXT3    : '落札者様からのご入金確認後、成約',
  GUIDE_AUCTIONAGENCY_TAB2_TEXT4    : 'を差し引いた金額をお支払いします。',
  GUIDE_AUCTIONAGENCY_TAB2_H4_3     : 'バイク代金を受け取る',
  GUIDE_AUCTIONAGENCY_TAB2_TEXT5    : '書類ご返送の確認後に銀行振込にてお支払いします。',
  GUIDE_AUCTIONAGENCY_TAB2_H4_4     : '名義変更完了',
  GUIDE_AUCTIONAGENCY_TAB2_TEXT6    : '名義変更後、チャットにてご報告いたします。',

  GUIDE_BUY_H1                   : 'バイクを買いたい方',
  GUIDE_BUY_H2                   : '出展されるバイクはプロがきちんと査定したバイクのみ！業界初のオークション',
  GUIDE_BUY_STEP1_TITLE1         : '会員登録',
  GUIDE_BUY_STEP1_TEXT1          : '新規会員登録を行なってください。クレジットカードをご準備ください。',
  GUIDE_BUY_STEP2_TITLE1         : 'バイクを探す',
  GUIDE_BUY_STEP2_TEXT1          :  '・下見会場から直近の入札会へ出品予定のバイク一覧をみる',
  GUIDE_BUY_STEP2_TEXT2          :  '・お探しのバイクを検索する',
  GUIDE_BUY_STEP2_TEXT3          :  '気になるバイクはお気に入り登録を！',
  GUIDE_BUY_STEP2_TEXT4          :  'バイクに関するご質問はチャットルームからお問い合わせください。',
  GUIDE_BUY_STEP3_TITLE1         : 'いざ、入札！',
  GUIDE_BUY_STEP3_TEXT1          :  '1入札2000円からご入札いただけます。',
  GUIDE_BUY_STEP3_TEXT2          :  '最低落札価格まで到達されてない場合は入札しても落札となりません。',
  GUIDE_BUY_STEP3_TEXT3          :  'オークションのしくみ',
  GUIDE_BUY_STEP3_TEXT4          :  '表示してある現在価格に１入札（2000円）ごとに入札できます。',
  GUIDE_BUY_STEP3_TEXT4__2          :  'まだ誰も入札していない商品についてはスタート価格と同額で入札することができますが、',
  GUIDE_BUY_STEP3_TEXT4__3          :  '既に入札がある商品については現在価格+1入札分(2000円)足した金額以上の入札を行う必要があります。',
  GUIDE_BUY_STEP3_TEXT4__4          :  'オークション終了間近に入札があった場合、自動延長されます。',
  GUIDE_BUY_STEP3_TEXT4__5          :  '最後の入札から３分間他の入札がなければ終了です。',
  GUIDE_BUY_STEP3_TEXT4__6          :  '最低落札価格まで到達されてない場合は入札しても落札となりません。',
  GUIDE_BUY_STEP3_TEXT4__7          :  '１番高い価格で入札した方が落札します。ライマは「セカンドプライス方式」オークションです。',
  GUIDE_BUY_STEP3_TEXT5          :  'セカンドプライス方式とは',
  GUIDE_BUY_STEP3_TEXT6          :  '１番高い金額で入札した方が落札者となり、2番目の入札金額に1入札足した金額が落札金額となります。',
  GUIDE_BUY_STEP3_TEXT6__2          :  '例えば、あなたが３０万円で入札してトップの入札者となり、2番目の入札者が２０万円であった場合でオークションが終了した場合落札者はあなたですが、落札金額は２０万＋1入札分（2000円）の20万2000円で落札できます。',
  GUIDE_BUY_STEP3_TEXT6__3          :  'このしくみは1入札ずつ競り上げていかなくても、欲しいバイクにあらかじめ自分の予算で入札をしておけば、その予算以下で落札できる可能性がある便利なしくみです。',
  GUIDE_BUY_STEP3_TEXT6__4          :  '最後に、同じ金額で複数会員様の入札があった場合は先に入札した会員様が優先的に落札権利を得ます。',
  GUIDE_BUY_STEP3_TEXT6__5          :  'お目当ての商品にはお早めにご入札ください！',
  GUIDE_BUY_STEP4_TITLE1         : '見事落札！おめでとうございます',
  GUIDE_BUY_STEP4_TEXT1          :  '配送方法を選択します',
  GUIDE_BUY_STEP4_TEXT1__2          :  '配送料は原則落札者負担で取引されます。',
  GUIDE_BUY_STEP4_TEXT2          :  '・ライマ配送手配',
  GUIDE_BUY_STEP4_TEXT3          :  'ライマ配送手配ではバイクの配送と必要書類も合わせて配送手配いたしますのでおすすめです。',
  GUIDE_BUY_STEP4_TEXT4          :  '・自分で配送手配',
  GUIDE_BUY_STEP4_TEXT5          :  '自分で配送手配の場合は出品者様ご希望の出荷先からご手配となります。（中継地やデポからの指定はできません）',
  GUIDE_BUY_STEP5_TITLE1         : '落札',
  GUIDE_BUY_STEP5_TITLE2         : 'のご決済 ',
  GUIDE_BUY_STEP5_TEXT1          :  '登録クレジットカードから簡単決済',
  GUIDE_BUY_STEP5_TEXT2          :  '・ライマ配送手配の場合',
  GUIDE_BUY_STEP5_TEXT3          :  '配送料を確定し別途ご請求致します。',
  GUIDE_BUY_STEP5_TEXT4          :  '詳しくは',
  GUIDE_BUY_STEP5_TEXT5          :  '配送について',
  GUIDE_BUY_STEP5_TEXT6          :  'をご覧ください。',
  GUIDE_BUY_STEP5_TEXT7          :  '銀行振込にてお支払いの確認が取れ次第、出品者様とのお取引チャットルームがオープンします。',
  GUIDE_BUY_STEP5_TEXT8          :  '・自分で配送手配の場合',
  GUIDE_BUY_STEP5_TEXT9          :  'STEP6（チャットルームにて直接お取引開始）に進みます。',
  GUIDE_BUY_STEP6_TITLE1         : '出品者様、もしくはライマとチャットルームにて直接お取引開始',
  GUIDE_BUY_STEP6_TEXT1          :  '出品者様より',
  GUIDE_BUY_STEP6_TEXT2          :  '「氏名」「連絡先」「振込先」「お渡しする書類について」',
  GUIDE_BUY_STEP6_TEXT3          :  'など個別チャットにて連絡があるのをお待ちください。',
  GUIDE_BUY_STEP6_TEXT4          :  '連絡がない場合は落札者様よりご連絡をお願い致します。',
  GUIDE_BUY_STEP6_TEXT5          :  '※基本的にお振込み先は出品者様名義のもののみです。',
  GUIDE_BUY_STEP6_TEXT6          :  '出品時に本人確認は行っておりますが',
  GUIDE_BUY_STEP6_TEXT7          :  '振込み先の名義名が全然違う、など少しでも怪しい点があればすぐに振込はせず、十分にご確認のうえお取引を行ってください。',
  GUIDE_BUY_STEP6_TEXT8          :  '書類について：ライマ配送手配をご選択されている場合は、基本バイクと一緒に書類もお預かり致します。',
  GUIDE_BUY_STEP6_TEXT9          :  '別途郵送をご希望される場合はその旨出品者様へご依頼ください（着払い推奨）',
  GUIDE_BUY_STEP7_TITLE1         : '必要書類、バイクの配送手配',
  GUIDE_BUY_STEP7_TEXT1          : '配送についてはこちら',
  GUIDE_BUY_STEP7_TEXT2          : 'ライマ配送手配、ご自身で配送手配どちらかのお選びいただいた方法で配送手配を進めてください。',
  GUIDE_BUY_STEP7_TEXT3          : '車検書や譲渡証明書などの必要な書類のお受け取り（事前郵送もしくはバイクと一緒に配送）を完了されてください。',
  GUIDE_BUY_STEP7_TEXT4          : '※ライマ配送手配は基本的にバイクと一緒に書類もお預かりしお届けいたします。',
  GUIDE_BUY_STEP7_TEXT5          : '書類の不備、紛失などのトラブル回避のためにもこの方法を推奨いたします。',
  GUIDE_BUY_STEP8_TITLE1         : 'バイク到着確認後、名義変更',
  GUIDE_BUY_STEP8_TEXT1          : '名義変更期限はバイクの到着後2週間以内となっております。',
  GUIDE_BUY_STEP8_TEXT2          : '必ず期限内に名義変更をされて、チャットルームにてご報告をお願いします。',

  GUIDE_SELL_H1                  : 'バイクを売りたい方',
  GUIDE_SELL_H2                  : 'オンラインで全て完結!10分でできる簡単査定',
  GUIDE_SELL_STEP1_TITLE1        : '会員登録',
  GUIDE_SELL_STEP1_TEXT1         : '査定、ライマ買取サービスをご利用の場合はクレジットカードの登録は不要です。',
  GUIDE_SELL_STEP2_TITLE1        : '査定依頼',
  GUIDE_SELL_STEP2_TEXT1         : '買取査定依頼ページに必要事項を入力します。また、必要書類については画像でアップロードします。',
  GUIDE_SELL_STEP2_TEXT2         : '車両に関する書類（車検証など）をお手元に準備してからご入力いただくと大変スムーズです。',
  GUIDE_SELL_STEP2_TEXT3         : '必要書類',
  GUIDE_SELL_STEP2_TEXT4         : '（画像でご用意ください）',
  GUIDE_SELL_STEP2_TEXT5         : '本人確認書類',
  GUIDE_SELL_STEP2_TEXT6         : '車検証など',
  GUIDE_SELL_STEP2_TEXT7         : '※名義人以外の方は名義人様の委任状が必要となります ',
  GUIDE_SELL_STEP2_TEXT8         : 'バイクの写真',
  GUIDE_SELL_STEP2_TEXT9         : '※指定した部位が撮影された写真、15枚程度',
  GUIDE_SELL_STEP2_TEXT10        : '査定',
  GUIDE_SELL_STEP2_TEXT11        : '査定チームの確認後、不備や質問があればチャットからご連絡させていただきます。',
  GUIDE_SELL_STEP2_TEXT12        : '全て確認できた状態から５営業日以内に査定額を決定致します。',
  GUIDE_SELL_STEP3_TITLE1        : '査定額決定',
  GUIDE_SELL_STEP3_TEXT1         : '手数料、配送料',
  GUIDE_SELL_STEP3_TEXT2         : '全てライマが負担します！査定額から差し引くものは一切ありません！',
  GUIDE_SELL_STEP3_TEXT3         : 'ここまで全てオンラインで完結！',
  GUIDE_SELL_STEP3_TEXT4         : '来店する必要も担当者による訪問もありません。',
  GUIDE_SELL_STEP3_TEXT5         : '査定が完了したら・・・',
  GUIDE_SELL_TAB1_TITLE1         : 'そのままライマに',
  GUIDE_SELL_TAB1_TITLE2         : '買取依頼する',
  GUIDE_SELL_TAB1_H4_1           : '必要書類',
  GUIDE_SELL_TAB1_H4_1__2        : 'の送付',
  GUIDE_SELL_TAB1_TEXT1          : '契約書などの書類がライマより届きます。ご確認、ご捺印のうえご返送ください。',
  GUIDE_SELL_TAB1_H4_2           : 'ライマよりバイク代金をお支払い',
  GUIDE_SELL_TAB1_TEXT2          : '書類のご返送確認後すぐに銀行振込にてお支払いします。',
  GUIDE_SELL_TAB1_H4_3__1        : '配送 ',
  GUIDE_SELL_TAB1_H4_3__2        : 'の手配もライマがすべて行います！',
  GUIDE_SELL_TAB1_TEXT3          : 'ご依頼者様は配送業者へお受渡しいただくだけでOK。',
  GUIDE_SELL_TAB1_H4_4           : '名義変更完了',
  GUIDE_SELL_TAB1_TEXT4          : '車両到着後、名義変更まで完了したらチャットにてお知らせ致します。',
  GUIDE_SELL_TAB2_TITLE1         : 'オークションに',
  GUIDE_SELL_TAB2_TITLE2         : '出品する',
  GUIDE_SELL_TAB2_TEXT1          : '売れないリスクゼロ！業界初の必ず売れるオークション',
  GUIDE_SELL_TAB2_TEXT2          : '落札されなかった場合でも査定額そのままライマが買い取り！',

  OTHER_AUCTIONTERMS_H2                 : 'ライダーズマーケットオークション利用規約',
  OTHER_AUCTIONTERMS_SUBTITLE           : '査定・オークション規定',
  OTHER_AUCTIONTERMS_DT1                : '1. 査定',
  OTHER_AUCTIONTERMS_DD1_OL1            : '1） 当社は出品車両について，お客様の申告内容 に基づき出品車両を検査し，当社所定の方法による検査評価基準により評価します。',
  OTHER_AUCTIONTERMS_DD1_OL2            : '2） 前項の検査は，査定額の決定を目的としており，出品車両の品質を保証するものではありません。',
  OTHER_AUCTIONTERMS_DD1_OL3            : '3） お客様は，本条第 1 項に基づき，当社が行った検査の内容および査定額を参 考とすることはできますが，検査の内容または評価の相違について，当社に責めを求めることはできません。',
  OTHER_AUCTIONTERMS_DT2                : '2. 査定可能車両基準',
  OTHER_AUCTIONTERMS_DD2                : '査定可能車両は以下の基準に適合したもののみといたします。ただし，当社が認めた車両についてはこの限りではありません。',
  OTHER_AUCTIONTERMS_DD2_OL1            : '1） 所有者の意思に反して査定依頼された車両でないこと。',
  OTHER_AUCTIONTERMS_DD2_OL2            : '2） 車台番号等が不正に打刻された車両でないこと。',
  OTHER_AUCTIONTERMS_DD2_OL3            : '3） 車台番号等が識別困難な車両でないこと。',
  OTHER_AUCTIONTERMS_DD2_OL4            : '4） その他不正であることが強く疑われる車両でないこと。',
  OTHER_AUCTIONTERMS_DD2__2             : '当社は，前項にかかわらず，諸規程をもって，車両の種類，品質に応 じたオークションを開設することができます。',
  OTHER_AUCTIONTERMS_DT3                : '3. みなしメーター減算車',
  OTHER_AUCTIONTERMS_DD3                : '依頼車両について，当該車両のスピードメーターの取付状態から実走行距 離が表示されていないと当社が判断した場合は，お客様の了解なしにメーター減算車とすることができるものとします。ただし，お客様がメーター減算 車でないことを証明した場合はこの限りではありません。',
  OTHER_AUCTIONTERMS_DT4                : '4. 買取',
  OTHER_AUCTIONTERMS_DD4_OL1            : '1） 本条第1項に基づき査定した査定内容は決定日より2週間保証いたします。',
  OTHER_AUCTIONTERMS_DD4_OL2            : '2） 買取依頼を選択した時点で買取成立となります。',
  OTHER_AUCTIONTERMS_DD4_OL3            : '3） 成立後、お客様は当社が指定した書類、契約、配送に関して滞りなく対応しなくてはいけません。',
  OTHER_AUCTIONTERMS_DD4_OL4            : '4） 車両の到着後、査定時のお客様による申告と相違があった場合、当社が悪質なものと認めた場合は買取の取消、または査定額の一部返金を求めることができます。',
  OTHER_AUCTIONTERMS_DT5                : '5. 出品申込みと誠実義務',
  OTHER_AUCTIONTERMS_DD5_OL1            : '1） 出品できる車両は当社が査定した車両のみ、査定内容に変更などがないものに限ります。 ',
  OTHER_AUCTIONTERMS_DD5_OL2            : '2） 出品者は，諸規程に従い，出品車両の車種，車名，車歴，仕様，品質，瑕疵の程度等、必要事項を正確かつ誠実に当社、及び入札意思のある方へ申告しなければいけません。',
  OTHER_AUCTIONTERMS_DD5_OL3            : '3） 出品者は，虚偽の記入，申告漏れ，誤記入その他申込みによって生ずる全ての問題について責任を負わなければなりません。',
  OTHER_AUCTIONTERMS_DD5_OL4            : '4） 出品者は出品車両の出品車情報に誤りが無いか確認する義務があります。',
  OTHER_AUCTIONTERMS_DT6                : '6. ライマ出品代行サービスについて',
  OTHER_AUCTIONTERMS_DD6_OL1            : '1） 本条第５項の車両はオークションの一切を任せることができるライマ出品代行サービス（以下「出品代行」といいます）を利用することができます。',
  OTHER_AUCTIONTERMS_DD6_OL2            : '2） このサービスは出品代行の出品手数料、成約手数料が適応されます。',
  OTHER_AUCTIONTERMS_DD6_OL3            : '3） 車両が当社へ到着した日から30日間お預かりし、最低2回の適切なオークションへ出品されます。',
  OTHER_AUCTIONTERMS_DD6_OL4            : '4） 流札された場合そのまま当社が買取、もしくはお客様は車両の往復送料をお支払いいただくことにより車両をお返しすることができます。',
  OTHER_AUCTIONTERMS_DD6_OL5            : '5） 流札された場合で、2週間以上お客様からの連絡が滞った場合は自動的に買取成立となります。',
  OTHER_AUCTIONTERMS_DT7                : '7. 車両損害等',
  OTHER_AUCTIONTERMS_DD7_OL1            : '1） 天災，地変その他の当社の責に帰すことのできない事由によって，当社保管の車両に，故障その他の損害が生じた場合，当社は一切責任を 負わないものとします。',
  OTHER_AUCTIONTERMS_DD7_OL2            : '2） 保管場所において，当社の責に帰すことのできない事由によって事故が発生し，これにより車両の損害が生じた場合も前項と同様とします。',
  OTHER_AUCTIONTERMS_DD7_OL3            : '3） 車両保管場所において，車両の盗難事故が発生した場合，オークション前および流札車両については相場価格，成約後の車両については落札価格をその損害の限度額とします。',
  OTHER_AUCTIONTERMS_DD7_OL4            : '4） 盗難による部品損害については，標準装備品および装備が出品票に明記されたものに限り損害として認められるものとし，中古部品時価相当額をもって損害の限度額とする。',
  OTHER_AUCTIONTERMS_DT8                : '8. 成約について',
  OTHER_AUCTIONTERMS_DD8                : '当オークションシステム上で落札された時点で成約となります。',
  OTHER_AUCTIONTERMS_DT9                : '9. 出品手数料、成約手数料',
  OTHER_AUCTIONTERMS_DD9_OL1            : '1） お客様は，出品に際して当社に対して出品手数料をお支払いいただきます。',
  OTHER_AUCTIONTERMS_DD9_OL2            : '2） お客様は，出品した車両が落札された場合のみ，当社に対して成約手数料をお支払いいただきます。',
  OTHER_AUCTIONTERMS_DD9_OL3            : '3） 手数料の額については，当社が別途定めるものとします。',
  OTHER_AUCTIONTERMS_DT10               : '10. 出品車両の取消、キャンセル、クレームについて',
  OTHER_AUCTIONTERMS_DD10_OL1           : '1） 一度出品された車両で、まだ入札が入っていない車両のみ出品を取り消すことができます。ただしこの場合の出品手数料の払い戻しはいたしません。',
  OTHER_AUCTIONTERMS_DD10_OL2           : '2） 最低落札価格を越えていない入札であっても、入札が入っている車両は出品の取り消しはできません。流札されるのを待つか、落札された場合は所定のペナルティを支払うことでキャンセルできます。',
  OTHER_AUCTIONTERMS_DD10_OL3           : '3） 成約後に関しても同様キャンセルはできません。キャンセルしたい場合、落札金額の20％もしくは20,000円のうち多い方をペナルティとして請求いたします。(ペナルティは半分キャンセル事務手数料として当社、もう半分は落札者へお支払いします。)',
  OTHER_AUCTIONTERMS_DD10_OL4           : '4） 落札者からの連絡が2週間以上滞った場合は代金の支払い前に限りキャンセルすることができます。この場合は当社事務局にて対応いたします。',
  OTHER_AUCTIONTERMS_DD10_OL5           : '5） 出品者都合でのキャンセルによりトラブルが発生した場合、当社は責任を負いません。',
  OTHER_AUCTIONTERMS_DD10_OL6           : '6） クレーム規定に基づいた申し出があった場合はこの限りではありません。出品者はクレーム規定に沿った対応をする義務を負います。',
  OTHER_AUCTIONTERMS_DT11               : '11. 出品者の禁止行為',
  OTHER_AUCTIONTERMS_DD11               : '出品者による以下の行為は禁止します。',
  OTHER_AUCTIONTERMS_DD11_OL1           : '1） 販売する意思がないにもかかわらず出品すること',
  OTHER_AUCTIONTERMS_DD11_OL2           : '2） 同じ商品を、他社のサービスやその他の方法によって、二重に出品すること',
  OTHER_AUCTIONTERMS_DD11_OL3           : '3） 同じ車両を別のアカウントを使って同時に出品すること',
  OTHER_AUCTIONTERMS_DD11_OL4           : '4） 商品の現物が手元にない状態で出品すること',
  OTHER_AUCTIONTERMS_DD11_OL5           : '5） 落札者の入金を商品調達に充当することを前提に出品すること',
  OTHER_AUCTIONTERMS_DD11_OL6           : '6） 商品説明やチャットルームおよびその他の連絡手段で、表示価格より高い金額で取引を行う旨を連絡すること',
  OTHER_AUCTIONTERMS_DD11_OL7           : '7） 入札者に誤解を与えるような表示をすること',
  OTHER_AUCTIONTERMS_DD11_OL8           : '8） 事実と異なる表示をすること',
  OTHER_AUCTIONTERMS_DD11_OL9           : '9） 出品画面上で商品説明を十分にしないこと',
  OTHER_AUCTIONTERMS_DD11_OL10          : '10） 商品説明文を画像にして掲載すること',
  OTHER_AUCTIONTERMS_DD11_OL11          : '11） 出品物以外の商品やサービスなどを、内容についての説明が不十分なまま「おまけ」などとして付加すること',
  OTHER_AUCTIONTERMS_WINNING_A_TENDER   : '） 落札',
  OTHER_AUCTIONTERMS_DD11_OL12          : '12） 出品物と直接関係のない画像や単語を商品タイトルや商品説明に掲載すること',
  OTHER_AUCTIONTERMS_DD11_OL13          : '13） 商品等の広告を目的として出品すること',
  OTHER_AUCTIONTERMS_DD11_OL14          : '14） 広告を掲載したり、リンクをはったりすること',
  OTHER_AUCTIONTERMS_DD11_OL15          : '15） 特約事項等を商品ページ以外に記載すること',
  OTHER_AUCTIONTERMS_DD11_OL16          : '16） 自身の出品したオークションに入札行為を行うこと。なお、入札の成否を問いません。',
  OTHER_AUCTIONTERMS_DD11_OL17          : '17） 進行中のほかのオークションを妨害すること',
  OTHER_AUCTIONTERMS_DD11_OL18          : '18） 落札者の依頼もなく、商品代金以外を請求すること',
  OTHER_AUCTIONTERMS_DD11_OL19          : '19） 代金の支払いに車両の名義人または委任者以外の振込み口座を指定すること',
  OTHER_AUCTIONTERMS_DD11_OL20          : '20） 電子決済サービスの決済用バーコードやQRコードなどを掲載すること',
  OTHER_AUCTIONTERMS_DD11_OL21          : '21） 自動的に出品するツールや、それに類するプログラムの利用、またはそれらに類する行為によって出品すること（当社が特に認めた場合を除きます）',
  OTHER_AUCTIONTERMS_DD11_OL22          : '22） 本サービスの提供する目的から逸脱した行為',
  OTHER_AUCTIONTERMS_DD11_OL23          : '23） 本サービスの提供する落札システムを利用しない取引を誘引すること',
  OTHER_AUCTIONTERMS_DD11_OL24          : '24） 発送元の地域を正しく設定しないこと',
  OTHER_AUCTIONTERMS_DD11_OL25          : '25） 落札者が設定した配送方法に沿った手続きに対応しないこと。支払い後2週間以内に発送すること(配送業者の状況によってこれに限りません)',
  OTHER_AUCTIONTERMS_DD11_OL26          : '26） 入札者(落札者)を特定の個人に限定した出品をすること',
  OTHER_AUCTIONTERMS_DD11_OL27          : '27） 出品者情報、会員登録の情報が最新のものでない状態で出品すること',
  OTHER_AUCTIONTERMS_DD11_OL28          : '28） 当社のサーバーまたはネットワークに過度な負荷をかけると当社が合理的に判断する行為',
  OTHER_AUCTIONTERMS_DD11_OL29          : '29） 出品物の違法な利用方法を示唆または助長する商品説明を行うこと',
  OTHER_AUCTIONTERMS_DD11_OL30          : '30） その他、当社独自の判断で不適当とみなす行為、また当オークションの運営方針に外れるとみなす行為',
  OTHER_AUCTIONTERMS_DT12               : '12. 落札された場合',
  OTHER_AUCTIONTERMS_DD12               : '車両が落札された出品者は車両の名義が変更されるまで落札者とのやりとり、売買に関する必要事項を責任を持って遂行する義務があります。',
  OTHER_AUCTIONTERMS_FLOW               : 'ご利用の流れ',
  OTHER_AUCTIONTERMS_DD12_OL1           : '1）',
  OTHER_AUCTIONTERMS_DD12_OL1__2        : 'に沿った対応を行ってください。',
  OTHER_AUCTIONTERMS_DD12_OL2           : '2） 出品者と落札者間の車両代金の支払い方法ややりとりに関して、当社は一切関与しません。問題が起きた場合、当社は責任を負いません。ただし出品者が第三者機関へ問題解決を依頼した場合、解決にむけて協力対応を迅速に行います。',
  OTHER_AUCTIONTERMS_DD12_OL3           : '3） 出品者が期限までに書類規定に定める譲渡書類の全部または一部の引渡しを怠った場合，当該出品者は，諸規定定めるペナルティを落札者に支払わなければ いけません。',
  OTHER_AUCTIONTERMS_DT13               : '13. 入札やご利用についての禁止行為および注意事項',
  OTHER_AUCTIONTERMS_DD13               : '以下の行為を禁止します。',
  OTHER_AUCTIONTERMS_DD13_OL1           : '1） 購入する意思がないにもかかわらず入札、落札すること',
  OTHER_AUCTIONTERMS_DD13_OL1__2        : '落札後、出品者に速やかに連絡を行わない、出品者からの連絡を無視するなども、購入する意思のない入札とみなしますのでご注意ください。',
  OTHER_AUCTIONTERMS_DD13_OL2           : '2） 出品者の禁止行為に抵触する行為を要求、誘引すること',
  OTHER_AUCTIONTERMS_DD13_OL3           : '3） 出品車両以外の出品を要求、誘引すること',
  OTHER_AUCTIONTERMS_DD13_OL4           : '4） 出品ルールに抵触する行為を要求、誘引すること',
  OTHER_AUCTIONTERMS_DD13_OL5           : '5） 当社が指定する決済手段以外の取引を行うこと、また誘引すること',
  OTHER_AUCTIONTERMS_DD13_OL6           : '6） 当社のサーバーまたはネットワークに過度な負荷をかけると当社が合理的に判断する行為',
  OTHER_AUCTIONTERMS_DD13_OL7           : '7） 入札の成否を問わず、1つの商品に対して複数の会員登録を用いて入札を行ったと当社が合理的に判断する行為',
  OTHER_AUCTIONTERMS_DD13_OL8           : '8） 入札の取消はできません',
  OTHER_AUCTIONTERMS_DT14               : '14. 落札',
  OTHER_AUCTIONTERMS_DD14_OL1           : '1） 次条以下の定めるところに従い，オークションに参加して車両を落札することができます。車両の落札手続については，本規則に定めるもののほか，諸規程をもって定めるものとします。',
  OTHER_AUCTIONTERMS_DD14_OL2           : '2） ',
  OTHER_AUCTIONTERMS_DD14_OL2__2        : 'に沿った対応を行ってください。',
  OTHER_AUCTIONTERMS_DD14_OL3           : '3） 当社が落札を認めたときにオークション取引が成約したものとします。この場合、成約車両に関する売買契約は，出品者と落札者の間に成立します。',
  OTHER_AUCTIONTERMS_DD14_OL4           : '4） 出品者と落札者間の車両代金の支払い方法ややりとりに関して、当社は一切関与しません。問題が起きた場合、当社は責任を負いません。ただし出品者が第三者機関へ問題解決を依頼した場合、解決にむけて協力対応を迅速に行います。',
  OTHER_AUCTIONTERMS_DT15               : '15. 落札手数料',
  OTHER_AUCTIONTERMS_DD15_OL1           : '1） 車両を落札したときは，当社に対して落札手数料をお支払いいただきます。',
  OTHER_AUCTIONTERMS_DD15_OL2           : '2） 手数料の額については，当社が別途定めるものとします。',
  OTHER_AUCTIONTERMS_DT16               : '16. 落札した車両の配送',
  OTHER_AUCTIONTERMS_DD16_OL1           : '1） 配送の手配に関しては落札者が責任を有します。',
  OTHER_AUCTIONTERMS_DD16_OL2           : '2） ライマ配送手配以外で配送を選択された場合、出品者に指定の場所まで車両の移動や配送を依頼すことはできません。必ず出品者指定の場所から配送手配をします。',
  OTHER_AUCTIONTERMS_DD16_OL3           : '3） 出品者から配送業者への車両の受渡しは、車両代金の支払い確認後に限ります。落札者自身が直接引取に行く場合も同様とします。',
  OTHER_AUCTIONTERMS_DD16_OL4           : '4） 配送手配は落札後30日以内の引渡しとします。それを超えた場合、所定の保管料がかかる場合があります。（ライマ出品車両で落札日から30日を超えた場合1日1台につき1000円の保管料をいただきます。）',
  OTHER_AUCTIONTERMS_DT17               : '17. 車両代金の決済',
  OTHER_AUCTIONTERMS_DD17_OL1           : '1） 落札者は、落札車両代金を出品者より振込み先の連絡があった日から7日以内に支払わなくてはならないとする。ただし、支払期日の最終日が銀行の休日に該当する場合は、その前日をもって期日の最終日とします。',
  OTHER_AUCTIONTERMS_DD17_OL2           : '2） 落札車両の所有権は、落札者が出品者に対して、落札車両代金等を支払ったときに落札店に移転します。',
  OTHER_AUCTIONTERMS_DD17_OL3           : '3） 出品者と落札者間の車両代金の支払い方法ややりとりに関して、当社は一切関与しません。問題が起きた場合、当社は責任を負いません。ただし出品者が第三者機関へ問題解決を依頼した場合、解決にむけて協力対応を迅速に行います。',
  OTHER_AUCTIONTERMS_DD17_OL4           : '4） 出品者から振込み先の連絡を受け取ってから2週間以上代金の支払いが滞った場合はキャンセルとみなされる場合があります。',
  OTHER_AUCTIONTERMS_DT18               : '18. 落札者の車両確認義務',
  OTHER_AUCTIONTERMS_DD18               : '落札者は車両の出品時の記載内容の確認と、出品車両の十分な確認を行い、さらに車両到着日より3日以内に当該車両と出品内容との相違がないことを再度確認しなければいけません。',
  OTHER_AUCTIONTERMS_DT19               : '19. 落札の取消、キャンセル、クレームについて',
  OTHER_AUCTIONTERMS_DD19_OL1           : '1） 落札者都合のキャンセルは落札金額の20％もしくは20,000円のうち多い方をペナルティとして請求いたします。（ペナルティは半分キャンセル事務手数料として当社、もう半分は出品者へお支払いします。）',
  OTHER_AUCTIONTERMS_DD19_OL2           : '2） 出品者からの連絡が2週間以上滞った場合はキャンセルすることができます。この場合は当社事務局にて対応いたします。',
  OTHER_AUCTIONTERMS_DD19_OL3           : '3） 落札者都合でのキャンセルによりトラブルが発生した場合、当社は責任を負いません。',
  OTHER_AUCTIONTERMS_DD19_OL4           : '4） クレーム規定に基づいた申し出をされたい場合はこの限りではありません。',
  OTHER_AUCTIONTERMS_DT20               : '20. 振込手数料および送付費用の負担',
  OTHER_AUCTIONTERMS_DD20_OL1           : '1） 基本的に送金者が振込み手数料を負担する。ただし、キャンセルやクレーム返金の場合は出品者負担とする。',
  OTHER_AUCTIONTERMS_DD20_OL2           : '2） 書類の送付は、落札者が車両の配送とは別で希望する場合は落札者が負担する。その他に生じた送付費用は落札者都合で送付が必要となった場合は落札者、出品者都合で送付が必要な場合は出品者が負担する。',
  OTHER_AUCTIONTERMS_DT21               : '21. 車両の譲渡書類',
  OTHER_AUCTIONTERMS_DD21_OL1           : '1） 出品者は，成約車両に関する書類規程に定める譲渡書類（以下「譲渡書類」 といいます。）を全て準備し、落札者へ車両が到着する日までに引き渡す義務があります。',
  OTHER_AUCTIONTERMS_DD21_OL2           : '2） 落札者は、譲渡書類を受け取った後速やかに名義変更を行い、決して紛失してはいけません。',
  OTHER_AUCTIONTERMS_DT22               : '22. 落札車両の登録',
  OTHER_AUCTIONTERMS_DD22_OL1           : '1） 落札者は、譲渡書類を受領した後20日以内に登録を完了しなければいけません。ただし、返納証明書の場合は除きます。',
  OTHER_AUCTIONTERMS_DD22_OL2           : '2） 落札者は，前項の登録を完了した後，期限内にその旨をチャットルームにて通知しなければいけません。',
  OTHER_AUCTIONTERMS_DT23               : '23. 譲渡書類の不備',
  OTHER_AUCTIONTERMS_DD23               : '次の各号の一つに該当する車両を出品しようとする場合は、当該車両については、抹消登録（返納）した上で譲渡書類を提出するものとします。',
  OTHER_AUCTIONTERMS_DD23_OL1           : '1） 当該車両の名義人が一般の支払を停止する等倒産状態にある場合',
  OTHER_AUCTIONTERMS_DD23_OL2           : '2） 差し替えまたは再交付が困難と思われる譲渡書類',
  OTHER_AUCTIONTERMS_DD23_OL3           : '3） 名義人が死亡している車両',
  OTHER_AUCTIONTERMS_DT24               : '24. 譲渡書類遅延のペナルティ',
  OTHER_AUCTIONTERMS_DD24               : '出品者が譲渡書類の全部または一部の引渡しを怠った場合，落札者は出品者へキャンセル、または返品することができます。その際にかかる配送料やキャンセル料など全てを出品者が負担しなければなりません。',
  OTHER_AUCTIONTERMS_DT25               : '25. 名義変更遅延ペナルティ',
  OTHER_AUCTIONTERMS_DD25               : '落札者が、出品者へ連絡もなく期日までの車両の登録の完了および出品者に対する通知を怠った場合、出品者は落札者へキャンセルまたは返品を請求することができます。その際にかかる配送料やキャンセル料など全てを落札者が負担しなければなりません。',
  OTHER_AUCTIONTERMS_DT26               : '26. 譲渡書類紛失または失効のペナルティ',
  OTHER_AUCTIONTERMS_DD26               : '落札者は、落札車両について引渡された譲渡書類の全部または一部を紛失し、名義変更が間に合わず、出品者へ車両税の負担があった場合は、出品者が落札者へ負担分を請求することができます。',
  OTHER_AUCTIONTERMS_DD26__2            : '2回以上の再交付において、何度も紛失する、など問題が見られた場合は出品者は別途手数料として20,000円を請求することができます。',
  OTHER_AUCTIONTERMS_DT27               : '27. クレーム規定',
  OTHER_AUCTIONTERMS_DD27_OL1           : '1） 出品者および落札者は，オークションにおいて発生する紛争について、理解と協力をもってこれにあたり、紛争を円満に解決するよう努めるものとする。',
  OTHER_AUCTIONTERMS_DD27_OL2           : '2） 出品者は、車両の出品をするに際して、クレームの発生を事前に防止するよう努めなければならない。',
  OTHER_AUCTIONTERMS_DD27_OL3           : '3） 出品者は、出品車両の仕様、品質、瑕疵の程度等必要事項を誠実に申告しなければならない。',
  OTHER_AUCTIONTERMS_DD27_OL4           : '4） 落札者は、出品車両を落札する場合、十分な下見をして落札し、さらに落札後も車両到着3日以内に当該車両とオークション出品票との相違がないことを再度確認しなければならない。',
  OTHER_AUCTIONTERMS_DD27_OL5           : '5） クレームの申立は、車両1台について1回のみとする。ただし、当社が認めたものについてはこの限りではない。',
  OTHER_AUCTIONTERMS_DD27_OL6           : '6） メーカーの保証で対応できるクレームについては、メーカーに対して行うものとする。',
  OTHER_AUCTIONTERMS_DT28               : '28. クレーム免責',
  OTHER_AUCTIONTERMS_DD28               : 'クレームが以下に該当する場合、売買契約の解除、代金減額請求は認めないものとします。ただし、当社が免責不適当と判断した場合はこの限りではありません。',
  OTHER_AUCTIONTERMS_DD28_OL1           : '1） 出品者が故意に隠していたわけではなく、事前に質問をしていれば判り得る内容についてのクレーム',
  OTHER_AUCTIONTERMS_DD28_OL2           : '2） 年式相応の経年劣化や中古車として常識的な修理や瑕疵の場合',
  OTHER_AUCTIONTERMS_DD28_OL3           : '3） 落札代金額3万円未満の車両の場合',
  OTHER_AUCTIONTERMS_DD28_OL4           : '4） 修理部品、加修部品が1万円未満の場合',
  OTHER_AUCTIONTERMS_DD28_OL5           : '5） 工賃の請求',
  OTHER_AUCTIONTERMS_DD28_OL6           : '6） 落札車両に修理、加工、部品交換を施した場合',
  OTHER_AUCTIONTERMS_DD28_OL7           : '7） 改造車両の改造箇所に関する申告',
  OTHER_AUCTIONTERMS_DD28_OL8           : '8） キットバイクに関する申告',
  OTHER_AUCTIONTERMS_DD28_OL9           : '9） 自走にて搬出後の申告',
  OTHER_AUCTIONTERMS_DD28_OL10          : '10） 第三者に売却または引き渡した場合',
  OTHER_AUCTIONTERMS_DD28_OL11          : '11） 落札者が落札車両の不具合を出品者の確認をとることなく加修したとき',
  OTHER_AUCTIONTERMS_DD28_OL12          : '12） 落札車両の所有者または使用者名義、もしくは登録名義を変更した場合',
  OTHER_AUCTIONTERMS_DD28_OL13          : '13） 落札車両を国外へ輸出した場合。ただし、法的問題に抵触した場合は合法的措置を執る場合がある。',
  OTHER_AUCTIONTERMS_DD28_OL14          : '14） 出品者が出品時に不明と記載した箇所にあたる問題',
  OTHER_AUCTIONTERMS_DT29               : '29. あっせん・仲裁',
  OTHER_AUCTIONTERMS_DD29_OL1           : '1） 契約の解除または代金減額請求について売買当事者間に調整がつかない場合、もしくは特殊事情により例外処理を必要とする場合、当社は公平、中立の立場であっせんをするものとし、売買当事者はその裁定結果に無条件で従うものとします。また、売買当事者双方からの申し立てがあるときは、当社は仲裁を行うものとします。',
  OTHER_AUCTIONTERMS_DD29_OL2           : '2） 売買当事者が前項の裁定結果に従わない場合は、除名またはオークションへの参加停止等の処分を科す場合があります。',
  OTHER_AUCTIONTERMS_DT30               : '30. 重大クレーム',
  OTHER_AUCTIONTERMS_DD30               : '以下の問題に対してキャンセルとなった場合、車両代金の返金以外に、往復配送料、落札者が負担した手数料、その他該当車両に発生した諸経費を損害賠償請求されます。',
  OTHER_AUCTIONTERMS_DD30_OL1           : '1） 譲渡書類の全部または一部の引渡遅延日数が15日を経過した場合',
  OTHER_AUCTIONTERMS_DD30_OL2           : '2） 盗難及び遺失車輌等、法的問題車両',
  OTHER_AUCTIONTERMS_DD30_OL3           : '3） 車台番号の不正打刻発覚',
  OTHER_AUCTIONTERMS_DD30_OL4           : '4） 下見をしてもわからないメーター減算発覚',
  OTHER_AUCTIONTERMS_DD30_OL5           : '5） 担保設定等があるため所有権移転ができない場合、出品者が担保等について即時解除できないとき',
  OTHER_AUCTIONTERMS_DT31               : '31. 名義変更前の違反',
  OTHER_AUCTIONTERMS_DD31               : '移転登録完了前に交通違反等により出品者に迷惑が及んだ場合、落札者はペナルティとして20,000円を出品店に支払うものとします。尚、度重なる違反により迷惑を及ぼした場合には、取引停止または強制退会の措置を取るものとします。',
  OTHER_AUCTIONTERMS_BYLAWS             : '付則',
  OTHER_AUCTIONTERMS_ENFORCING          : '施行',
  OTHER_AUCTIONTERMS_ENFORCING_DATE     : '2023年11月1日施行',

  OTHER_TOKUSHO_TITLE       : '特定商取引法の表示',
  OTHER_TOKUSHO_TH1         : '当サイトがご提供する内容',
  OTHER_TOKUSHO_TD1         : 'お客様の車両(自動二輪車)の査定、買取をします。<br />自社商品の車両を含む商品を競り落としたり、出品したり、お客様同士の取引の機会を提供します。',
  OTHER_TOKUSHO_TH2         : 'ご利用・販売価格',
  OTHER_TOKUSHO_TD2_UL1_LI  : '・商品代金',
  OTHER_TOKUSHO_TD2_2_1     : '販売価格（車両価格）の決済はシステム外で販売者、購入者間で直接行います。金額については商品詳細ページをご参照下さい。',
  OTHER_TOKUSHO_TD2_2_2     : 'なお、販売元が自社である商品は別途消費税が加算されます。',
  OTHER_TOKUSHO_TD2_2_3     : '当サイト内で決済が行われるのは以下のシステムご利用料金です。',
  OTHER_TOKUSHO_TD2_UL2_LI1 : '・出品手数料',
  OTHER_TOKUSHO_TD2_UL2_LI2 : '・出品代行手数料',
  OTHER_TOKUSHO_TD2_UL2_LI3 : '・落札手数料',
  OTHER_TOKUSHO_TD2_3       : '手数料については',
  OTHER_TOKUSHO_TD2_3_A     : 'こちら',
  OTHER_TOKUSHO_TD2_3_A2    : 'をご参照ください。',
  OTHER_TOKUSHO_TH3         : '配送料',
  OTHER_TOKUSHO_TD3         : 'システム外（銀行振込など）で別途ご請求致します。<br />排気量・配送エリア・車種によって異なります。 ※離島、一部特殊車両は別途お問い合わせ下さい。',
  OTHER_TOKUSHO_TH4         : 'ご利用条件',
  OTHER_TOKUSHO_TD4         : '日本国内に居住される方で満20歳以上の方。未成年については、（保護者等）法定代理人の同意があれば出品、購入可能。',
  OTHER_TOKUSHO_TH5         : '提供時期',
  OTHER_TOKUSHO_TD5         : '<span class="bold">査定：</span>当該手続き完了次第、直ちにご利用いただけます。<br /><span class="bold">出品：</span>出品手続き完了後、直ちにご利用いただけます。<br /><span class="bold">入落札：</span>入落札手続き完了後、直ちにご利用いただけます。',
  OTHER_TOKUSHO_TH6         : 'お支払時期',
  OTHER_TOKUSHO_TD6         : '売買成立より7日以内',
  OTHER_TOKUSHO_TH7         : 'お支払方法',
  OTHER_TOKUSHO_TD7_LI_1    : '・銀行振込',
  OTHER_TOKUSHO_TD7_LI_2    : '・クレジットカード（手数料のみ対応。個人間の売買では使えません）',
  OTHER_TOKUSHO_TH8         : '商品引渡時期',
  OTHER_TOKUSHO_TD8_1       : '取引に関わる全てのお支払いが完了したことと、取引に関わる全ての書類が揃ってあることが確認出来次第配送手配します。',
  OTHER_TOKUSHO_TD8_2       : '※災害、感染症の流行などにより、予告なくお届け期間、お届け方法が変更になる場合やお届けができない場合がございます。',
  OTHER_TOKUSHO_TH9         : '返品・キャンセル',
  OTHER_TOKUSHO_TD9_1       : '購入確定後のお客様都合によるキャンセルは原則として応じかねますので、あらかじめご了承願います。',
  OTHER_TOKUSHO_TD9_2       : 'お客様都合による返品には原則として応じかねますが、クレーム規定に基づいた申し出をされたい場合はこの限りではありません。',
  OTHER_TOKUSHO_TD9_3_1     : '詳しくは',
  OTHER_TOKUSHO_TD9_3_2     : '査定・オークション規定',
  OTHER_TOKUSHO_TD9_3_3     : 'をご参照ください。',
  OTHER_TOKUSHO_TH10        : '提供・販売事業者',
  OTHER_TOKUSHO_TD10        : '株式会社UEJ OBMバイク事業部',
  OTHER_TOKUSHO_TH11        : '責任者',
  OTHER_TOKUSHO_TD11        : 'アトキンス真希子',
  OTHER_TOKUSHO_TH12        : '事業所所在地',
  OTHER_TOKUSHO_TD12        : '〒824-0028 <br class="only_sp" />福岡県行橋市辻垣123-3',
  OTHER_TOKUSHO_TH13        : '電話/FAX',
  OTHER_TOKUSHO_TD13_TEL    : '電話 : 0930-26-2266',
  OTHER_TOKUSHO_TD13_FAX    : 'FAX : 0930-26-2267',
  OTHER_TOKUSHO_TD13_TXT1   : '※こちらの電話番号では、お問い合わせ方法のご案内を承っています。',
  OTHER_TOKUSHO_TD13_TXT2   : '個別の取引状況を踏まえたお問い合わせに関しては、お客様の個人情報保護のため、ご登録のメールアドレスから下記の問い合わせ先へご連絡をお願い致します。',
  OTHER_TOKUSHO_TH14        : '問い合わせ先',
  OTHER_TOKUSHO_TD14        : 'raima_support@obm.jp',
  OTHER_TOKUSHO_TH15        : '古物商許可番号',
  OTHER_TOKUSHO_TD15        : '古物商第902091510030',

}
