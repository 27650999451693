<template>
  <footer>
    <nav>
      <!-- PC fNav -->
      <div class="fNav_pc only_pc wrap1160">
        <ul class="fNav-2">
          <li>
            <p>{{ $t("PAGE_PURCHASE_OF_CONSTRUCTION_EQUIPMENT") }}</p>
            <ul>
              <li>
                <a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)">{{
                  $t("PAGE_AUCTION")
                }}</a>
              </li>
            </ul>
          </li>
          <li>
            <p>{{ $t("PAGE_MEMBER_LOGIN") }}</p>
            <ul>
              <li>
                <a v-if="!logined"
                  @click="
                    goto($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)
                  "
                  >{{ $t("PAGE_NEW_MEMBER_REGISTRATION") }}</a
                >
              </li>
              <li v-if="!logined">
                <a @click="goto($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{
                  $t("PAGE_LOGIN")
                }}</a>
              </li>
              <li v-if="logined">
                <a @click="goto($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{
                  $t("PAGE_MY_PAGE")
                }}</a>
              </li>
              <li v-if="logined">
                <a @click="logout">{{
                  $t("HEADER_LOGOUT_BUTTON_LABEL")
                }}</a>
              </li>
            </ul>
          </li>
          <li>
            <p>{{ $t("PAGE_USERS_GUIDE") }}</p>
            <ul>
              <li>
                <a @click="goto($define.PAGE_NAMES.GUIDE_AUCTION_SELL_PAGE_NAME)">{{
                  $t("PAGE_SELL_MOTORCYCLE")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.GUIDE_AUCTION_BUY_PAGE_NAME)">{{
                  $t("PAGE_BUY_MOTORCYCLE")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.GUIDE_AUCTION_PAGE_NAME)">{{
                  $t("PAGE_ABOUT_AUCTION_LISTING")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.GUIDE_AUCTION_AGENCY_PAGE_NAME)">{{
                  $t("PAGE_ABOUT_AUCTION_LISTING_SERVICE")
                }}</a>
              </li>
            </ul>
          </li>
          <li>
            <p>{{ $t("PAGE_OTHER_GUIDANCE") }}</p>
            <ul>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{
                  $t("PAGE_DELIVERY")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_TERMS_PAGE_NAME)">{{
                  $t("PAGE_TERMS")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME)">{{
                  $t("PAGE_AUCTION_TERMS")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{
                  $t("PAGE_VARIOUS_COMMISSIONS")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_TOKUSHO_PAGE_NAME)">{{
                  $t("PAGE_TOKUSHO")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_FAQ_SUPPORT_PAGE_NAME)">{{
                  $t("PAGE_FAQ_SUPPORT")
                }}</a>
              </li>
            </ul>
          </li>
          <li>
            <p>{{ $t("LINK_COMPANY_INFO") }}</p>
            <ul>
              <li>
                <a @click="goto($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{
                  $t("LINK_COMPANY_PROFILE")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{
                  $t("PAGE_PRIVACY_POLICY")
                }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- SP fNav -->
      <div class="fNav_sp only_sp">
        <ul>
          <li><a @click="goto($define.PAGE_NAMES.TOP_PAGE_NAME)">TOP</a></li>
          <li>
            <p>{{ $t("PAGE_PURCHASE_OF_CONSTRUCTION_EQUIPMENT") }}</p>
            <dl>
              <dt>
                <a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)">{{
                  $t("PAGE_AUCTION")
                }}</a>
              </dt>
            </dl>
          </li>
          <li>
            <p>{{ $t("PAGE_MEMBER_LOGIN") }}</p>
            <ul>
              <li v-if="!logined">
                <a @click="goto($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">
                  {{ $t("PAGE_NEW_MEMBER_REGISTRATION") }}
                </a>
              </li>
              <li v-if="!logined">
                <a @click="goto($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a>
              </li>
              <li v-if="logined">
                <a @click="goto($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{
                  $t("PAGE_MY_PAGE")
                }}</a>
              </li>
              <li v-if="logined">
                <a @click="logout">{{$t("HEADER_LOGOUT_BUTTON_LABEL")}}</a>
              </li>
            </ul>
          </li>
          <li>
            <p>{{ $t("PAGE_USERS_GUIDE") }}</p>
            <ul>
              <li>
                <a @click="goto($define.PAGE_NAMES.GUIDE_AUCTION_SELL_PAGE_NAME)">{{
                  $t("PAGE_SELL_MOTORCYCLE")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.GUIDE_AUCTION_BUY_PAGE_NAME)">{{
                  $t("PAGE_BUY_MOTORCYCLE")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.GUIDE_AUCTION_PAGE_NAME)">{{
                  $t("PAGE_ABOUT_AUCTION_LISTING")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.GUIDE_AUCTION_AGENCY_PAGE_NAME)">{{
                  $t("PAGE_ABOUT_AUCTION_LISTING_SERVICE")
                }}</a>
              </li>
            </ul>
          </li>
          <li>
            <p>{{ $t("PAGE_OTHER_GUIDANCE") }}</p>
            <ul>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{
                  $t("PAGE_DELIVERY")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_TERMS_PAGE_NAME)">{{
                  $t("PAGE_TERMS")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME)">{{
                  $t("PAGE_AUCTION_TERMS")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{
                  $t("PAGE_VARIOUS_COMMISSIONS")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_TOKUSHO_PAGE_NAME)">{{
                  $t("PAGE_TOKUSHO")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.OTHER_GUIDE_FAQ_SUPPORT_PAGE_NAME)">{{
                  $t("PAGE_FAQ_SUPPORT")
                }}</a>
              </li>
            </ul>
          </li>
          <li>
            <p>{{ $t("LINK_COMPANY_INFO") }}</p>
            <ul>
              <li>
                <a @click="goto($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{
                  $t("LINK_COMPANY_PROFILE")
                }}</a>
              </li>
              <li>
                <a @click="goto($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{
                  $t("PAGE_PRIVACY_POLICY")
                }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    <p class="copyright" v-html="$t('FOOTER_COPYRIGHT')"></p>
  </footer>
</template>
<script>
export default {
  props : {
    logined : {
      type    : Boolean,
      default : false,
    },
  },
  data() {
    return {}
  },
  methods : {
    goto(dest, id = null, query = {}, hash = null) {
      this.$routerGo(dest, id, query, hash)
      window.scrollTo({
        top      : 0,
        left     : 0,
        behavior : 'smooth',
      })
    },
    logout() {
      this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
      this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.MEMBER_NO)
      this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
    },
  },
}
</script>
<style scoped lang="scss">
p {
  margin-bottom: 0px !important;
}
ul {
  padding-left: 0px !important;
}
.unlink:hover {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  footer p.kobutsu {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  footer nav .fNav_pc > ul > li {
    width: 185px;
  }
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
