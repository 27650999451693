import {defineStore} from 'pinia'

const useLoading = defineStore('loading', {
  state : () => {
    return {loading : []}
  },
  actions : {
    setLoading(val) {
      if (val) this.loading.push(val)
      else this.loading.pop()
    },
  },
  getters : {
    isLoading : state => {
      return state.loading.length > 0
    }
  },
})

export default useLoading
